import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'

import type { AppRouter } from '@mesx-server/trpc/router'
import apiHelper from '~/helpers/ApiHelper'

if (!import.meta.env.VITE_TRPC_MESX_URL)
  throw new Error('VITE_TRPC_MESX_URL is not defined')

export const trpc = createTRPCProxyClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${import.meta.env.VITE_TRPC_MESX_URL}/trpc`,
      async headers() {
        return {
          authorization: await apiHelper.getAccessToken(),
        }
      },
    }),
  ],
})
