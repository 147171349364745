import { defineStore } from 'pinia'

export const alertDrawerStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'alertsDrawer',
  // a function that returns a fresh state
  state: () => ({
    alert: null as any,
  }),

  // optional getters
  getters: {
    getAlert(): any {
      return this.alert
    },
  },
  // optional actions
  actions: {
    setAlert(alert) {
      this.alert = alert
    },
  },
})
