<script setup lang="ts">
import { trpc } from '~/composables/mesx/trpc'
import { useQueryClient } from '@tanstack/vue-query'
import { usersStore } from '~/store/users'
import { TagMachine } from '~/composables/mesx/requests/tags'

const isGeneratingData = ref(false)
const queryClient = useQueryClient()

const props = defineProps<{
  tagId: string
  machines: TagMachine['machineToTag']
}>()

const { tagId, machines } = toRefs(props)

const { user } = usersStore()
const siteId = user.site_id
const clientId = user.client_id
const operatorId = user.id

const generateData = async () => {
  try {
    isGeneratingData.value = true
    const requests = [] as Promise<any>[]

    for (const machine of machines.value) {
      requests.push(
        trpc.generateData.mutate({
          siteId,
          clientId,
          machineId: machine.machineId,
          tagId: tagId.value,
          operatorId,
        }),
      )
    }

    await Promise.all(requests)

    queryClient.invalidateQueries()
  } catch (e) {
    console.log(e)
  } finally {
    isGeneratingData.value = false
  }
}
</script>

<template>
  <div>
    <OButton
      :disabled="isGeneratingData"
      :loading="isGeneratingData"
      @click="generateData"
    >
      Generate data for the last 24 hours
    </OButton>
  </div>
</template>
