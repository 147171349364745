import { defineStore } from 'pinia'
import alertController from '~/controllers/alerts'

export const alertsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'alerts',
  // a function that returns a fresh state
  state: () => ({
    alerts: [] as any[],
  }),

  // optional getters
  getters: {
    getAlerts(): any {
      return this.alerts
    },
  },
  // optional actions
  actions: {
    async loadAlerts(): Promise<any> {
      const alerts = await alertController.getAlerts()
      this.alerts = alerts
    },
    findAlert(id: string) {
      return this.alerts.find((alert: any) => alert?.id === id)
    },
    findAlertByName(name: string) {
      return this.alerts.find((alert: any) => alert?.name === name)
    },
    filterAlerts(ids: any) {
      const alerts: any = this.alerts.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return alerts
    },
    unshiftAlert(alert: any) {
      this.alerts.unshift(alert)
    },
    getAlertsForReport(report_id: string) {
      return this.alerts.filter(alert => alert.sessionContext.reportId == report_id)
    },
    getAlertsForSession(session_id: string) {
      return this.alerts.filter(alert => alert.sessionContext.sessionId == session_id)
    },
  },
})
