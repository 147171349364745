<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Icons } from '~/models/Icon'

const props = defineProps({
  // file path (optional): will be used if there is no file urls provided
  id: {
    type: String,
    required: false,
    default: '',
  },
  filePath: {
    type: String,
    required: false,
  },
  // file URL (optional): will be used if there is no file path provided
  fileUrl: {
    type: String,
    required: false,
  },
  // file attachment ID (optional): will be used only if this is an attachment
  attachmentId: {
    type: String,
    required: false,
  },
  // file name
  fileName: {
    type: String,
    required: true,
  },
  // file type
  fileType: {
    type: String,
    required: false,
  },
  fitObject: {
    type: String,
    required: false,
  },
})

const { t } = useI18n()

const file = reactive({
  loading: false,
  // loadingUrl: false,
  loaded: false,
  extension: '',
  type: '',
  embedUrl: '',
  name: '',
  data: [],
})

const state = reactive({
  iframeHeight: '500px',
  embedHeight: '500px',
})

const setIframeHeight = () => {
  const modalHeight = window.innerHeight * 0.85
  const modalHeaderHeight = 65
  const modalBodyPadding = 24
  state.iframeHeight = `${modalHeight}px`
  state.embedHeight = `${modalHeight - modalHeaderHeight}px`
}

const setFileType = () => {
  if (props.fileName) {
    file.extension = props.fileType ?? props.fileName.split('.')[props.fileName.split('.').length - 1].toLowerCase()
    const type = file.extension
    if (type === 'xlsx' || type === 'xlsb' || type === 'docx' || type === 'pptx')
      file.type = 'office'
    else if (type === 'jpg' || type === 'jpeg' || type === 'png' || type === 'bmp' || type === 'gif')
      file.type = 'img'
    else if (type.includes('pdf'))
      file.type = 'pdf'
    else
      file.type = 'other'
  }
}

const loadFile = async () => {
  setFileType()
  // If the file hasn't already been loaded
  if (!file.loaded) {
    file.loading = true
    file.embedUrl = props.fileUrl
    file.name = props.fileName
    file.loading = false
    file.loaded = true
  }
}

watch(
  () => props,
  () => {
    setFileType()
  },
  { deep: true },
)

watch(
  () => props.fileUrl, (newVal) => {
    file.embedUrl = newVal
  },
  {
    deep: false,
    immediate: true,
  },
)

watch(
  () => props.fileName, (newVal) => {
    file.name = newVal
  },
  {
    deep: false,
    immediate: true,
  },
)

loadFile()
</script>

<template>
  <div
    :id="props.id"
    data-cy="file-reader"
    class="h-full w-full overflow-y-auto"
  >
    <div
      v-if="file.type === 'table'"
      class="h-full"
    >
      <!-- <SimpleTable :data="file.data" /> -->
    </div>
    <!-- TODO ----- -->
    <div
      v-if="file.type === 'pdf'"
      class="h-full w-full"
    >
      <iframe
        :src="`${file.embedUrl}#pagemode=none`"
        width="100%"
        height="100%"
        :alt="file.name"
        type="application/pdf"
      />
    </div>
    <div
      v-if="file.type === 'office'"
      class="h-full"
    >
      <iframe
        :src="file.embedUrl"
        width="100%"
        height="100%"
        frameborder="0"
      />
    </div>
    <div
      v-if="file.type === 'img'"
      class="mx-auto w-fit h-full flex items-center justify-center"
    >
      <img
        :src="file.embedUrl"
        frameborder="0"
      >
    </div>
    <div
      v-if="file.type === 'other'"
      class="h-full w-full"
    >
      <div class="mb-4">
        Télécharger le document pour le consulter
      </div>
      <o-button
        type="primary"
        size="large"
      >
        <a
          :href="file.embedUrl"
          class="flex items-center"
        > <component
          :is="Icons.DOWNLOAD"
          class="-ml-1 mr-2 h-4 w-4"
        /> <p>{{ file.name }}</p></a>
      </o-button>
    </div>
  </div>
</template>
