import localforage from 'localforage'
import type { UserModule } from '~/types'
import { fb_auth } from '~/services/firebase'
import { verifyIfUserHaveAccessRightToPath } from '~/utils/auth'

export const install: UserModule = ({ isClient, router }) => {
  if (!isClient) return

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    const requiresForcePass = to.matched.some(
      (record) => record.meta.requiresForcePass,
    )
    localforage.getItem('currentUser').then((user) => {
      const storageUser = user ? JSON.parse(user) : {}
      window.currentPageRequireAuth = requiresAuth

      if (requiresForcePass) {
        if (storageUser?.is_reset_password === false) {
          next({
            path: '/auth/force-reset-pass',
          })
          return
        }
        if (storageUser?.password_expired_at) {
          next({
            path: '/auth/reset-expired-password',
          })
          return
        }
      }

      if (requiresAuth) {
        fb_auth.onAuthStateChanged(async (user) => {
          if (user && localStorage.currentUser) {
            const currentUser = JSON.parse(localStorage.currentUser)
            if (!verifyIfUserHaveAccessRightToPath(currentUser, to))
              next({ path: '/' })

            next()
          } else {
            await fb_auth?.signOut()
            next({
              path: '/auth/login',
            })
          }
        })
      } else {
        fb_auth.onAuthStateChanged(async (user) => {
          if (user && to.name === 'auth-login'){
            if(to.query?.token){
              if(to.query?.token?.startsWith('ey')){
                next()
              }
            }
            next('/')
          }
          else next()
        })
      }
    })
  })
}
