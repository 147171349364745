import { useQuery } from '@tanstack/vue-query'
import { trpc } from '../trpc'
import { usersStore } from '~/store/users'

export type TagMachine =
  NonNullable<ReturnType<typeof useGetAllTags>['data']['value']> extends Array<
    infer T
  >
    ? T
    : null

export const useGetAllTags = () => {
  const { client_id, site_id } = usersStore().user
  return useQuery({
    queryKey: ['fetchAllTags'],
    queryFn: async () => trpc.fetchAllTags.query({ site_id, client_id }),
    initialData: [],
    refetchOnWindowFocus: true,
  })
}
