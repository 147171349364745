import * as _ from 'lodash'

export const sliceArrayUntilElement = (arr: Array<any>, element: any) => {
  const index = findIndex(arr, element)
  if (index)
    return arr.slice(0, index)
  return false
}

export const buildTreeNestedForVueTreeSelect = (listObj: object[]) => {
  // build tree
  const table = _.cloneDeep(listObj)
  const recurseChildren = (parent, child) => {
    if (parent.hasOwnProperty('children'))
      parent.children.push(child)
    else
      parent.children = [child]
  }
  for (let i = 0; i < table.length - 1; i++) {
    const parentItem = table[i]
    for (let j = i + 1; j < table.length; j++) {
      const childrenItem = table[j]
      if (parentItem.id === childrenItem.parentId)
        recurseChildren(parentItem, childrenItem)

      else if (childrenItem.id === parentItem.parentId)
        recurseChildren(childrenItem, parentItem)
    }
  }
  const result = table.filter(x => !x.parentId)
  if (result.length === 0)
    return table

  if (result) {
    result?.forEach((e) => {
      e?.children?.sort((a, b) => a.label.localeCompare(b.label))
    })
  }

  return result
}

export const findInParents = (element: any, array: any[], word: string): boolean => {
  if (!element.parentId)
    return element.label?.toLowerCase().includes(word)
  const parent = array.find(p => p.id === element.parentId)
  return false || findInParents(parent, array, word)
}

export const findObjectInArrayById = (listObject: Array<any>, id: string) => {
  return listObject?.find(obj => obj.id === id)
}
export const getAllValueByKey = (listObject: Array<any>, key: string) => {
  return _.uniq(listObject?.map(obj => obj && obj[key]))
}

export const getAllObjectWithKeyNotIncludingInList = (listObject: Array<any>, key: string, list: Array<any>) => {
  return listObject?.filter(obj => !list.includes(obj[key]))
}

export const removeNullInArrayString = (arr: Array<string>) => {
  return arr.filter(str => str !== null)
}

export const hydrateListIdWithListObject = (listId: Array<string>, listObject: Array<any>) => {
  return listId?.map(id => findObjectInArrayById(listObject, id))
}
