import type { JStep } from './jStep'
import { hydrate } from '~/utils/object'

export enum TemplateStatus {
  draft = 4,
  published,
  archived,
  deleted,
}

export class JTemplate {
  [x: string]: any

  // General
  id = ''
  name = ''
  status: TemplateStatus = TemplateStatus.draft
  client_id = ''
  site_id = ''
  attachment_ids = []
  version_reason = ''

  // Content
  steps: Array<JStep> = [] // convert

  // Parameters
  category = ''

  // Audit
  creation_date: any = ''
  update_date: any = ''
  created_by = ''
  updated_by = ''

  constructor(object: typeof JTemplate | {}) {
    hydrate(this, object)
  }

  public fromJson(payload: any) {
    hydrate(this, payload)
  }
}
