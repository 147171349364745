import type { JStep } from '~/models/documents/jStep'
import type { JInputData } from '~/models/report/jInputData'
import { handleOptions } from '~/services/grid'
import { gridStore } from '~/store/grid'
import { StepType } from '~/models/documents/jStep'
import CellRendererRadio from '~/components/UI/CellRendererRadio.vue'
import CellRendererTime from '~/components/UI/CellRendererTime.vue'
import CellRendererSelect from '~/components/UI/CellRendererSelect.vue'
import CellRendererStringBasic from '~/components/UI/CellRendererStringBasic.vue'
import { setValueOrNA } from '~/controllers/reports/reportsController'
import { formatDate } from '~/utils/formatter'
import { usersStore } from '~/store/users'

export class Cell {
  protected _rowIndex: number
  protected _colIndex: number
  protected _step: JStep
  protected _inputs: JInputData[]
  protected _hasHistory: boolean
  protected _disabled: boolean
  protected _isActivated: boolean
  // protected _showJustificationModal: boolean
  protected _showHistoryModal: boolean
  protected _editable: boolean
  protected _required: boolean
  protected _isHidden: boolean
  protected _isHistory: boolean
  protected _restoredValue: JInputData | null
  protected _justifyKoValue: JInputData | null

  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
  ) {
    this._rowIndex = rowIndex
    this._colIndex = colIndex
    this._inputs = inputs
    this._step = step
    this._isHidden = step.hidden
    this._isHistory = isHistory
    this._showHistoryModal = false
    this._editable = true
    this._restoredValue = null
    this._justifyKoValue = null
    // this._showJustificationModal = false
    this._hasHistory = inputs.length > 1
  }

  setShowHistoryModal(showHistoryModal: boolean): void {
    this._showHistoryModal = showHistoryModal
  }

  setIsActivated(value: boolean): void {
    this._isActivated = value
  }

  initializeActivationState(params: any): this {
    this._isActivated = setValueOrNA(this.latestValue ?? null, params)
    return this
  }

  // setShowJustificationModal(showJustificationModal: boolean): void {
  //   this._showJustificationModal = showJustificationModal
  //   gridStore().grid?.setShowJustificationModal(showJustificationModal)
  // }

  setRestoredValue(value: JInputData): void {
    this._restoredValue = value
  }

  setJustifyKoValue(value: JInputData): void {
    this._justifyKoValue = value
  }

  getLatestInputData(): JInputData | null {
    if (this._hasHistory) {
      return this._inputs.sort((inputA: JInputData, inputB: JInputData) => {
        return inputA.update_date > inputB.update_date
      })?.[0]
    }

    return this._inputs?.[0]
  }

  getHistory(): JInputData[] {
    if (!this._hasHistory && !this.getLatestInputData()?.reason) return []

    return this._inputs
  }

  getHistoryTooltip(): string {
    return this.getHistory()
      .filter((value, index) => index > 0)
      .map((history) => {
        return `${history.value} ${formatDate(history.update_date)}  ${usersStore().findUser(history.updated_by, { full: true })} \n`
      })
      .join(' ')
  }

  protected get latestValue(): string {
    return this.getLatestInputData()?.value ?? ''
  }

  isEditable(params: any): boolean {
    const { data, colDef } = params
    if (data?.isDisabled) {
      if (this.step?.disabled) return false
    }

    const value = colDef?.editable ? colDef?.editable(params, false) : false

    this._editable = value
    return this._editable
  }

  get isActivated(): boolean {
    return this._isActivated
  }

  get colIndex(): number {
    return this._colIndex
  }

  get rowIndex(): number {
    return this._rowIndex
  }

  get type(): string | undefined {
    return this._step?.type
  }

  get cellStepName(): string {
    return `${this._rowIndex + 1} - ${this._step.name}`
  }

  get inputs(): JInputData[] {
    return this._inputs
  }

  get step(): JStep | undefined {
    return this._step
  }

  get hasHistory(): boolean {
    return this._inputs?.length > 1
  }

  get isHidden(): boolean {
    return this._isHidden
  }

  get isHistory(): boolean {
    return this._isHistory
  }

  get restoreValue(): JInputData | null {
    return this._restoredValue
  }

  get justifyKoValue(): JInputData | null {
    return this._justifyKoValue
  }

  // getShowHistoryModal(): boolean {
  //   return this._showHistoryModal
  // }

  // getShowJustificationModal(): boolean {
  //   return this._showJustificationModal
  // }

  parseAnswer(): string | undefined {
    const answer: JInputData | null =
      this.restoreValue ?? this.justifyKoValue ?? this.getLatestInputData()
    return answer?.value
  }

  updateInputData(inputData: JInputData): void {
    this._inputs.unshift(inputData)
    if (this.step?.last_sampling_areas?.[inputData.col_id] !== undefined)
      this.step.last_sampling_areas[inputData.col_id] = true
    if (this._inputs?.length > 1) this._hasHistory = true
  }

  stepDetails() {
    const step = this.step
    const selectOptions = handleOptions(
      step?.list_data?.list_id,
      gridStore().grid?.listOptions(),
    )

    return {
      ...step,
      isHistory: true,
      data: {
        details: {
          isMultiple: step?.list_data?.is_multiple,
          is_not_applicable: step?.is_not_applicable,
          selectOptions,
        },
      },
    }
  }

  historyComponent(answer: object): object {
    const inputs = {
      [StepType.Boolean]: CellRendererRadio,
      [StepType.Time]: CellRendererTime,
      [StepType.List]: CellRendererSelect,
      [StepType.Measure]: CellRendererStringBasic,
      [StepType.Number]: CellRendererStringBasic,
      [StepType.Text]: CellRendererStringBasic,
    }
    const template = {
      input: inputs[this._step?.type],
      params: { answer, ...this.stepDetails() },
      'is-cleared': false,
      'is-from-modal': true,
      'options-to-show': 1,
    }
    const components = {
      [StepType.Boolean]: template,
      [StepType.Time]: template,
      [StepType.List]: template,
      [StepType.Measure]: template,
      [StepType.Number]: template,
      [StepType.Text]: template,
      // checkbox
      [StepType.Checkbox]: {
        input: 'input',
        class: 'h-5 w-5 flex',
        type: 'checkbox',
        disabled: true,
        checked: answer?.value,
      },
    }

    return components[this._step?.type]
  }
}

export default Cell
