<script lang="ts" setup>
import { hotReload } from '~/helpers/UtilsHelper'
import { Icons } from '~/models/Icon'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const hotReloadApp = async () => {
  await hotReload()
}
</script>

<template>
  <div
    id="version_popup"
    class="pointer-events-none z-50 fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div
      v-if="props.modelValue"
      class="flex w-full flex-col items-center space-y-4 h-[56px]"
    >
      <transition
        appear
        enter-active-class="delay-1000 -translate-y-20 transform ease-out duration-300 transition opacity-100"
        enter-from-class="-translate-y-20 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition ease-in duration-100 opacity-0"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          class="pointer-events-auto w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="px-4 py-3">
            <div class="flex items-center">
              <div class="flex w-0 flex-1 justify-between space-x-2">
                <component
                  :is="Icons.SPARKLES"
                  class="w-5 h-5 self-center"
                />
                <p class="w-0 flex-1 text-md font-medium text-gray-900 self-center">
                  {{ $t('global.new_version') }}
                </p>
                <button
                  type="button"
                  class="h-8 w-24 flex-shrink-0 rounded-md bg-duskblue-500 px-2 text-md font-medium text-white hover:bg-duskblue-500/90"
                  @click="hotReloadApp"
                >
                  {{ $t('global.refresh') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
