export enum CacheCheckSumsLocalForageKeys {
  USERS = 'checksum_firestore_users',
  WORKORDERS = 'checksum_firestore_workorders',
  WORKPLACES = 'checksum_firestore_workplaces',
  ROUTING = 'checksum_firestore_routing',
  PRODUCTS = 'checksum_firestore_products',
  CACHE_VERSION = 'cache_version',
}

export enum CacheFirebaseKey {
  USERS = 'users',
  WORKORDERS = 'workorders',
  ROUTING = 'routing',
  WORKPLACES = 'workplaces',
  PRODUCTS = 'products',
  LOOKUPS = 'lookups',
}
