import _ from 'lodash'
import { defineStore } from 'pinia'
import SiteController from '~/controllers/sites'
import StatusController from '~/controllers/statuses'
import type { JSite } from '~/models/sites'
import { workflowStore } from '~/store/workflows'
import { WorkflowType } from '~/components/Settings/Workflows/config'
import {
  BuiltInStatus,
  ReportStatus,
  TemplateStatus,
  type StatusRawModel,
} from '~/common/models/status'
import { SITES_COLLECTION_NAME } from '~/common/config/firebase/storage'
import DBHelper from '~/helpers/dbHelper'

export const siteStore = defineStore({
  id: 'site',
  state: () => ({
    site: {} as JSite,
    availableSiteStatuses: [] as any,
  }),
  getters: {
    allNonDeletedStatuses(state) {
      return state.availableSiteStatuses.filter(
        (e) =>
          e.id !== BuiltInStatus.select &&
          e.id !== BuiltInStatus.deleted &&
          !e.deletedAt,
      )
    },
    allReportsStatuses(state) {
      return state.availableSiteStatuses?.filter(
        (e) => !Object.values(TemplateStatus).filter(Number).includes(e.id),
      )
    },
  },
  actions: {
    async loadSite(): Promise<any> {
      const site: JSite = await SiteController.getCurrentSite()
      const siteStatuses = await StatusController.getStatuses()

      const dbHelper = new DBHelper()
      await dbHelper?.getDocFromCollectionOnSnapshot(
        SITES_COLLECTION_NAME,
        site.id,
        (site: JSite) => {
          this.site = site
        },
      )

      this.site = site
      this.availableSiteStatuses = siteStatuses
    },
    getStatus(statusId) {
      return this.availableSiteStatuses?.find((e) => e.id === statusId)
    },
    getFlag(flagName) {
      return this.site.flags?.[flagName]
    },
    hasActions(): boolean {
      return this.site?.flags?.actions?.some((e) => e.enabled)
    },
    hasFlag({ flagField, flagType }): boolean {
      return this.site?.flags
        ? this.site?.flags[flagField]?.some(
            (p: any) => p.type === flagType && p.enabled,
          )
        : false
    },
    productionOrderStatuses() {
      let result: StatusRawModel[] = []
      const allStatuses = this.availableSiteStatuses
      const productionOrderStatuses =
        workflowStore().findWorkflowByType(WorkflowType.PRODUCTION_ORDERS)
          ?.steps ?? []
      if (!_.isEmpty(productionOrderStatuses)) {
        result = productionOrderStatuses.map((e) => {
          return allStatuses.find((status) => status.id === e.status)
        })
      } else {
        result = allStatuses.filter((e) => {
          if (
            e.id === ReportStatus.new ||
            e.id === ReportStatus.in_progress ||
            e.id === ReportStatus.finished
          )
            return e
        })
      }
      return result
    },
  },
})
