<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import localforage from 'localforage'
import { storeToRefs } from 'pinia'
import { addMinutes, minutesToMilliseconds } from 'date-fns'
import ViewsController from './controllers/views'
import type { JSite } from './models/sites'
import { retryUpdateInputData } from './controllers/reports/reportsController'
import { Icons, MIcons } from './models/Icon'
import { getUserLanguage } from './helpers/locales'
import { CACHED_OBJECTS } from '~/config/local-forage'
import { VERSION } from '~/common'
import { loadUser, logout } from '~/controllers/authentication'
import { usersStore } from '~/store/users'
import { siteStore } from '~/store/site'
import { hotReload, versionToNumber } from '~/helpers/UtilsHelper'
import { apiStore } from '~/store/api'
import loggerHelper from '~/helpers/LoggerHelper'
import startup from '~/helpers/Startup'
import { gS } from '~/store/global'
import apiHelper from '~/helpers/ApiHelper'
import { fb_auth } from '~/services/firebase'
import { OfflineHandler } from '~/services/offline'
import DBHelper from '~/helpers/dbHelper'
import Intercom from '@intercom/messenger-js-sdk'
import { shutdown } from '@intercom/messenger-js-sdk'

if (
  import.meta.env.VITE_ENV_TEST === 'true' ||
  import.meta.env.VITE_ENV_TEST === true
) {
  // @ts-expect-error: Hide/Show Crisp Chatbox
  $crisp.push(['do', 'chat:hide'])
}

const dbHelper = new DBHelper()
const route = useRoute()
const router = useRouter()
const api = apiStore().getApiClient
const { locale } = useI18n()
const user = computed(() => usersStore().user)

useHead({
  title: `Juno - ${VERSION}`,
  meta: [{ name: 'description', content: `Juno web app ${VERSION}` }],
})

const appData: any = reactive({
  loading: false,
  offline: false,
  showPopUp: false,
})

const state = reactive({
  maxActiveTime: 480 as number,
  currentSite: {} as JSite,
  // In order to use deconnect function only one time
  userIsDeconnected: 0,
  modalSimulator: false,
})

// offline init
const { isOnline } = storeToRefs(gS())

watch(isOnline, (value) => {
  if (value) OfflineHandler.syncPostData()
})

OfflineHandler.syncPostData()

apiHelper.startPingingJunoServer()
apiHelper.retryFailedPost()
// end offline init

const checkVersion = async () => {
  const status = await api.getAppStatus()
  const frontVersion = versionToNumber(VERSION)
  const serverVersion = versionToNumber(status?.version)
  // const currentTimestamp = addMinutes(new Date(), 4)
  //  && (status.releaseTimeStamp && currentTimestamp.getTime() > status.RELEASE_TIMESTAMP)
  if (frontVersion < serverVersion) {
    if (!status.forceReload) appData.showPopUp = true
    else await hotReload()
  }
}

const replayData = async () => {
  if (fb_auth.currentUser) await retryUpdateInputData()
}

const channel = new BroadcastChannel('logoutChannel')
const isDeveloper = computed(() => usersStore().user?.is_developer)

const logNetworkInfo = async () => {
  if (import.meta.env.DEV) return

  const connection: any = navigator?.connection

  const occurence_date = new Date()
  const userData = usersStore().user
  const logData: any = {
    type: connection?.effectiveType,
    downlinkype: `${connection?.downlink} Mb/s`,
    rtt: `${connection?.rtt} ms`,
    client_id: userData?.client_id ? userData?.client_id : 'anonymous',
    client_name: userData?.client_name ? userData?.client_name : 'anonymous',
    site_name: userData?.site_name ? userData?.site_name : 'anonymous',
    uid: userData?.id ? userData?.id : 'anonymous',
    first_name: userData?.first_name ? userData?.first_name : 'anonymous',
    last_name: userData?.last_name ? userData?.last_name : 'anonymous',
    online: navigator.onLine,
    timestamp: occurence_date.getTime(),
    date: occurence_date,
    sync_status: 'todo',
    version: VERSION,
  }
  const logs: any = await localforage.getItem('connection-logs')
  const logsDataList = logs?.data ? logs?.data : []
  logsDataList.push(logData)
  if (navigator.onLine === true) {
    if (logsDataList?.length) {
      for (const f of logsDataList) {
        if (f.sync_status === 'todo') {
          f.sync_status = 'synched'
          await dbHelper.addDataToCollection('connection_logs', f)
        }
      }
    }
    appData.offline = false
  } else {
    appData.offline = true
  }
  localforage.setItem('connection-logs', { data: logsDataList })
}

const init = async () => {
  loggerHelper.logEvent('INIT_APP')
  // eslint-disable-next-line no-console

  // Firefox doesn't handle network API
  if ('connection' in navigator)
    navigator.connection.addEventListener('change', logNetworkInfo)

  if (typeof window !== 'undefined') {
    checkVersion()
    appData.interval_ctx = setInterval(checkVersion, 1000 * 60 * 10) // ask for new version every 10 min
    appData.interval_replay = setInterval(replayData, 1000 * 60 * 4) // ask for data to replay every 5 min
  }
  appData.loading = true
  await loadUser()
  const user = usersStore().user
  if (user?.is_reset_password === false && user?.is_reset_password !== null)
    router.push('/auth/force-reset-pass')

  if (user?.password_expired_at) router.push('/auth/reset-expired-password')

  if (user) await localforage.setItem('currentUser', JSON.stringify(user))

  if (user?.role_ids?.length) {
    await startup.bootstrap()

    state.currentSite = siteStore().site

    if (state.currentSite?.flags?.idle_delay)
      state.maxActiveTime = state.currentSite.flags.idle_delay as number
    ViewsController.setupView()
  }

  appData.loading = false
}

let timeOut: ReturnType<typeof setTimeout>

const userActivityHandler = () => {
  timeOut = setTimeout(async () => {
    state.userIsDeconnected++
    if (state.userIsDeconnected === 1) {
      const currentUrl = route.fullPath
      const userId = usersStore().user.id
      localforage.setItem(
        `${CACHED_OBJECTS.LATEST_VISITED_URL}_${userId}`,
        currentUrl,
      )
      localforage.setItem(CACHED_OBJECTS.DISCONNECTED_NON_ACTIVITY, 'true')
      await logout()
    }
  }, minutesToMilliseconds(state.maxActiveTime))
}

const globalListener = async () => {
  state.maxActiveTime = (siteStore().site.flags?.idle_delay as number) ?? 480
  // if user is logged in
  if (usersStore().user.id?.length) {
    const lastActivity = (await localforage.getItem(
      CACHED_OBJECTS.LAST_ACTIVITY_TIME,
    )) as Date
    if (
      lastActivity &&
      new Date() > addMinutes(lastActivity, state.maxActiveTime)
    ) {
      const currentUrl = route.fullPath
      const userId = usersStore().user.id
      localforage.setItem(
        `${CACHED_OBJECTS.LATEST_VISITED_URL}_${userId}`,
        currentUrl,
      )
      localforage.setItem(CACHED_OBJECTS.DISCONNECTED_NON_ACTIVITY, 'true')
      clearTimeout(timeOut)
      await logout()
    }
    localforage.setItem(CACHED_OBJECTS.LAST_ACTIVITY_TIME, new Date())
    clearTimeout(timeOut)
    state.userIsDeconnected = 0
    userActivityHandler()
  }
}

init()

watch([user, navigator.language], async () => {
  locale.value = await getUserLanguage(user.value)
})

window.addEventListener('storage', (event) => {
  if (event.storageArea != localStorage) return
  if (event.key === 'refresh_key') {
    location.reload()
  }
})

watch(
  () => siteStore().site,
  () => {
    if (siteStore().site?.flags?.show_intercom) {
      Intercom({
        app_id: import.meta.env.VITE_INTERCOM_APP_ID,
        user_id: user.value.id,
        name: user.value.full_name,
        email: user.value.email,
        created_at: user.value.create_date,
      })
      $crisp.push(['do', 'chat:hide'])
    } else {
      shutdown()
      // @ts-expect-error: Hide/Show Crisp Chatbox
      user?.full_name && $crisp.push(['set', 'user:nickname', user?.full_name])
      // @ts-expect-error: Hide/Show Crisp Chatbox
      user?.email && $crisp.push(['set', 'user:email', user?.email])
      $crisp.push(['do', 'chat:show'])
    }
  },
)
</script>

<template>
  <div
    v-if="!isOnline"
    class="bg-[#FEE4E3] p-4 text-center"
    role="alert"
  >
    <div class="font-base inline-flex items-center space-x-2">
      <component
        :is="Icons.CLOUD_OFFLINE"
        class="text-red-600"
      />
      <div>{{ $t('global.you_are_offline') }}</div>
    </div>
  </div>

  <VersionPopUp v-model="appData.showPopUp" />

  <section v-if="siteStore().site.flags?.mes && isDeveloper">
    <div
      class="absolute bottom-32 right-6 z-50 grid h-16 w-16 cursor-pointer place-items-center rounded-full bg-white shadow-xl transition-opacity ease-out"
      :onclick="() => (state.modalSimulator = true)"
    >
      <JIcon
        :icon="MIcons.SMART_TOY"
        class="text-4xl"
      />
    </div>

    <ActiveModal v-model="state.modalSimulator">
      <template #body>
        <Simulator />
      </template>
    </ActiveModal>
  </section>

  <router-view
    v-if="!appData.loading"
    @click="globalListener"
    @input="globalListener"
    @mousemove="globalListener"
  />
  <div
    v-if="appData.loading"
    class="flex h-screen items-center justify-center"
  >
    <div
      style="border-top-color: transparent"
      class="h-12 w-12 animate-spin justify-center rounded-full border-4 border-solid border-blue-400"
    />
  </div>
</template>

<style>
.readOnlyMode {
  pointer-events: none;
}

.read-only {
  pointer-events: none !important;
}

.crisp-client .cc-1brb6 .cc-1yy0g .cc-1m2mf .cc-157aw {
  width: 48px !important;
  height: 48px !important;
  display: block !important;
  border-radius: 100% !important;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0!important, 0.05) !important;
  transition: transform 0.15s ease-in-out !important;
}

.crisp-client
  .cc-1brb6[data-website-logo='false'][data-last-operator-face='false']
  .cc-1yy0g
  .cc-1m2mf
  .cc-157aw
  .cc-d73fc[data-is-ongoing='false']
  .cc-1bvfm {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwIiB3aWR0aD0iMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxmaWx0ZXIgaWQ9ImEiIGhlaWdodD0iMTM4LjclIiB3aWR0aD0iMTMxLjQlIiB4PSItMTUuNyUiIHk9Ii0xNS4xJSI+PGZlTW9ycGhvbG9neSBpbj0iU291cmNlQWxwaGEiIG9wZXJhdG9yPSJkaWxhdGUiIHJhZGl1cz0iMSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz48ZmVPZmZzZXQgZHk9IjEiIGluPSJzaGFkb3dTcHJlYWRPdXRlcjEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+PGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSIxIi8+PGZlQ29tcG9zaXRlIGluPSJzaGFkb3dCbHVyT3V0ZXIxIiBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0ib3V0IiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjA3IDAiLz48L2ZpbHRlcj48cGF0aCBpZD0iYiIgZD0iTTE0LjIzIDIwLjQ2bC05LjY1IDEuMUwzIDUuMTIgMzAuMDcgMmwxLjU4IDE2LjQ2LTkuMzcgMS4wNy0zLjUgNS43Mi00LjU1LTQuOHoiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiLz48dXNlIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjYiIvPjwvZz48L3N2Zz4=) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  display: inline-block !important;
  width: 28px !important;
  height: 24px !important;
  top: 14px !important;
  left: 10px !important;
}

.crisp-client
  .cc-1brb6[data-website-logo='false'][data-last-operator-face='false'][data-full-view='false']
  .cc-1yy0g
  .cc-1m2mf[data-maximized='true']
  .cc-157aw
  .cc-d73fc[data-is-ongoing='false']
  .cc-1bvfm {
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwIiB3aWR0aD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE5LjM5OSAyLjA0YTEuMzM0IDEuMzM0IDAgMDAtMi4yOS0uOTNsLTcuMDU2IDcuMDU3TDIuOTk1IDEuMTFBMS4zMzMgMS4zMzMgMCAxMDEuMTEgMi45OTVsNy4wNTcgNy4wNThMMS4xMSAxNy4xMWExLjMzNCAxLjMzNCAwIDEwMS44ODUgMS44ODVsNy4wNTgtNy4wNTcgNy4wNTcgNy4wNTdhMS4zMzQgMS4zMzQgMCAxMDEuODg1LTEuODg1bC03LjA1Ny03LjA1NyA3LjA1Ny03LjA1OGExLjMzMiAxLjMzMiAwIDAwLjQwNC0uOTU1eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==) !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-position-x: center !important;
  background-position-y: center !important;
  display: inline-block !important;
  width: 18px !important;
  height: 18px !important;
  top: 15px !important;
  left: 16px !important;
}
</style>
