import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'
import StorageHelper from '~/helpers/storageHelper'
import { usersStore } from '~/store/users'

export class PhotoCell extends Cell {
  private storageHelper: StorageHelper

  constructor(rowIndex: number, colIndex: number, inputs: JInputData[], step: JStep, isHistory: boolean) {
    if (step.type !== StepType.Photo)
      throw new Error('Type mismatch')

    super(rowIndex, colIndex, inputs, step, isHistory)
    this.storageHelper = new StorageHelper()
  }

  get latestValue(): string {
    const value = super.latestValue
    return value
  }

  async uploadImage(imageBlob: Blob): Promise<string> {
    const blobId = URL.createObjectURL(imageBlob).split('/').at(-1)
    const fileName = `Screenshot_${this._step.id}_${blobId}`
    const path = await this.storageHelper.uploadFile(`clients/${usersStore().user.client_id}/${fileName}`, imageBlob, fileName)
    return path
  }
}

export default PhotoCell
