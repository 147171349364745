<script setup lang="ts">
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits<{
  (e: 'save'): void
  (e: 'actionOnClose'): void
  (e: 'update:modelValue', val: boolean): void
}>()

const closeModal = () => {
  emit('update:modelValue', false)
  emit('actionOnClose')
}
</script>

<template>
  <div
    v-show="props.modelValue"
    class="fixed z-20 inset-0"
  >
    <div
      id="modal-wrapper"
      class="flex items-center justify-center min-h-full p-4 text-center sm:p-0 bg-black/40"
      :onclick="() => closeModal()"
    >
      <div
        :onclick="(e) => e.stopPropagation()"
        class="bg-white p-8 rounded-md content"
      >
        <slot
          name="body"
        />
      </div>
    </div>
  </div>
</template>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
