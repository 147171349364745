import _ from 'lodash'

export const getWeekNumber = (date) => {
  const yearFirstDay = new Date(date.getFullYear(), 0, 1)
  const numberOfDays = Math.floor((date - yearFirstDay) / (24 * 60 * 60 * 1000))
  return Math.ceil((date.getDay() + 1 + numberOfDays) / 7)
}

export const getWeekDiff = (start_date, end_date) => {
  const numberOfDays = Math.floor(
    (end_date - start_date) / (24 * 60 * 60 * 1000),
  )
  return Math.round(numberOfDays / 7)
}

export const getMonthDiff = (start_date, end_date) => {
  const numberOfDays = Math.floor(
    (end_date - start_date) / (24 * 60 * 60 * 1000),
  )
  return Math.round(numberOfDays / 30)
}

export const getYearDiff = (start_date, end_date) => {
  const numberOfDays = Math.floor(
    (end_date - start_date) / (24 * 60 * 60 * 1000),
  )
  return Math.round(numberOfDays / 365)
}

export const getDifferenceInDays = (startDate, endDate) => {
  const Difference_In_Time = endDate - startDate

  // To calculate the no. of days between two dates
  const oneDay = 1000 * 3600 * 24
  return Math.round(Difference_In_Time / oneDay)
}

export const getFullYear = (
  date: Date | string | number,
  seperator = '-',
): string => {
  date = new Date(date)
  const year = date.getFullYear().toString()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const result = year + seperator + month + seperator + day

  return result
}

export const dateToUtc = (date: Date | string | number) => {
  date = new Date(date)
  date = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )

  return date
}

export const isDateDisabled = (date) => {
  let result = false
  if (
    date === null ||
    _.isEmpty(date) ||
    date === false ||
    date === 0 ||
    date === undefined ||
    Number.isNaN(date)
  )
    result = true
  return result
}

export const dateInterval = (
  date: string | Date = new Date(),
  fromToday = false,
): { start: Date; end: Date } => {
  const setDate = (dateString: string | Date) => {
    const d = new Date(dateString)
    d.setHours(0, 0, 0, 0)
    return d
  }
  const start = setDate(date)
  const end = setDate(fromToday ? new Date() : date)
  end.setHours(23, 59, 59, 59)
  return { start, end }
}

export const dateIntervalFixed = (from: string | Date = new Date()) => {
  const setDate = (dateString: string | Date) => {
    const d = new Date(dateString)
    d.setHours(0, 0, 0, 0)
    return d
  }
  const start = setDate(from)
  const end = new Date()
  return { start, end }
}
