export const NON_BUILT_IN_STATUSES_IDS_THRESHOLD = 100

export enum WorkflowType {
  CREATE = -1,
  DOCUMENTS,
  REPORTS,
  PRODUCTION_ORDERS,
  ALERTS,
  OPERATIONS,
}

export interface DefaultWorkflowType {
  type: WorkflowType
  created_at: string
  created_by: string
  bypassBuiltIn?: boolean
  transitions: any
  steps: { description: string; status: number }[]
}

export const workflowTypes = [
  { value: WorkflowType.CREATE, label: 'workflows.type_select' },
  { value: WorkflowType.DOCUMENTS, label: 'workflows.type_documents' },
  { value: WorkflowType.REPORTS, label: 'workflows.type_reports' },
  { value: WorkflowType.PRODUCTION_ORDERS, label: 'workflows.type_of' },
  { value: WorkflowType.ALERTS, label: 'workflows.type_alerts' },
  { value: WorkflowType.OPERATIONS, label: 'workflows.type_operations' },
]

export const defaultWorkflows: DefaultWorkflowType[] = [
  {
    type: WorkflowType.DOCUMENTS,
    created_at: '/',
    created_by: 'Juno',
    transitions: [
      { from: '1', to: '2', name: 'transitionTo2' },
      { from: '2', to: '3', name: 'transitionTo3' },
      { from: '3', to: '2', name: 'transitionTo2' },
    ],
    steps: [
      { description: 'workflows.default_document_step_1', status: 4 },
      { description: 'workflows.default_document_step_2', status: 5 },
    ],
  },
  {
    type: WorkflowType.REPORTS,
    created_at: '/',
    created_by: 'Juno',
    transitions: [
      { from: '1', to: '2', name: 'transitionTo2' },
      { from: '2', to: '3', name: 'transitionTo3' },
      { from: '3', to: '2', name: 'transitionTo2' },
    ],
    steps: [
      { description: 'workflows.default_report_step_1', status: 1 },
      { description: 'workflows.default_report_step_2', status: 2 },
      { description: 'workflows.default_report_step_3', status: 3 },
    ],
  },

  {
    type: WorkflowType.PRODUCTION_ORDERS,
    created_at: '/',
    created_by: 'Juno',
    transitions: [
      { from: '1', to: '2', name: 'transitionTo2' },
      { from: '2', to: '3', name: 'transitionTo3' },
      { from: '3', to: '2', name: 'transitionTo2' },
      { from: '3', to: '11', name: 'transitionTo11' },
    ],
    steps: [
      { description: 'workflows.default_of_step_1', status: 1 },
      { description: 'workflows.default_of_step_2', status: 2 },
      { description: 'workflows.default_of_step_3', status: 3 },
      { description: 'workflows.default_of_step_11', status: 11 },
    ],
  },
  {
    type: WorkflowType.ALERTS,
    created_at: '/',
    created_by: 'Juno',
    transitions: [
      { from: '1', to: '2', name: 'transitionTo2' },
      { from: '2', to: '3', name: 'transitionTo3' },
      { from: '3', to: '2', name: 'transitionTo2' },
    ],
    steps: [
      { description: 'workflows.default_alert_step_1', status: 1 },
      { description: 'workflows.default_alert_step_2', status: 2 },
      { description: 'workflows.default_alert_step_3', status: 3 },
    ],
  },
  {
    type: WorkflowType.OPERATIONS,
    created_at: '/',
    created_by: 'Juno',
    transitions: [
      { from: '1', to: '2', name: 'transitionTo2' },
      { from: '2', to: '3', name: 'transitionTo3' },
      { from: '3', to: '2', name: 'transitionTo2' },
    ],
    steps: [
      { description: 'workflows.default_operations_step_1', status: 1 },
      { description: 'workflows.default_operations_step_2', status: 2 },
      { description: 'workflows.default_operations_step_3', status: 3 },
    ],
  },
]
