<script setup lang="ts">
import type { Controle } from '~/models/operator'
import { StepMeasureOptions, StepType } from '~/models/documents/jStep'

const props = defineProps({
  params: {
    type: Object,
    required: true,
    default: {} as any,
  },
})

const isMeasure = props.params?.data?.type === StepType.Measure
const isBoolean = props.params?.data?.type === StepType.Boolean

// TODO :  Change with chartjs interface

const state = reactive({
  details: props.params?.data?.details,
  tags: props.params?.data?.tags,
  aqlTags: props.params?.data?.aqlTags,
  isHistory: props.params?.data?.isHistory,
  unit: props.params?.data?.details?.unit,
  allControles: [] as Controle[],
  gridHasFileAttachement: props.params?.columnApi.columnModel.columnDefs.find(
    (e) => e.field === 'file',
  ),
  rowIndex: props?.params?.rowIndex,
  colIndex:
    props?.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

// TODO LUC -> Remove hard coded
const tagName = (tag: any, key: string) => {
  if (key === 'quantity') return `Quantité : ${tag?.toString()}`
  else if (key === 'nbPieceByControl')
    return `Pièce / contrôle : ${tag?.toString()}`
  else if (key === 'nbPieces') return `Nb pièce mini : ${tag?.toString()}`
}
</script>

<template>
  <div
    class="flex w-full flex-col justify-center overflow-hidden py-2 text-left"
    data-cy="cell-renderer-description"
  >
    <div
      class="align-center flex w-full flex-row items-center justify-between gap-2 whitespace-normal leading-normal"
    >
      <span class="break-keep font-bold"> {{ props.params?.data?.name }} </span>

      <span
        v-if="!props.params?.data?.isMandatory"
        class="rounded bg-tab-primary p-1.5 text-xs font-medium text-grey-900"
      >
        {{ $t('global.optional') }}
      </span>
      <div
        v-if="state.aqlTags && props.params?.data?.isMandatory"
        class="absolute right-4 top-2 flex flex-col gap-2"
      >
        <div
          v-for="[key, tag] in Object.entries(state.aqlTags)"
          :key="key"
          class="flex flex-col gap-2"
        >
          <span
            v-if="tag"
            class="rounded bg-[#FEF0CD] p-1.5 text-xs font-medium text-primary"
          >
            {{ `${key}: ${tag}` }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="state.tags?.length"
      class="flex flex-wrap gap-2 py-1"
    >
      <div
        v-for="(tag, index) in state.tags"
        :key="`${tag}-${index}`"
        class="ml-0 h-6 rounded-md px-2 pt-0 text-sm leading-6"
        :class="`bg-[${tag.bgColor}]`"
      >
        <span> {{ tag.value }} </span>
      </div>
    </div>
    <div
      v-if="isBoolean"
      class="flex items-center gap-4 text-sm leading-normal"
    >
      <span
        v-if="state.details?.means_of_measure"
        class="font-medium"
      >
        {{ state.details?.means_of_measure }}
      </span>
    </div>
    <div
      v-if="isMeasure"
      class="flex items-center gap-4 text-sm leading-normal"
    >
      <span
        v-if="state.details?.means_of_measure"
        class="font-medium"
      >
        {{ state.details?.means_of_measure }}
      </span>
      <span
        v-if="props.params?.data?.type === StepType.Measure"
        :class="{ 'pt-2': !state.details?.means_of_measure }"
      >
        <span
          v-if="
            state.details?.goal !== undefined &&
            !state.details?.last_targets?.length
          "
          class="font-semibold"
        >
          {{ `${state.details?.goal} ` }}
        </span>
        <span
          v-if="state.details?.unit"
          class="font-medium"
        >
          {{ `${state.details?.unit} ` }}
        </span>
        <span class="ml-2 gap-2 font-medium">
          <span class="mr-2 font-light">
            {{ $t('step.measure_min') }}:
            <span class="font-semibold">
              {{
                `${state.details.minType === StepMeasureOptions.Margin ? '-' : ''}${state.details?.range[0]} `
              }}
            </span>
          </span>
          <span class="font-light">
            {{ $t('step.measure_max') }}:
            <span class="font-semibold">
              {{
                `${state.details.maxType === StepMeasureOptions.Margin ? '+' : ''}${state.details?.range[1]} `
              }}
            </span>
          </span>
        </span>
        <span
          v-if="state.details?.is_dynamic"
          :class="`gap-2 font-medium ${state.details?.goal && 'ml-2'}`"
        >
          {{ ` ${$t('report.dynamic')}` }}
        </span>
      </span>
    </div>
    <div
      v-if="props.params?.data?.description"
      id="rich-editor-description"
      class="mt-4 whitespace-normal break-keep text-justify leading-normal"
    >
      <div v-html="props.params?.data?.description" />
    </div>
  </div>
</template>

<style lang="scss">
@import '~/../../../styles/_richeditor.scss';

.truncate-modified {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ag-pinned-left-cols-container {
  .ag-row {
    @apply flex items-baseline;
    .ag-cell-value:first-child {
      @apply top-2 ml-4;
      width: 32px !important;
    }
    .ag-cell-last-left-pinned {
      @apply overflow-hidden;
    }
  }
}

.ag-pinned-left-cols-container :has(.gridHasFileAttachement) {
  .ag-row {
    @apply flex items-baseline;
    .ag-cell-value:first-child {
      @apply top-2 ml-4 h-8 self-center rounded-full bg-cloudygray-100 p-0 text-base font-semibold;
    }
    .ag-cell-last-left-pinned {
      @apply h-auto overflow-visible;
    }
  }
}
</style>
