import { Icons } from '~/models/Icon'

export const isOperatorView = (): boolean => {
  return window.location.pathname.startsWith('/operator')
}

export const canUseLocalData = (): boolean => {
  const excludedUrls = [
    // 'operator\\/workplaces\\/(.*)\\/sessions\\/(.*)\\/documents\\/(.*)\\/reports\\/(.*)'
  ]

  return isOperatorView() && !excludedUrls.some(url => window.location.pathname.match(url))
}

export const generateRandomString = (length) => {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const getTagIcon = (type = '') => {
  switch (type) {
    case 'products':
      return Icons.CUBE
    case 'workplaces':
    case 'workplace':
      return Icons.WORKPLACE
    case 'operations':
    case 'operation':
      return Icons.BUILD
    case 'production_order':
      return Icons.BARCODE
    default:
      return null
  }
}

export const getBannerColor = (type = '') => {
  switch (type) {
    case 'products':
      return '#2E90FA'
    case 'workplaces':
    case 'workplace':
      return '#FB6514'
    case 'operations':
    case 'operation':
      return '#FDB022'
    case 'production_orders':
    case 'production_order':
      return '#12B76A'
    default:
      return ''
  }
}
