import { usersStore } from '~/store/users'
import { workflowStore } from '~/store/workflows'
import type { WorkflowType } from '~/components/Settings/Workflows/config'
import type { ReportStatus } from '~/models/documents/jReport'
import { defaultWorkflows } from '~/components/Settings/Workflows/config'

export interface TypedWorkflowDto {
  type: WorkflowType
  status: ReportStatus
  workflowId: string | null
}

class ReportWorkflowService {
  checkIfHasRightsOnCurrentStatus(WorkflowDtos: TypedWorkflowDto[]): boolean {
    let hasRight = true

    for (const workflowDto of WorkflowDtos) {
      if (!this.hasRightByType(workflowDto.type, workflowDto.status, workflowDto.workflowId)) {
        hasRight = false
        break
      }
    }

    return hasRight
  }

  hasRightByType(type: WorkflowType, status: ReportStatus, workflowId: string | null = null): boolean {
    const workflow = this.getWorkflow(workflowId, type)

    if (!workflow)
      return true

    const user = usersStore().user

    const currentWorkflowStep = workflow.steps.find(e => e.status === status)

    if (!currentWorkflowStep?.permissions?.length)
      return true

    return currentWorkflowStep?.permissions?.some(e => user.role_ids.includes(e))
  }

  getWorkflow(workflowId: string | null = null, type: number) {
    if (workflowId)
      return workflowStore().findWorkflow(workflowId)
    return workflowStore().findWorkflowByType(type) || defaultWorkflows.find(e => e.type === this.type)!
  }
}

const reportWorkflowService = new ReportWorkflowService()

export default reportWorkflowService
