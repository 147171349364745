import DbHelper from '~/helpers/dbHelper'
import { VIEWS_COLLECTION_NAME } from '~/config/storage'
import { usersStore } from '~/store/users'
import { JView } from '~/models/views/jView'
import { removeInstance } from '~/utils/object'
import { viewsStore } from '~/store/views'

const dbHelper = new DbHelper()

interface createViewForm {
  name: string
  description?: string
  view_configuration: {
    filters: any
    groupBy: any
    displayType: string
  }
}

interface updateViewForm extends Partial<createViewForm> {
  id: string
}

class ViewController {
  async getUserViews() {
    const currentUser = usersStore().user
    const views = await dbHelper.getAllDataFromCollectionWithAll(VIEWS_COLLECTION_NAME, {
      where_constraints: [
        {
          field: 'client_id',
          compare: '==',
          value: currentUser.client_id,
        },
        {
          field: 'site_id',
          compare: '==',
          value: currentUser.site_id,
        },
        {
          field: 'created_by',
          compare: '==',
          value: currentUser.id,
        },
      ],
    })

    return views
  }

  async createView(viewParams: createViewForm) {
    const user = usersStore().user

    const view = new JView({
      client_id: user.client_id,
      site_id: user.site_id,
      created_date: new Date().getTime(),
      created_by: user.id,
      ...viewParams,
    })

    const rawView = removeInstance(view)

    rawView.view_configuration.filters.forEach((e) => {
      for (const i in e) {
        if (e[i] === undefined)
          e[i] = null
      }
    })

    const createdView = await dbHelper.addDataToCollection(VIEWS_COLLECTION_NAME, rawView)

    await dbHelper.updateDataToCollection(VIEWS_COLLECTION_NAME, createdView.id, { id: createdView.id })
  }

  async updateView(viewParams: updateViewForm) {
    const { id, ...params } = viewParams

    await dbHelper.updateDataToCollection(VIEWS_COLLECTION_NAME, viewParams.id, params)
  }

  async deleteView(viewId: string) {
    await dbHelper.deleteData(VIEWS_COLLECTION_NAME, viewId)

    viewsStore().removeView(viewId)
  }

  setupView() {
    const user = usersStore().user

    dbHelper.setupCollectionSnapshot({
      collectionName: VIEWS_COLLECTION_NAME,
      checks: [
        {
          field: 'client_id',
          compare: '==',
          value: user.client_id,
        },
        {
          field: 'site_id',
          compare: '==',
          value: user.site_id,
        },
      ],
      callback: async (views: JView[]) => {
        viewsStore().updateViews(views)
      },
    })
  }
}

const viewsController = new ViewController()

export default viewsController
