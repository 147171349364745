import { Timestamp, documentId } from 'firebase/firestore'
import _ from 'lodash'
import { getSettings, getUsers } from '../settings'
import { DocumentStatus } from '~/models/documents/jDocument'
import { removeInstance } from '~/utils/object'
import { StepType, buildStep } from '~/models/documents/jStep'
import DbHelper from '~/helpers/dbHelper'
import {
  DOCUMENTS_COLLECTION_NAME,
  STEPS_COLLECTION_NAME,
  TEMPLATES_COLLECTION_NAME,
} from '~/config/storage'
import { usersStore } from '~/store/users'
import type { JStep } from '~/models/documents/jStep'
import { SettingsType } from '~/models/settings/settings'
import { JTemplate } from '~/models/documents/jTemplate'
import { TemplateStatus } from '~/common/models/status'
import { createStep, setStep } from '~/controllers/documents'
import { settingsStore } from '~/store/settings'
import api from '~/helpers/ApiHelper'
import { documentSettingsStore } from '~/store/documentSettings'
import { DocumentSettingsType } from '~/models/documents/documentSettings'
import documentService from '~/services/documents/documentService'

const dbHelper = new DbHelper()

// template
export const createTemplate = async (template: JTemplate) => {
  const currentUser = usersStore().user
  template = new JTemplate(template)

  template.fromJson({
    client_id: currentUser.client_id,
    site_id: currentUser.site_id,
    created_by: currentUser.id,
    creation_date: new Date().getTime(),
    update_date: new Date().getTime(),
    updated_by: currentUser.id,
  })
  const stepIds = new Array<string>()

  await Promise.all(
    template.steps.map(async (step: JStep) => {
      step.templateName = template.name
      step.templateId = template.id
      step = buildStep(step)
      step.files_attached = step.files_attached.map((file) => {
        return Object.assign({}, file)
      })

      await createStep(step)
      stepIds.push(step.id)
    }),
  )
  template.steps = stepIds
  delete template.id

  return (
    await dbHelper.addDataToCollection(
      TEMPLATES_COLLECTION_NAME,
      removeInstance(template),
    )
  ).id
}

export const updateTemplate = async (template: JTemplate) => {
  const currentUser = usersStore().user
  template = new JTemplate(template)

  template.fromJson({
    client_id: currentUser.client_id,
    site_id: currentUser.site_id,
    created_by: currentUser.id,
    update_date: new Date().getTime(),
    updated_by: currentUser.id,
  })

  const stepIds = new Array<string>()
  const newlyAddedIds = new Array<string>()

  await Promise.all(
    template.steps.map(async (step) => {
      step.templateName = template.name
      step.templateId = template.id
      step = buildStep(step)
      if (step.description) {
        try {
          const height = await documentService.calculateDescriptionHeight(
            step.description,
          )
          step.description_height = height
        } catch (error) {
          console.error('Failed to calculate height for step:', step, error)
          // Handle the error appropriately
        }
      }
      step.files_attached = step.files_attached.map((file) => {
        return Object.assign({}, file)
      })

      if (step.id === '') newlyAddedIds.push(await createStep(step).id)
      else await setStep(step)
    }),
  )

  const filledTemplate = removeInstance(template)

  template.steps.forEach((element: JStep) => {
    if (element.id !== '') stepIds.push(element.id)
  })

  newlyAddedIds.forEach((element) => {
    stepIds.push(element)
  })

  const stepIdsTemplate = _.clone(template)
  stepIdsTemplate.steps = stepIds

  const listTypeOptions = documentSettingsStore().filterDocumentSettings(
    DocumentSettingsType.list_options,
  )

  await dbHelper.updateDataToCollection(
    TEMPLATES_COLLECTION_NAME,
    stepIdsTemplate.id,
    removeInstance(stepIdsTemplate),
  )
  const afterTemplate = await getFullTemplateById(template.id as string)
  let previousStepId = ''
  afterTemplate?.steps.sort((a: any, b: any) =>
    a.num_step > b.num_step ? 1 : -1,
  )
  afterTemplate.steps.forEach((step) => {
    step.previous_step_id = previousStepId
    previousStepId = step.id
  })

  await api.updateTemplate({ afterTemplate, listTypeOptions })

  return filledTemplate
}

export const updateTemplateStatus = async (
  templateId: string,
  status: TemplateStatus,
) => {
  return await dbHelper.updateDataToCollection(
    TEMPLATES_COLLECTION_NAME,
    templateId,
    { status },
  )
}

export const getFullTemplates = async (
  ids: Array<string> = [],
): Promise<JTemplate[]> => {
  const currentUser = usersStore().user
  const where_constraints = [
    {
      field: 'client_id',
      compare: '==',
      value: currentUser.client_id,
    },
    {
      field: 'site_id',
      compare: '==',
      value: currentUser.site_id,
    },
    {
      field: 'status',
      compare: '==',
      value: TemplateStatus.published,
    },
  ]

  if (ids.length > 0) {
    where_constraints.push({
      field: documentId(),
      compare: 'in',
      value: ids,
    })
  }

  const templates = await dbHelper.getAllDataFromCollectionWithAll(
    TEMPLATES_COLLECTION_NAME,
    { where_constraints },
  )

  const templatesWithSteps = await Promise.all(
    templates.map(async (template) => {
      const steps = await dbHelper.getAllDataFromCollectionFromIds(
        STEPS_COLLECTION_NAME,
        template.steps,
      )
      steps.forEach((step) => {
        step.tags = step.tags || []
        if (step.type === StepType.List) step.list_data = step.list_data || {}
      })
      return { ...template, steps }
    }),
  )

  return templatesWithSteps
}

export const getTemplatesWithSettings = async (): Promise<JTemplate[]> => {
  const currentUser = usersStore().user

  const [templates, users, settings] = await Promise.all([
    dbHelper.getAllDataFromCollectionWithAll(TEMPLATES_COLLECTION_NAME, {
      where_constraints: [
        {
          field: 'client_id',
          compare: '==',
          value: currentUser.client_id,
        },
        {
          field: 'site_id',
          compare: '==',
          value: currentUser.site_id,
        },
        {
          field: 'status',
          compare: 'not-in',
          value: [TemplateStatus.deleted],
        },
      ],
    }),
    getUsers(currentUser.client_id),
    getSettings(SettingsType.document_category),
  ])

  const loadReferences = async () => {
    templates.forEach((template: JTemplate) => {
      const creator = users.find((item) => item.id === template.created_by)
      const setting = settings.find((item) => item.id === template.category)
      const modifier = users.find((item) => item.id === template.updated_by)

      template.created_by = `${creator?.first_name} ${creator?.last_name}`
      template.updated_by = `${modifier?.first_name} ${modifier?.last_name}`
      template.updated_by_id = modifier?.id
      template.category = setting?.value
    })
  }
  await loadReferences()
  return templates
}

export const getTemplatesFromIds = async (
  templateIds: any[],
): Promise<any[]> => {
  return await dbHelper.getAllDataFromCollectionFromIds(
    TEMPLATES_COLLECTION_NAME,
    templateIds,
  )
}

export const getTemplateById = async (templateId: string): Promise<any> => {
  return await dbHelper.getDocFromCollection(
    TEMPLATES_COLLECTION_NAME,
    templateId,
  )
}

export const getFullTemplateById = async (templateId: string): Promise<any> => {
  const template = await dbHelper.getDocFromCollection(
    TEMPLATES_COLLECTION_NAME,
    templateId,
  )
  template.steps =
    (await dbHelper.getAllDataFromCollectionFromIds(
      STEPS_COLLECTION_NAME,
      template.steps,
    )) || []
  template.steps?.map((p) => {
    p.tags = p.tags || []
    if (p.type === StepType.List) p.list_data = p.list_data || {}

    return p
  })
  template.id = templateId

  template.steps.sort((a: any, b: any) => (a.num_step > b.num_step ? 1 : -1))

  return template
}

export const getDocumentsReference = async (
  templateId: string,
): Promise<any> => {
  if (!templateId) return Promise.resolve([])

  const currentUser = usersStore().user
  const users = usersStore().users
  const categories = settingsStore()
    .filterSettings(SettingsType.document_category)
    .map(({ id, value }: any) => ({ id, value }))

  const templates = await dbHelper.getAllDataFromCollectionWithAll(
    DOCUMENTS_COLLECTION_NAME,
    {
      where_constraints: [
        {
          field: 'client_id',
          compare: '==',
          value: currentUser.client_id,
        },
        {
          field: 'site_id',
          compare: '==',
          value: currentUser.site_id,
        },
        {
          field: 'templates',
          compare: 'array-contains',
          value: templateId,
        },
        {
          field: 'status',
          compare: 'not-in',
          value: [TemplateStatus.deleted, DocumentStatus.archived],
        },
        {
          field: 'next_version_document_id',
          compare: '==',
          value: '',
        },
      ],
    },
  )

  return templates.map((template: JTemplate) => {
    const modifier = usersStore().findUser(template.updated_by)
    const category = categories.find((item) => item.id === template.category)
    template.updated_by = `${modifier?.first_name} ${modifier?.last_name}`
    template.updated_by_id = modifier?.id
    template.update_date = new Date(
      template.update_date instanceof Timestamp
        ? template.update_date.toDate()
        : template.update_date,
    )
    template.category = category?.value
    return template
  })
}
