export const CACHED_OBJECTS = {
  PRODUCTS: 'PRODUCTS',
  WORKPLACES: 'WORKPLACES',
  WORKPLACES_HISTORY: 'WORKPLACES_HISTORY',
  OPERATIONS: 'OPERATIONS',
  WORKORDERS: 'WORKORDERS',
  USERS: 'USERS',
  INPUT_DATA: 'INPUT_DATA',
  ROUTING: 'ROUTING',
  LOOKUPS: 'LOOKUPS',
  LOOKUPS_HISTORY: 'LOOKUPS_HISTORY',
  LAST_ACTIVITY_TIME: 'LAST_ACTIVITY_TIME',
  DISCONNECTED_NON_ACTIVITY: 'DISCONNECTED_NON_ACTIVITY',
  LATEST_VISITED_URL: 'LATEST_VISITED_URL',
  MESX_NOTIFICATIONS: 'MESX_NOTIFICATIONS',
}
