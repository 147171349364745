<script setup lang="ts">
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { Icons } from '~/models/Icon'
import {siteStore} from "~/store/site";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
    default: false,
  },
  showAttachment: {
    type: Boolean,
    required: false,
    default: false,
  },
  title: {
    type: String,
    required: false,
  },
  displayFile: {
    type: Boolean,
    required: false,
  },
  alertDrawer: {
    type: Boolean,
    required: false,
    default: false,
  },
  alertDrawerHub: {
    type: Boolean,
    required: false,
    default: false,
  },
  backdrop: {
    type: Boolean,
    required: false,
    default: false,
  },
  subTitle: {
    type: String,
    required: false,
  },
  removeContentPadding: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'update:modelValue', val: any): void
}>()

watch(
  () => props.open, (newVal) => {
    state.open = newVal
  },
)
const slots = useSlots()

const closeDrawer = () => {
  emit('close')
}

const handleDrawerWidth = () => {
  if (props.displayFile)
    return 'w-[50vw]'

  if (props.alertDrawer || props.alertDrawerHub)
    return 'w-[42rem]'

  return 'w-[500px]'
}

watch(() => props.modelValue, (value) => {
  const displayCrisp = import.meta.env.VITE_ENV_TEST !== 'true' && import.meta.env.VITE_ENV_TEST !== true && value && !siteStore().getFlag('show_intercom')
  // @ts-expect-error: Hide/Show Crisp Chatbox
  $crisp.push(['do', displayCrisp ? 'chat:show' : 'chat:hide'])
})
</script>

<template>
  <TransitionRoot
    as="template"
    :show="modelValue"
  >
    <Dialog
      as="div"
      class="fixed overflow-hidden z-40"
      :class="props.showAttachment ? '' : 'inset-0'"
      @close="props.showAttachment ? '' : closeDrawer()"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          v-if="backdrop"
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @click="closeDrawer"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div :class="handleDrawerWidth()">
              <div class="h-full flex flex-col bg-white shadow-2xl overflow-y-auto">
                <div
                  class="p-4 border-b border-gray-200 space-y-2"
                  :class="{
                    'p-8': props.alertDrawer,
                    'p-4': !props.alertDrawer,
                    'pb-0': props.alertDrawerHub,
                  }"
                >
                  <div
                    v-if="props.title"
                    data-cy="drawer-title"
                    class="flex items-start justify-between"
                    :class="{
                      'mb-8': props.alertDrawerHub,
                    }"
                  >
                    <DialogTitle class="text-xl font-semibold text-gray-900">
                      {{ props.title }}
                    </DialogTitle>
                    <div class="flex items-center">
                      <button
                        type="button"
                        class="bg-white rounded-md text-gray-400 hover:text-gray-500"
                        @click="closeDrawer()"
                      >
                        <component
                          :is="Icons.CLOSE"
                          class="h-7 w-7"
                        />
                      </button>
                    </div>
                  </div>
                  <div class="flex-1 overflow-y-auto space-y-4">
                    <slot name="header" />
                    <slot
                      name="tabs"
                    />
                  </div>
                  <div
                    v-if="props.subTitle"
                    class="flex items-start justify-between mt-1"
                  >
                    <DialogTitle class="text-xs font-light text-gray-400">
                      {{ props.subTitle }}
                    </DialogTitle>
                  </div>
                </div>
                <div
                  v-if="$slots['search-section']"
                  class="border-b border-gray-200 p-4 "
                >
                  <slot name="search-section" />
                </div>
                <div
                  class="relative flex-1 flex flex-col overflow-y-auto"
                  :class="[props.alertDrawer ? 'p-8' : 'p-4', props.removeContentPadding ? '!p-0' : '']"
                >
                  <slot name="content" />
                </div>
                <div
                  v-if="props.alertDrawer && slots['bottom-content']"
                  class="relative"
                >
                  <slot name="bottom-content" />
                </div>
                <div :class="{ 'bg-[#FAFAFA] p-8': props.alertDrawer && slots.footer }">
                  <slot name="footer" />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
