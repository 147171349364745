import { defineStore } from 'pinia'
import { WorkflowType } from '~/components/Settings/Workflows/config'
import RoleController from '~/controllers/roles'

export const rolesStore = defineStore({
  id: 'role',
  state: () => ({
    availableRoles: [] as any,
    actions: ['read', 'create', 'modify', 'change-status', 'delete', '*', 'test'],
    ressources: ['documents', 'templates', 'reports', 'alerts', 'settings', 'permissions', 'instructions'],
    permissions: ['read', 'create', 'modify', 'change-status', 'delete'],
  }),
  getters: {
    conceptorRoles(state) {
      return state.availableRoles.filter(e => e.conceptorAccess)
    },
  },
  actions: {
    async loadRoles(): Promise<any> {
      const roles: any = await RoleController.getRoles()
      const getPermsLength = (perms: any) => Object
        .values(perms)
        .reduce((acc: any, curr: any) => acc + curr.length, 0) as number

      this.availableRoles = roles
        .sort((curr, next) => getPermsLength(next.permissions) - getPermsLength(curr.permissions))
        .map(this.setRolePermissions)
    },
    findRoleById(roleId: number) {
      return this.availableRoles?.find(e => e.id === roleId) || []
    },
    findRoleByIds(roleIds: number[]) {
      return this.availableRoles.constructor === Array ? this.availableRoles?.filter(e => roleIds?.includes(e.id)) : []
    },
    setRolePermissions(role) {
      const finalPerms = {}

      for (const ressource of this.ressources) {
        finalPerms[ressource] = {}

        for (const permission of this.permissions) {
          finalPerms[ressource][permission] = Array.isArray(role.permissions?.[ressource])
            ? role.permissions?.[ressource]?.includes(permission)
            : false
        }
      }

      role.permissions = finalPerms

      return role
    },
    getRolesAccessBasedOnType(type) {
      const workflowTypeKeys = Object
        .keys(WorkflowType)
        .filter((key: string) => Number.isNaN(Number(key)) && key !== 'CREATE')
        .map(e => e.toLowerCase())

      // TODO Sylvain => we did this because operations and workorders doesn't have entries in roles
      const redirectworkflowType = (type === 2 || type === 4) ? 1 : type
      const workflowType = workflowTypeKeys[redirectworkflowType]

      return this.availableRoles
        .filter(e => e.permissions[workflowType]['change-status'])
        .map(e => ({ value: e.id, label: e.name }))
    },
  },
})
