import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'

export class NumberCell extends Cell {
  constructor(rowIndex: number, colIndex: number, inputs: JInputData[], step: JStep, isHistory: boolean) {
    if (step.type !== StepType.Number)
      throw new Error('Type mismatch')

    super(rowIndex, colIndex, inputs, step, isHistory)
  }

  get latestValue(): string {
    let value = super.latestValue

    if (this._step.separatorDecimal === ',')
      value = `${value}`.replace('.', ',')

    return value.replaceAll('\n', '<br />') ?? null
  }
}

export default NumberCell
