import type { UserModule } from '~/types'
import { usersStore } from '~/store/users'

export const install: UserModule = ({ app }) => {
  app.mixin({
    methods: {
      canInteract(resource = ''): Boolean {
        const user = usersStore().user
        const readOnlyModeActive = user.access_rights?.readonly
        return !readOnlyModeActive
      },
    },
  })
  return app
}
