import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'
import {formatDate} from "~/utils/formatter";
import {usersStore} from "~/store/users";

export class CheckboxCell extends Cell {
  constructor(rowIndex: number, colIndex: number, inputs: JInputData[], step: JStep, isHistory: boolean) {
    if (step?.type !== StepType.Checkbox)
      throw new Error('Type mismatch')
    super(rowIndex, colIndex, inputs, step, isHistory)
    this._isActivated = step.last_sampling_areas[colIndex] || this.latestValue !== null
  }

  get latestValue(): string | null {
    const value = super.latestValue
    return value || null
  }

  parseAnswer(): string {
    const answer: JInputData | null = this.restoreValue ?? this.justifyKoValue ?? this.getLatestInputData()
    return answer?.value ? 'Valide' : 'Non valide'
  }

  getHistoryTooltip(): string {
    return this.getHistory().filter((value, index) => index > 0)
        .map(history => {
          return `${history.value ? 'checked' : 'unchecked'} ${formatDate(history.update_date)}  ${usersStore().findUser(history.updated_by, {full: true})} \n`
        })
        .join(' ')
  }
}

export default CheckboxCell
