import { SettingsType, Team } from '~/models/settings/settings'
import { createSetting } from '~/controllers/settings'
import { settingsStore } from '~/store/settings'

export const convertTimeToNumber = (hours: number, minutes: number) => {
  const min = minutes / 60

  return hours + min
}

export const timeConvert = (n = null) => {
  const hours = Math.floor(n)
  const rMinutes = n - hours
  const minutes = rMinutes * 60

  return { hours, minutes }
}

export const convertRangeToDate = (date: Date, starting_time: number, ending_time: number): any => {
  const convertedStartTime = timeConvert(starting_time)
  const convertedEndTime = timeConvert(ending_time)

  if (starting_time < ending_time) {
    return {
      starting_time: date.setHours(convertedStartTime.hours, convertedStartTime.minutes),
      ending_time: date.setHours(convertedEndTime.hours, convertedEndTime.minutes),
    }
  }

  if (date.getHours() > starting_time && date.getHours() <= 23.99) { // before swith to the next day
    const intermediate = new Date(date.getTime())
    const nextDay = new Date(intermediate)
    nextDay.setDate(nextDay.getDate() + 1)

    return {
      starting_time: date.setHours(convertedStartTime.hours, convertedStartTime.minutes),
      ending_time: nextDay.setHours(convertedEndTime.hours, convertedEndTime.minutes),
    }
  }
  else {
    const intermediate = new Date(date.getTime())
    const previousDay = new Date(intermediate)
    previousDay.setDate(previousDay.getDate() - 1)

    return {
      starting_time: previousDay.setHours(convertedStartTime.hours, convertedStartTime.minutes),
      ending_time: date.setHours(convertedEndTime.hours, convertedEndTime.minutes),
    }
  }
}

export const convertHourToDate = (date: Date, hours: number) => {
  const convertedStartTime = timeConvert(hours)

  date.setHours(convertedStartTime.hours, convertedStartTime.minutes)
  return date
}

export const getCurrentWorkingTeam = async (): Promise<Team | undefined> => {
  const teamsSchedules = settingsStore().filterSettings(SettingsType.team).map(p => <Team>p)

  const result = teamsSchedules.find((p) => {
    const starting_time = convertHourToDate(new Date(), p.starting_time)
    const ending_time = convertHourToDate(new Date(), p.ending_time)

    const now = new Date()
    if (starting_time < ending_time) {
      if (now.getTime() >= starting_time.getTime() && now.getTime() < ending_time.getTime())
        return true
      return false
    }
    else {
      const nextDay = new Date()
      nextDay.setDate(nextDay.getDate() + 1)
      const ending_time_tomorrow = convertHourToDate(nextDay, p.ending_time)
      if (now.getTime() >= starting_time.getTime() && now.getTime() < ending_time_tomorrow.getTime())
        return true

      const previousDay = new Date()
      previousDay.setDate(previousDay.getDate() - 1)
      const starting_time_yesterday = convertHourToDate(previousDay, p.starting_time)
      if (now.getTime() >= starting_time_yesterday.getTime() && now.getTime() <= ending_time.getTime())
        return true
    }
    return false
  })

  return result
}

export const getTeam = async (teamId): Promise<Team[]> => {
  const teamsSchedules = settingsStore().filterSettings(SettingsType.team).map(p => <Team>p)

  return teamsSchedules
}

export const getTeams = async (): Promise<Team[]> => {
  const teamsSchedules = settingsStore().filterSettings(SettingsType.team).map(p => <Team>p)

  return teamsSchedules
}

export const saveTeam = async (name: string, startingHour: number, startingMinutes: number, endingHour: number, endingMinutes: number) => {
  const startingTime = convertTimeToNumber(startingHour, startingMinutes)
  const endingTime = convertTimeToNumber(endingHour, endingMinutes)

  const team = new Team(name, startingTime, endingTime)
  await createSetting(team)
}
