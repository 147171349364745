import { defaultConfig, plugin } from '@formkit/vue'
import { generateClasses } from '@formkit/tailwindcss'
import { de, fr } from '@formkit/i18n'
import { formkitTheme } from '~/modules/formkit-theme'
import type { UserModule } from '~/types'
// import '@formkit/themes/genesis/theme.css'

const currentLanguage = localStorage.getItem('lang')

// Configure FormKit:
const config = defaultConfig({
  locales: { de, fr },
  locale: currentLanguage ?? 'fr',
  // @Sylvain: Maybe we can generate this dynamically after
  messages: {
    en: {
      validation: {
        required() {
          return 'This field is required.'
        },
        min({ args }) {
          return `The value cannot be less than ${args[0]}.`
        },
        max({ args }) {
          return `The value cannot be higher than ${args[0]}.`
        },
      },
    },
    fr: {
      validation: {
        required() {
          return 'Ce champ est requis.'
        },
        min({ args }) {
          return `La valeur ne peut pas être inférieure à ${args[0]}.`
        },
        max({ args }) {
          return `La valeur ne peut pas être supérieure à ${args[0]}.`
        },
      },
    },
  },
  config: {
    classes: generateClasses(
      formkitTheme,
    ),
  },
})

export const install: UserModule = ({ isClient, initialState, app }) => {
  app.use(plugin, config)
}
