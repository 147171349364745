interface IDocumentService {
  calculateDescriptionHeight(description: string): Promise<number>
}

class DocumentService implements IDocumentService {
  constructor() {}

  calculateDescriptionHeight(description: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const tempDiv = document.createElement('div')
      tempDiv.style.visibility = 'hidden'
      tempDiv.style.position = 'absolute'
      tempDiv.style.width = '450px'
      tempDiv.innerHTML = description
      document.body.appendChild(tempDiv)

      const images = tempDiv.querySelectorAll('img')
      let loadedImages = 0

      const imageLoaded = () => {
        loadedImages++
        if (loadedImages === images.length) {
          const height = tempDiv.clientHeight
          document.body.removeChild(tempDiv)
          resolve(height)
        }
      }

      if (images.length === 0) {
        // No images, resolve immediately
        const height = tempDiv.clientHeight
        document.body.removeChild(tempDiv)
        resolve(height)
      } else {
        images.forEach((img) => {
          if (img.complete) {
            imageLoaded() // For cached images
          } else {
            img.onload = imageLoaded
            img.onerror = () => reject('Failed to load image: ' + img.src)
          }
        })
      }
    })
  }
}

const documentService = new DocumentService()

export default documentService
