import _ from 'lodash'
import { useAcl } from '@clabnet/vue-simple-acl'
import { useRoute } from 'vue-router'
import CustomHeader from '~/components/UI/CustomHeader.vue'
import type { JReport } from '~/models/documents/jReport'
import type { JDocument } from '~/models/documents/jDocument'
import type { JStep, JStepLastTargets } from '~/models/documents/jStep'
import type { JInputData } from '~/models/report/jInputData'
import {
  addDefaultHeader,
  addSampling,
  checkIsCellDisabled,
  computeGlobalsampling,
  computeRowHeight,
  computeSamplingByType,
  computeTypeRepetitionInStep,
  getCellEditorSelector,
  getCellRendererSelector,
  getCellStyle,
  getColumnState,
  getHeaderComponentParams,
  getSeparator,
  handleBranchingForNewColumn,
  handleTags,
  isEditable,
  refreshSampling,
  setDetailsByType,
  setReportRepetition,
} from '~/services/grid'
import { ReportStatus, RoleGrid } from '~/models/documents/jReport'
import type { JSession } from '~/models/sessions/JSession'
import type { ListOptions } from '~/models/documents/documentSettings'
import {
  DocumentSettingsType,
  RepetitionType,
} from '~/models/documents/documentSettings'
import {
  getFormulaContext,
  setReportStatus,
} from '~/controllers/reports/reportsController'
import { documentSettingsStore } from '~/store/documentSettings'
import { usersStore } from '~/store/users'
import TextCell from '~/grid/TextCell'
import MeasureCell from '~/grid/MeasureCell'
import ListCell from '~/grid/ListCell'
import TimeCell from '~/grid/TimeCell'
import NumberCell from '~/grid/NumberCell'
import PhotoCell from '~/grid/PhotoCell'
import BooleanCell from '~/grid/BooleanCell'
import CheckboxCell from '~/grid/CheckboxCell'
import {
  DisplayColumnType,
  DisplayLineType,
} from '~/models/documents/jDocument'
import { alertsStore } from '~/store/alerts'
import { gridStore } from '~/store/grid'
import loggerHelper from '~/helpers/LoggerHelper'
import type { JSite } from '~/models/sites'
import { siteStore } from '~/store/site'
import {
  validateMandatoryStepFilled,
  validateStepBorderLine,
  validateStepKo,
} from '~/controllers/documents'
import { ColumnState } from '~/utils/report'
import { settingsStore } from '~/store/settings'
import { SettingsType } from '~/models/settings/settings'
import { castInputDataValue } from '~/helpers/UtilsHelper'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'
import type { Cell } from '~/grid/Cell'

const cellClasses = {
  Text: TextCell,
  List: ListCell,
  Boolean: BooleanCell,
  Measure: MeasureCell,
  Checkbox: CheckboxCell,
  Time: TimeCell,
  Number: NumberCell,
  Photo: PhotoCell,
}

export enum CellMode {
  FOCUSING = 'focusing',
  EDITING = 'editing',
  EDITED = 'edited',
}

const acl = useAcl()

class Grid {
  private _masterSession: any
  private _session: JSession
  private _report: JReport
  private _document: JDocument
  private _steps: JStep[]
  private _inputData: JInputData[]
  private _cells: Cell[]
  private _groupedCells: object
  private _columnsDefinition: object[]
  private _rowsDefinition: object[]
  private _hasAttachments: boolean
  private _shiftColumnIndex: number
  private _pinnedColumnIndex: number | null
  private _isHistory: boolean
  private _isJustifyKoEnabled: boolean
  private _highlightedCols: number[]
  private _lastColIndexUsed: number
  private _currentSite: JSite
  private _listOptions: ListOptions[]

  //
  private _route = useRoute()
  private _allStepsSamplingAreas = []
  private _role: string
  private _isWorkplaceDoc: boolean
  private _onExport: boolean
  // private _showJustificationModal: boolean

  constructor(
    report: JReport,
    document: JDocument,
    masterSession: any,
    session: JSession,
    isHistory: boolean,
    role: string,
    isWorkplaceDoc = false,
    onExport = false,
  ) {
    if (!report || !document || !session)
      throw new Error('Arguments can not be empty!')

    this._report = report
    this._isHistory = isHistory
    this._document = document
    this._steps = report.steps
    this._lastColIndexUsed = report.last_col_index
    this._inputData = report.inputData ?? []
    this._pinnedColumnIndex = report.pinned_column_index
    this._shiftColumnIndex = 0
    this._masterSession = masterSession
    this._session = session
    this._isJustifyKoEnabled = settingsStore().findSettingById(
      document.category,
    )?.justify_ko
    this._cells = []
    this._rowsDefinition = []
    this._columnsDefinition = []
    this._listOptions = []
    this._highlightedCols = []
    this._role = role
    this._hasAttachments = false
    this._currentSite = siteStore().site || []
    this._isWorkplaceDoc = isWorkplaceDoc
    this._onExport = onExport
    // this._showJustificationModal = false
  }

  public readonly ragCellClassRules = {
    'rag-red-outer': (params: any) => {
      // TODO: get the value of the cell, get mandatory of row, get the columns definition to get the state of cell
      // The method use negativ logic, return false if the step is valid and true otherwise
      const currentStep = this._steps[params?.rowIndex]
      if (
        checkIsCellDisabled(
          currentStep?.col_ids_to_disable,
          params.colDef.realIndex,
          currentStep.parentFrequency,
          currentStep.disabled,
        )
      )
        return false

      const colIndex = params.colDef.index - params.data?.shiftIndex
      const cell = this.getCellByIndex(params?.rowIndex, colIndex)
      const lastValue = cell?.getLatestInputData()?.value
      const headerComponentParams = this._columnsDefinition.find(
        (col) => col.index === Number(params.colDef.index),
      )?.headerComponentParams

      if (
        headerComponentParams?.columnState !== ColumnState.new ||
        (lastValue !== '' && lastValue !== undefined)
      ) {
        if (
          params.colDef.index - this._shiftColumnIndex ===
          this._lastColIndexUsed
        )
          return validateStepKo(lastValue, currentStep, colIndex, false)

        return (
          validateStepKo(
            lastValue,
            currentStep,
            params.colDef.realIndex,
            true,
          ) ||
          validateMandatoryStepFilled(
            lastValue,
            currentStep.is_mandatory,
            cell?.isActivated,
          )
        )
      }
    },
    'rag-orange-outer': (params: any) => {
      // for some reason this has to be instanciated here
      const tolerance =
        settingsStore().filterSettings(SettingsType.tolerance)[0]?.value || 0

      const cell = this.getCellByIndex(
        params?.rowIndex,
        params.colDef.index - params.data?.shiftIndex,
      )
      const lastValue = cell?.getLatestInputData()?.value
      const currentStep = this._steps[params?.rowIndex]
      const colIndex = params.colDef.index - params.data?.shiftIndex

      return validateStepBorderLine(lastValue, currentStep, tolerance, colIndex)
    },
    'rag-yellow-bg': (params: any) => {
      const highlightedColsForNotifs = (
        this._route?.query?.highlighted_cols as string
      )
        ?.split(',')
        .map(Number)
        .map((item) => item + this._shiftColumnIndex)

      return highlightedColsForNotifs?.includes(params.column.colDef.colId)
    },
  }

  get document(): JDocument {
    return this._document
  }

  updateGrid(onlyKoColumns = false): void {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    this.buildGridHeader(onlyKoColumns).updateGridBody(onlyKoColumns)
  }

  async prepareGrid(onlyKoColumns = false): void {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    this.buildGridHeader(onlyKoColumns)
    await this.buildGridBody(onlyKoColumns)
  }

  async prepareSteps(): Promise<this> {
    // map report steps on time
    this._listOptions = documentSettingsStore().filterDocumentSettings(
      DocumentSettingsType.list_options,
    )

    this._steps.sort((a: any, b: any) => (a.num_step > b.num_step ? 1 : -1))
    for (let index = 0; index < this._steps.length; index++) {
      let step = this._steps[index]

      // set grid repetitions
      this.stepFrequency(step)
      step = setReportRepetition(step)
      step = computeTypeRepetitionInStep(step)

      const contextScope = getFormulaContext(
        this._role,
        step,
        this.reportGridSize(),
        this._session,
      )

      const { aqlTags, sampling } = await computeSamplingByType(
        step,
        0,
        this.reportGridSize(),
        contextScope,
      )

      const samplingAreas =
        Object.keys(step?.last_sampling_areas ?? {}).length !== 0 &&
        this._role !== RoleGrid.preview
          ? [step.last_sampling_areas]
          : [sampling]
      this._allStepsSamplingAreas.push(samplingAreas)

      const details = setDetailsByType(
        step,
        samplingAreas,
        this._shiftColumnIndex,
        step.updatedShiftIndex,
        this._listOptions,
        step.typeRepetition[0] !== RepetitionType.formula,
      )

      details.updatedShiftIndex =
        !isUndefinedOrNullOrEmpty(step.updatedShiftIndex) &&
        step.updatedShiftIndex !== 0
          ? step.updatedShiftIndex
          : this._columnsDefinition.length

      const mappedTags = handleTags(
        documentSettingsStore().filterDocumentSettings(
          DocumentSettingsType.step_tag,
        ),
        step.tags,
      )

      step.mappedTags = mappedTags
      step.details = details
      step.last_sampling_areas = _.last(samplingAreas)

      this._rowsDefinition.push(
        this.buildRow(index, step, mappedTags, details, aqlTags),
      )

      if (
        this._document.displayLineType === DisplayLineType.editable_lines &&
        !step.last_sampling_areas?.includes(true)
      ) {
        step.hidden = true
      }

      if (this._role === RoleGrid.preview && step.hidden) {
        step.hidden = !step.last_sampling_areas?.includes(true)
      }

      this._steps[index] = step
    }

    return this
  }

  stepFrequency(step: JStep): void {
    if (!step.branching?.length) return

    const allConditionalStepIds = step.branching.flatMap(
      (b) => b.action_details?.steps_to_display,
    )
    this._steps = this._steps.map((s) => {
      if (allConditionalStepIds.includes(s.id))
        s.parentFrequency = step.frequency
      return s
    })
  }

  buildGridHeader(onlyKoColumns = false): this {
    this._columnsDefinition = addDefaultHeader(
      this._hasAttachments,
      this._inputData,
      this._onExport,
    )
    this._shiftColumnIndex = this._columnsDefinition.length

    this._highlightedCols =
      this._report?.steps?.flatMap(
        (step) => step.last_targets?.map((e) => e.init_col_id) || [],
      ) || []

    return this
  }

  async buildGridBody(onlyKoColumns = false): this {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    // Init steps
    await this.prepareSteps()

    const groupedInputs = _.groupBy(this._inputData, (input: JInputData) => {
      input.value = castInputDataValue(input)
      return `${input.col_id}-${input.row_id}`
    })

    const mergedSampling = computeGlobalsampling(this._allStepsSamplingAreas)

    this._cells = []
    let colIndex = -1
    for (let i = 0; i < this.reportGridSize(); i++) {
      if (
        onlyKoColumns &&
        ColumnState.ko !==
          getColumnState(
            this._steps,
            this._inputData,
            i,
            this._report.last_col_index === i,
            this.getMaxIndexUsed() || 0,
            gridStore().isDisabled,
            RoleGrid.editor,
          )
      )
        continue
      colIndex = colIndex + 1
      handleBranchingForNewColumn(this._steps, colIndex)
      for (let j = 0; j < this._steps.length; j++) {
        const step = this._steps[j]
        const rowIndex = j
        this._cells.push(
          new cellClasses[step?.type](
            rowIndex,
            colIndex,
            groupedInputs[`${i}-${rowIndex}`] || [],
            step,
            this._isHistory,
          ),
        )
      }
      let columnHide = false
      if (this._document.displayType === DisplayColumnType.all_columns)
        columnHide = false
      else columnHide = !mergedSampling[i]

      const headerParams = getHeaderComponentParams(
        this._steps,
        this._inputData,
        i,
        0,
        this._report.last_col_index,
        this._document.grid_header,
        this._document.trigger,
        this.getMaxIndexUsed() || 0,
        gridStore().isDisabled,
        alertsStore()?.getAlertsForReport(this._report.id),
      )

      this._columnsDefinition.push(
        this.buildColumn(i, headerParams, columnHide),
      )
    }

    this._groupedCells = _.keyBy(
      this._cells,
      (cell: Cell) => `${cell.rowIndex}-${cell.colIndex}`,
    )
    return this
  }

  updateGridBody(onlyKoColumns = false): this {
    if (!this._report)
      throw new Error('Error while building cells, Grid not found')

    // Init steps
    // this.prepareSteps()

    for (let i = 0; i < this.reportGridSize(); i++) {
      const headerParams = getHeaderComponentParams(
        this._steps,
        this._inputData,
        i,
        0,
        this._report.last_col_index,
        this._document.grid_header,
        this._document.trigger,
        this.getMaxIndexUsed() || 0,
        gridStore().isDisabled,
        alertsStore()?.getAlertsForReport(this._report.id),
      )

      this._columnsDefinition.push(this.buildColumn(i, headerParams, false))
    }

    this._groupedCells = _.keyBy(
      this._cells,
      (cell: Cell) => `${cell.rowIndex}-${cell.colIndex}`,
    )
    return this
  }

  buildRow(
    index: number,
    step: any,
    mappedTags: any[],
    details: any,
    aqlTags: any,
  ): JStep {
    return {
      ...step,
      file: step.files_attached[0],
      details,
      index,
      hidden: step.hidden || false,
      isMandatory: step.is_mandatory,
      isDisabled: step.disabled || false,
      colIdsToDisable: step.col_ids_to_disable || [],
      shiftIndex: this._shiftColumnIndex,
      tags: mappedTags,
      aqlTags,
      rowHeight: computeRowHeight({
        tags: mappedTags,
        name: step.name,
        description: step.description,
        descriptionHeight: step.description_height,
        type: step?.type,
        onExport: this._onExport,
      }),
      separatorDecimal: getSeparator(usersStore().user.language, 'decimal'),
    }
  }

  buildColumn(index: number, headerParams: any, columnHide: boolean): object {
    const hasNoReportPermission = !acl.can('edit-report', [
      this._document,
      this._report,
      this._masterSession,
      this._session,
    ])

    return {
      headerClass: 'header',
      headerComponent: CustomHeader,
      headerComponentParams: {
        ...headerParams,
        frequency: this._document.frequency,
      },
      field: headerParams.name,
      gridType: RoleGrid.conceptor,
      reportId: this._onExport ? this._report.id : null,
      cellDataType: false,
      realIndex: index,
      width: this._onExport ? 155 : undefined,
      index: this._columnsDefinition.length,
      editable: (params: any, isNativeCellDisabled = true) => {
        return isEditable(
          params,
          index,
          usersStore().user.id,
          this._inputData,
          this._document,
          this._role,
          this._report,
          isNativeCellDisabled,
          hasNoReportPermission,
          this._isHistory,
        )
      },
      cellStyle: (params: any) => {
        return getCellStyle(
          params,
          this._report,
          this._isHistory,
          index,
          this._highlightedCols,
          this._pinnedColumnIndex + this._shiftColumnIndex,
        )
      },
      cellClassRules: this.ragCellClassRules,
      pinned:
        this._pinnedColumnIndex ===
        this._columnsDefinition.length - this._shiftColumnIndex,
      colId: this._columnsDefinition.length,
      suppressMovable: true,
      hide: columnHide,
      cellRendererSelector: (params: any) => {
        return getCellRendererSelector(params)
      },
      cellEditorSelector: (params: any) => {
        return getCellEditorSelector(params, false)
      },
    }
  }

  updateSampling(rowIndex: number, colId: number): this {
    const step = this._steps.find((step) => step.num_step === rowIndex + 1)
    const isRecomputeSampling = this._currentSite?.flags?.sampling?.recompute

    if (step?.last_sampling_areas) {
      const latestSamplingArea = step?.last_sampling_areas as any
      if (latestSamplingArea[colId] === false) {
        if (isRecomputeSampling && step.details?.compute_sampling) {
          const refreshedSampling = refreshSampling(
            latestSamplingArea,
            step.frequency,
            step.sample,
            colId,
            colId + this._report?.gridSize,
            this._shiftColumnIndex,
            this._report?.gridSize,
          )
          if (refreshedSampling && refreshedSampling.length > 0) {
            step.last_sampling_areas = _.clone(refreshedSampling)
            loggerHelper.logEvent(
              `Sampling has been forced and recomputed.\n Report id : ${this._report.id}\n col id : ${colId}\n row id : ${rowIndex}\n Frequency : ${step.frequency}\n Sample : ${step.sample}\n Previous sampling : ${latestSamplingArea}\n new sampling : ${refreshedSampling}`,
            )
          }
        } else if (
          latestSamplingArea &&
          (!isRecomputeSampling || !step?.details?.compute_sampling)
        ) {
          if (step) step.last_sampling_areas[colId] = true
          loggerHelper.logEvent(
            `Sampling has been forced without recomputing.\n Report id : ${this._report.id}\n col id : ${colId}\n row id : ${rowIndex}\n Frequency : ${step.frequency}\n Sample : ${step.sample}\n Previous sampling : ${latestSamplingArea}\n new sampling : ${latestSamplingArea}`,
          )
        }
      }
    }
    return this
  }

  addColumn = async () => {
    loggerHelper.logInfo('operator report report-multiple AddColumn function')
    const newColIndex = this._columnsDefinition?.length
    const newColIndexOffshift = newColIndex - this._shiftColumnIndex
    // state.disableDelete = false
    const maxIndexUsed = this.getMaxIndexUsed() || 0

    // Refresh sampling for all rows
    this._steps.forEach((step: JStep) => {
      if (step?.details?.samplingAreas) {
        if (
          typeof step.last_sampling_areas[newColIndexOffshift] === 'undefined'
        ) {
          const refreshedSampling = addSampling(
            step.last_sampling_areas,
            step.frequency,
            step.sample,
            newColIndex,
            newColIndex + 1,
            step.details.updatedShiftIndex,
          )
          if (refreshedSampling && refreshedSampling.length > 0)
            step.last_sampling_areas = refreshedSampling
        }
      }
      if (step.last_targets) {
        const lastTarget = _.last(step.last_targets) as JStepLastTargets
        if (lastTarget) lastTarget.last_col_id = lastTarget.last_col_id + 1
      }
      const rowIndex = step.num_step - 1

      this._cells.push(
        new cellClasses[step?.type](
          rowIndex,
          newColIndexOffshift,
          [],
          step,
          this._isHistory,
        ),
      )
    })

    this._report.status = ReportStatus.in_progress
    await setReportStatus({
      report: this._report,
      status: ReportStatus.in_progress,
    })

    const columnDefs = this._columnsDefinition
    const headerParams = getHeaderComponentParams(
      this._steps,
      this._inputData,
      newColIndexOffshift,
      0,
      this._report.last_col_index,
      this._document.grid_header,
      this._document.trigger,
      maxIndexUsed,
      false,
      alertsStore()?.getAlertsForReport(this._report.id),
    )

    columnDefs.push(this.buildColumn(newColIndexOffshift, headerParams, false))

    this._columnsDefinition = [...columnDefs]

    this._report.gridSize++
  }

  setPinnedColumn = (columnToPin = null) => {
    loggerHelper.logInfo(
      'operator report report-multiple setPinnedColumn function',
    )

    if (columnToPin !== null) this._pinnedColumnIndex = columnToPin
    else this._pinnedColumnIndex = null

    const column = this._columnsDefinition?.find((column: any) => {
      return column?.index - this._shiftColumnIndex === columnToPin
    })
    if (!column) return
    column.pinned = !column.pinned
  }

  setNewTargetHeader = (newTargetColId: number) => {
    loggerHelper.logInfo(
      'operator report report-multiple setNewTargetHeader function',
    )
    this._highlightedCols = []
    this._steps?.forEach((step) => {
      if (step?.last_targets?.length)
        step.last_targets
          .map((e) => e.init_col_id)
          .forEach((colId) => this._highlightedCols.push(colId))
    })

    const column = this._columnsDefinition?.find((column: any) => {
      return column?.index - this._shiftColumnIndex === newTargetColId
    }) as any

    if (!column) return

    column.cellStyle = (params: any) => {
      return getCellStyle(
        params,
        this._report,
        this._isHistory,
        newTargetColId,
        this._highlightedCols,
        this._pinnedColumnIndex + this._shiftColumnIndex,
      )
    }
  }

  setNewAlertHeader = (colId: number) => {
    const column = this._columnsDefinition?.find((column: any) => {
      return column?.index - this._shiftColumnIndex === colId
    }) as any

    if (!column) return
    const alerts = alertsStore()?.getAlertsForReport(this._report.id)
    column.headerComponentParams.alerts = alerts?.filter((documentAlert) =>
      documentAlert.erroredSteps.some(
        (erroredStep) => erroredStep.answer.colId === colId,
      ),
    )
    column.headerComponentParams.alert = column.headerComponentParams.alerts[0]
    column.hide = false
  }

  unhideCol = (colIdToDisplay: number) => {
    const colId = colIdToDisplay
    const columnToUnhide = this._columnsDefinition.find(
      (column: any) => colId - 1 === column.index - this._shiftColumnIndex,
    ) as any

    if (!columnToUnhide) return
    columnToUnhide.hide = false
  }

  removeColumn = () => {
    loggerHelper.logInfo(
      'operator report report-multiple removeColumn function',
    )

    this._columnsDefinition.pop()
    this._report.gridSize--
  }

  updateInputData(inputData: JInputData): void {
    this._inputData.unshift(inputData)
    const cell = this.getCellByIndex(inputData.row_id, inputData.col_id)
    cell?.updateInputData(inputData)
  }

  reportGridSize(): number {
    return this._report.gridSize
  }

  lastColumnIndex() {
    return this._cells.length
  }

  steps(): JStep[] {
    return this._steps
  }

  columnsDefinition(): object[] {
    return this._columnsDefinition
  }

  rowsDefinition(): object[] {
    return this._rowsDefinition
  }

  cellsDefinition(): Cell[] {
    return this._cells
  }

  listOptions(): ListOptions[] {
    return this._listOptions
  }

  getCellByIndex(rowIndex: number, colIndex: number): Cell | undefined {
    return this._cells.find(
      (c) => c.rowIndex === rowIndex && c.colIndex === colIndex,
    )
  }

  // getCellByShowHistoryModal(): Cell | undefined {
  //   return this._cells.find(c => c.getShowHistoryModal())
  // }

  // getCellByShowJustificationModal(): Cell | undefined {
  //   return this._cells.find(c => c.getShowJustificationModal())
  // }

  getReport(): JReport {
    return this._report
  }

  get report(): JReport {
    return this._report
  }

  get isWorkplaceDoc(): boolean {
    return this._isWorkplaceDoc
  }

  get isHistory(): boolean {
    return this._isHistory
  }

  getDocument(): JDocument {
    return this._document
  }

  getMasterSession(): any {
    return this._masterSession
  }

  getSession(): JSession {
    return this._session
  }

  getShiftColumnIndex(): number {
    return this._shiftColumnIndex
  }

  getMaxIndexUsed(): number {
    return Math.max(...this._inputData?.map((data) => data.col_id)) ?? 0
  }

  isJustifyKoEnabled(): boolean {
    return this._isJustifyKoEnabled
  }

  // getShowHistoryModal(): boolean {
  //   return this._cells.some(c => c.getShowHistoryModal())
  // }

  // getShowJustificationModal(): boolean {
  //   return this._cells.some(c => c.getShowJustificationModal())
  // }

  // setShowJustificationModal(showJustificationModal): boolean {
  //   return this._showJustificationModal = showJustificationModal
  // }

  // get showJustificationModal(): boolean {
  //   return this._showJustificationModal
  // }

  setLastColIndexUsed(value: number): void {
    this._lastColIndexUsed = value
  }

  setInitialGridSize(value): void {
    this._report.initialGridSize = value
  }

  get rowDefinition(): any[] {
    return this._rowsDefinition
  }

  get onExport(): boolean {
    return this._onExport
  }

  set onExport(value: boolean) {
    this._onExport = value
  }
}

export default Grid
