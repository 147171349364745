import { SITES_COLLECTION_NAME } from '~/common/config/firebase/storage'
import DBHelper from '~/helpers/dbHelper'
import { usersStore } from '~/store/users'
import type { JSite } from '~/models/sites'

const dbHelper = new DBHelper()
const user = computed(() => usersStore().user)

class SiteController {
  async getCurrentSite(): Promise<JSite> {
    const sites: JSite[] = await dbHelper?.getAllDataFromCollectionFromIds(SITES_COLLECTION_NAME, [user.value.site_id])

    return sites[0]
  }

  async updateSiteFlags(flags) {
    await dbHelper.updateDataToCollection(SITES_COLLECTION_NAME, user.value.site_id, {
      flags,
    })
  }

  async getAllSites(): Promise<JSite[]> {
    const sites: JSite[] = await dbHelper.getAllDataFromCollectionWithWhere(SITES_COLLECTION_NAME, 'client_id', user.value.client_id)
    return sites
  }
}

const siteController = new SiteController()

export default siteController
