export enum ToastIcon {
  WARNING = 'warning',
  SUCCESS = 'success',
  LOADING = 'loading',
  ERROR = 'error',
  INFORMATION = 'information',
}

export interface IToast {
  show: boolean
  status: ToastIcon
  title: string
  content: string
  displayInterval: 3000
}
