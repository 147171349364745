import type { JReport } from '../documents/jReport'
import type { JDocument } from '../documents/jDocument'
import { hydrate } from '~/utils/object'

export enum SessionStatus {
  new = 1,
  in_progress,
  finished,
}

export enum SessionScope {
  WORKPLACE = 'workplace',
  WORKORDER = 'workorder',
}

export class JSessionContext {
  client_id = ''
  site_id = ''

  production_order_id = ''
  workplace_id = ''
  product_id = ''
  operation_id = ''
  quantity = 0
  tags = {}

  constructor(object: typeof JSessionContext | {}) {
    hydrate(this, object)
  }
}

export class JSession {
  id = ''
  client_id = ''
  site_id = ''
  master_session_id = 0
  is_deleted = false
  is_valid = true
  start_date: any = {}
  end_date: any = {}
  reports: JReport[] = []
  reports_ids: any[] = []
  documents_ids: any[] = []
  documents: JSessionDocument = {} as JSessionDocument

  status: SessionStatus = SessionStatus.new
  creator_id = ''
  stakeholders_operator_ids = []
  last_team = ''
  scope: SessionScope = SessionScope.WORKORDER

  session_context: JSessionContext = new JSessionContext({})
  created_by = ''
  updated_by = ''
  update_date = 0

  constructor(object: typeof JSession | {}) {
    hydrate(this, object)
  }
}

export class JSessionDocument {
  readonly_documents: JDocument[] = []
  read_write_documents: JDocument[] = []
  reports: JReport[] = []
}
