<script setup lang="ts">
import { useGetAllTags } from '~/composables/mesx/requests/tags'
import SimulatorTag from './SimulatorTag.vue'

const { data: tags } = useGetAllTags()
</script>

<template>
  <table
    id="simulator"
    class="w-full"
  >
    <thead>
      <tr>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Client
        </th>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Tag
        </th>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Tag type
        </th>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Machine(s)
        </th>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Temps cycle (ms)
        </th>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Generate data
        </th>
        <th
          scope="col"
          class="px-6 py-3"
        >
          Etat
        </th>
      </tr>
    </thead>
    <tbody>
      <SimulatorTag
        v-for="tag in tags"
        :key="tag.id"
        :tag="tag"
      />
    </tbody>
  </table>
</template>

<style lang="scss"></style>
