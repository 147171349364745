import { defineStore } from 'pinia'
import { apiStore } from '~/store/api'

export const lookupStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'lookups',
  // a function that returns a fresh state
  state: () => ({
    lookups: [] as any[],
    lookupsHistory: [] as any[],
  }),

  // optional getters
  getters: {
    getLookups(): any {
      return this.lookups
    },
    getLookupsHistory(): any {
      return this.lookupsHistory
    },
  },
  // optional actions
  actions: {
    async loadLookups(forceFetch = false): Promise<any> {
      const api = apiStore().getApiClient

      const lookups = await api.getLookupsData(forceFetch)
      this.lookups = lookups
    },
    async loadLookupHistory(forceFetch = false): Promise<any> {
      const api = apiStore().getApiClient

      const lookupsHistory = await api.getLookupsDataHistory(forceFetch)
      this.lookupsHistory = lookupsHistory
    },
    filterLookups(ids: any) {
      const lookups: any = this.lookups.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return lookups
    },
    filterLookupsHistory(ids: any) {
      const lookupsHistory: any = this.lookupsHistory.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return lookupsHistory
    },
    filterLookupsByType(type: string) {
      const lookups: any = this.lookups.filter((u: any) => u.type === type)
      return lookups
    },
    filterLookupsByTypes(types: string[]) {
      const lookups: any = this.lookups.filter((u: any) => types.includes(u.type))
      return lookups
    },
    filterLookupsHistoryByType(type: string) {
      const lookupsHistory: any = this.lookupsHistory.filter((u: any) => u.type === type)
      return lookupsHistory
    },
  },
})
