import { initializeApp } from 'firebase/app'
import { CACHE_SIZE_UNLIMITED, initializeFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { FIREBASE_CONFIG } from '~/config'

export const fb = initializeApp(FIREBASE_CONFIG)

// // Initialize Firebase
export const db = initializeFirestore(fb, {
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  experimentalForceLongPolling: true,
})

// export const fbperf = getPerformance(fb)
export const fb_storage = getStorage(fb)
export const fb_auth = getAuth(fb)

// if (reCAPTCHA_KEY && !import.meta.env.DEV) {
//   initializeAppCheck(fb, {
//     provider: new ReCaptchaEnterpriseProvider(reCAPTCHA_KEY),
//     isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
//   })
// }
