import DbHelper from '~/helpers/dbHelper'
import { DOCUMENT_SETTINGS_COLLECTION_NAME, SETTINGS_COLLECTION_NAME } from '~/config/storage'
import type { Settings, SettingsType } from '~/models/settings/settings'
import { usersStore } from '~/store/users'
import type { DocumentSetting, DocumentSettingsType } from '~/models/documents/documentSettings'

const dbHelper = new DbHelper()

export const getSettings = async (settingsType: SettingsType): Promise<Settings[]> => {
  const currentUser = usersStore().user

  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'site_id',
    compare: '==',
    value: currentUser.site_id as any,
  },
  {
    field: 'type',
    compare: '==',
    value: settingsType as any,
  },
  {
    field: 'deleted_at',
    compare: '==',
    value: null,
  }]

  return await dbHelper.getAllDataFromCollectionWithAll(SETTINGS_COLLECTION_NAME, { where_constraints })
}

export const getAllSettings = async (): Promise<Settings[]> => {
  const currentUser = usersStore().user

  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'site_id',
    compare: '==',
    value: currentUser.site_id as any,
  }]

  return await dbHelper.getAllDataFromCollectionWithAll(SETTINGS_COLLECTION_NAME, { where_constraints })
}

export const addSettings = async (settings: Settings): Promise<any> => {
  const currentUser = usersStore().user
  return dbHelper.addDataToCollection(SETTINGS_COLLECTION_NAME, {
    client_id: currentUser.client_id,
    site_id: currentUser.site_id,
    creation_date: new Date(),
    ...settings,
  })
}

export const getSettingsById = async (id: any): Promise<Settings[]> => {
  return await dbHelper.getDocFromCollection(SETTINGS_COLLECTION_NAME, id)
}

export const createSetting = async (settings: Settings) => {
  await dbHelper.addDataToCollection(SETTINGS_COLLECTION_NAME, settings)
}

export const updateSettings = async (settings: Settings): Promise<Settings> => {
  const { id, ...objectWithoutId } = settings
  await dbHelper.updateDataToCollection(SETTINGS_COLLECTION_NAME, id, {
    ...objectWithoutId,
    update_date: new Date(),
  })
}

export async function getUsers(): Promise<any[]> {
  return usersStore().getUsers
}

export const deleteSettings = async (docSettings: Settings): Promise<Settings> => {
  const { id } = docSettings
  await dbHelper.deleteData(SETTINGS_COLLECTION_NAME, id)
}
/** *************************** Document settings *****************************/

export const getDocumentSettings = async (settingsType: DocumentSettingsType): Promise<Settings[]> => {
  const currentUser = usersStore().user
  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'site_id',
    compare: '==',
    value: currentUser.site_id as any,
  },
  {
    field: 'type',
    compare: '==',
    value: settingsType as any,
  },
  {
    field: 'deleted_at',
    compare: '==',
    value: null,
  }]

  return await dbHelper.getAllDataFromCollectionWithAll(DOCUMENT_SETTINGS_COLLECTION_NAME, { where_constraints })
}

export const getAllDocumentSettings = async (): Promise<DocumentSetting[]> => {
  const currentUser = usersStore().user
  const where_constraints = [{
    field: 'client_id',
    compare: '==',
    value: currentUser.client_id as any,
  },
  {
    field: 'site_id',
    compare: '==',
    value: currentUser.site_id as any,
  },
  {
    field: 'deleted_at',
    compare: '==',
    value: null,
  }]

  return await dbHelper.getAllDataFromCollectionWithAll(DOCUMENT_SETTINGS_COLLECTION_NAME, { where_constraints })
}

export const addDocumentSettings = async (docSettings: Settings): Promise<any> => {
  const currentUser = usersStore().user
  return dbHelper.addDataToCollection(DOCUMENT_SETTINGS_COLLECTION_NAME, {
    client_id: currentUser.client_id,
    site_id: currentUser.site_id,
    creation_date: new Date(),
    ...docSettings,
  })
}

export const updateDocumentSettings = async (docSettings: Settings): Promise<Settings> => {
  const { id, ...objectWithoutId } = docSettings

  await dbHelper.updateDataToCollection(DOCUMENT_SETTINGS_COLLECTION_NAME, id, {
    ...objectWithoutId,
    update_date: new Date(),
  })
}

export const getDocumentSettingsById = async (id: any): Promise<Settings[]> => {
  return await dbHelper.getDocFromCollection(DOCUMENT_SETTINGS_COLLECTION_NAME, id)
}

export const deleteDocumentSettings = async (docSettings: Settings): Promise<Settings> => {
  const { id } = docSettings
  await dbHelper.deleteData(DOCUMENT_SETTINGS_COLLECTION_NAME, id)
}
