import { defineStore } from 'pinia'
import Grid from '~/grid/Grid'
import type { JReport } from '~/models/documents/jReport'
import type { JDocument } from '~/models/documents/jDocument'
import type { JSession } from '~/models/sessions/JSession'
import type Cell from '~/grid/Cell'

export const gridStore = (gridId = 'grid') =>
  defineStore(gridId, {
    // a function that returns a fresh state
    state: () => ({
      grid: null as Grid | null,
      gridApi: null as any,
      workplaceGrid: null as Grid | null,
      sessionReportGrid: null as Grid | null,
      isWorkplaceGrid: false,
      displayCheckbox: false as boolean,
      disabled: false as boolean,
      selectedColumnsIndex: [] as number[],
      selectedColName: '',
      historyModalCell: null as Cell | null,
      justificationModalCell: null as Cell | null,
      currentEditedCell: null as Cell | null,
      serialPortContext: {
        measureInstantData: '' as string,
        portInstance: null as unknown,
      },
    }),

    // optional getters
    getters: {
      getGrid(): any {
        if (this.isWorkplaceGrid) return this.workplaceGrid
        return this.sessionReportGrid
      },
      getIsWorkplaceGrid(): any {
        return this.isWorkplaceGrid
      },
      getGridApi(): any {
        return this.gridApi
      },
      isCheckboxDisplayed(): any {
        return this.displayCheckbox
      },
      isDisabled(): any {
        return this.disabled
      },
      getSelectedColumnsIndex(): any {
        return this.selectedColumnsIndex
      },
      getHistoryModalCell(): any {
        return this.historyModalCell
      },
      getJustificationModalCell(): any {
        return this.justificationModalCell
      },
      getCurrentEditedCell(): any {
        return this.currentEditedCell
      },
      getSerialPortContext(): any {
        return (attribute: string) => {
          if (!attribute) return this.serialPortContext
          return this.serialPortContext[attribute]
        }
      },
    },
    // optional actions
    actions: {
      loadGrid(
        report: JReport,
        document: JDocument,
        masterSession: any,
        session: JSession,
        isHistory: boolean,
        role: string,
        onExport = false,
      ): void {
        this.grid = new Grid(
          report,
          document,
          masterSession,
          session,
          isHistory,
          role,
          false,
          onExport,
        )
        if (this.isWorkplaceGrid) {
          this.workplaceGrid = new Grid(
            report,
            document,
            masterSession,
            session,
            isHistory,
            role,
            true,
          )
          this.grid = this.workplaceGrid
        } else {
          this.sessionReportGrid = new Grid(
            report,
            document,
            masterSession,
            session,
            isHistory,
            role,
            false,
            onExport,
          )
          this.grid = this.sessionReportGrid
        }
      },
      setGridApi(value: any): void {
        this.gridApi = value
      },
      setDisplayCheckbox(value: boolean): void {
        this.displayCheckbox = value
      },
      setSelectedColumnName(colName: string) {
        this.selectedColName = colName
      },
      setDisabled(value: boolean): void {
        this.disabled = value
      },
      setSelectedColumns(colIndex: number) {
        if (colIndex === -1) {
          this.selectedColumnsIndex = []
          return
        }
        const index = this.selectedColumnsIndex.indexOf(colIndex)
        if (index > -1) {
          this.selectedColumnsIndex.splice(index, 1)
          return
        }
        this.selectedColumnsIndex = [colIndex]
      },

      setWorkplaceGrid(isWorkplaceGrid: boolean) {
        this.isWorkplaceGrid = isWorkplaceGrid
      },
      setHistoryModalCell(cell: Cell | null): void {
        this.historyModalCell = cell
      },
      setJustificationModalCell(cell: Cell | null): void {
        this.justificationModalCell = cell
      },
      setCurrentEditedCell(value: Cell | null): void {
        this.currentEditedCell = value
      },
      setSerialPortContext(attribute: string, value: any): void {
        this.serialPortContext[attribute] = value
      },
    },
  })()
