alerts:
  alert_center: Centre d'incidents
  comment: Commentaire
  justification: Justification
  information_about_alerts: Plus d'information à propos de l'alerte...
  no_saved_answers: Aucune réponse n'a été pré-enregistrée
  to_signal: 'A signaler:'
  select_users_to_alert: Sélectionnez la/les personne(s) à alerter
  people_to_alert: 'Personnes ou groupes à alerter:'
  new_alert: 'Nouvelle alerte'
  no_justification_provided: Aucune justification n'a été fournie
  minimum_one_selected_people: Au moins une personne doit être assignée à l'alerte
  minimum_one_justification: Vous devez renseigner une justification
  raised_by_me: Levées par moi
  my_interventions: Mes interventions
  assigned_to: a assigné l'alerte à
  removed_from: a désassigné
  status_history: Historique des statuts de l'alerte
  get_more: Charger + { count } alerte | Charger + { count } alertes
  load_more: Plus d'alertes
  add_comment: Commentez sur l'alerte ou ajoutez des détails...
  search_placeholder: Rechercher le nom de la fiche
analysis:
  analysis: Analyse
authentication:
  wrong_authentication_method: Votre utilisateur n'a pas les permissions pour se connecter avec un mot de passe, contactez votre administrateur pour plus d'informations.
instructions:
  instructions_transfer: Passage de consignes
  modify_instructions: Modifier
  write_instructions: Écrivez vos instructions pour le prochain opérateur...
  created_successfully: Instruction(s) créée(s) avec succès
  create_error: Une erreur s'est produite
  create_error_msg: Une erreur s'est produite veuillez réessayer plus tard
  updated_successfully: Instruction(s) modifiée(s) avec succès
  update_error: Une erreur s'est produite
  update_error_msg: Une erreur s'est produite veuillez réessayer plus tard
  no_instructions_found: Aucune consigne n'a été trouvée
  new_instruction: Nouvelle consigne
  archive: Archiver
  save_instruction: Sauvegarder
  unarchive: Désarchiver
  export: Exporter
  selected: sélectionné | sélectionnés
application_fields:
  all_products: Tous les produits
  all_products_search: 'Tous les produits contenant: { entry }'
  all_operations: Toutes les opérations
  all_operations_search: 'Toutes les opérations contenant: { entry }'
  all_workplaces: Tous les postes de charges
  all_workplaces_search: 'Tous les workplaces contenant: { entry }'
  application_fields: Champs d'application
  charging-station: Poste de charge
  operations: Opérations
  product: Produit
  products: Produits
  reference: Références
  select_product: Sélectionner un produit...
  select_workplace: Sélectionner un poste de charge...
  select_operation: Sélectionner une opération...
  select_all_products: Sélectionner tous les produits
  select_all_workplaces: Sélectionner tous les postes de charges
  select_all_operations: Sélectionner toutes les opérations
  workplaces: Postes de charges
batch_record:
  add_comment: Ajouter un commentaire
  batch: OF
  batch_sumup: Voir le résumé de l'OF
  information: Informations de l'OF
  delete_document_from_batch_title: Supprimer une pièce jointe
  delete_document_from_batch_description: >-
    Êtes-vous sûr de supprimer cette pièce jointe ? Cette action est irréversible.
  documents:  Fiches de l'OF
  release_title: Libération de l'OF
  release_title_sidebar: Libération de lots
  modal_release_title: Êtes-vous sûr de vouloir libérer l'OF { name } ?
  modal_release_description: Vous êtes sur le point de libérer l'OF. Assurez-vous de la conformité totale de l'OF avant de valider. Merci de bien vouloir saisir vos identifiants pour confirmer.
  new_document: Ajouter une pièce jointe
  update_document: Modifier la fiche
  release: Libérer
  release_batch_error: Identifiants incorrects.
  released_batch: OF libéré le { date } par
  last_released_by_title: Dernière libération de l'OF
button:
  about: A propos
  back: Retour
  go: ALLER
  home: Accueil
  toggle_dark: Basculer en mode sombre
  toggle_langs: Changer de langue
dashboard:
  last_30_days: Les 30 derniers jours.
  select: Sélectionnez un tableau de bord
  select_dashboard_placeholder: Sélectionnez
  empty_dashboard: Aucun  tableau de bord n'est disponible.
dates:
  month: il y a un mois | il y a {amount} mois
  day: il y a un jour | il y a {amount} jours
  hour: il y a une heure | il y a {amount} heures
  minute: il y a une minute | il y a {amount} minutes
  second: ' il y a une seconde | il y a {amount} secondes'
days:
  daily: Tous les jours
  friday: Vendredi
  monday: Lundi
  saturday: Samedi
  sunday: Dimanche
  thursday: Jeudi
  tuesday: Mardi
  wednesday: Mercredi
monthes:
  january: Janvier
  february: Février
  march: Mars
  april: Avril
  may: Mai
  june: Juin
  july: Juillet
  august: Août
  september: Septembre
  october: Octobre
  november: Novembre
  december: Décembre
display:
  all_columns: Toutes les colonnes
  editable_columns: Colonnes saisissables
  all_lines: Toutes les lignes
  editable_lines: Lignes saisissables
document:
  type: Type
  type_manual: Manuel
  type_readonly: Lecture seule
  type_auto: Automatique (NIR)
  device: Machine
  notify_operator: Notifier l'opérateur
  simulate: Simuler
  production_order: Ordre de fabrication
  display: Affichage
  display_parameters: Paramètres d'affichage
  columns: Colonnes
  capture: Capturer
  connect_to_balance: Connecter l'appareil
  lines: Lignes
  column_number: Numéro de colonne
  status: Statut
  create: Nouvelle fiche
  valid: conforme
  uncertain: incertain
  non_valid: non conforme
  get_more_revisions: Charger + { count } révision | Charger + { count } révisions
  load_more_revisions: Plus de révisions
  add_new_block: Ajouter un nouveau bloc
  application_field: Champs d'application
  ask_admin: Veuillez contacter l'administrateur de l'application
  category: Catégorie
  document_to_model_success: Fiche convertie en modèle avec succès
  unsaved_changes_description: Toute modification sera perdue si vous n'enregistrez pas cette fiche
  unsaved_changes_title: Cette fiche n'est pas sauvegardée
  steps_linked_to_template: Des blocs sont déjà liés à un modèle
  unlink_steps: Dissociez d'abord les blocs déjà importés d'un modèle.
  exit_without_saving: Quitter sans sauvegarder
  version: Version
  version_reason_add: Ajouter une note sur la nouvelle version de la fiche
  version_reason_error: Vous devez justifier la nouvelle version de la fiche.
  note: Note
  created_by: Créé par
  use_as_template: Utiliser comme modèle
  created_on: Créé le
  cycle_of_document: Cycle de fiches
  delete: Supprimer la fiche
  create_new_version: Créer une nouvelle version ?
  publish_document: Publier la fiche
  create_new_document: Confirmer la Sauvegarde ?
  create_new_version_message: Vous êtes sur le point de changer de version en sauvegardant cette fiche
  create_new_document_message: Vous êtes sur le point de créer la première version de cette fiche.
  archive: Archiver la fiche
  delete_confirmation_message: >-
    Êtes-vous sûr de vouloir supprimer la fiche ? Cette action est
    irréversible.
  archive_confirmation_message: >-
    Êtes-vous sûr de vouloir archiver la fiche ?
  document_creation_message: La fiche a bien été créé
  document_delete_message: La fiche a été supprimé avec succès
  document_archive_message: La fiche a été archivé avec succès
  document_archive_title: Fiche archivée
  document_update_error_message: Une erreur est survenue lors de l'enregistrement de la fiche
  document_update_message: La fiche a été enregistré avec succès
  expiration_date: Date d'expiration
  file: Lien
  file_extension_error: L'extension n'est pas autorisée
  finish: Terminer l'édition de la fiche
  finish_confirmation_message: Êtes vous sûr d'avoir terminé votre saisie sur cette fiche ?
  format: Format
  frequency: Fréquence
  input_frequency: Fréquence des saisies
  input_frequency_activate: Activer les saisies fréquentielles
  get_more: Charger + { count } fiche | Charger + { count } fiches
  load_more: Plus de fiches
  last_updated_on: Dernière modification le
  lifecycle: Cycle de la fiche
  measures: Mesure
  name: Nom
  new_document: Nouvelle fiche
  no_files: Il n'y a pas de fichiers disponibles
  no_workplace_documents: Aucune fiche disponible sur ce poste de charge
  not_found: Aucune fiche trouvée
  number_of_columns: Nombre de colonnes
  order: Ordre
  cant_finish_document: Il reste des saisies à effectuer
  cant_finish_document_description: Vous ne pouvez pas terminer cette fiche sans avoir saisi les cellules figurant sur les lignes obligatoires (non-facultatives). Vous pouvez néanmoins renseigner "N/A" dans les cellules concernées ou les signaler.
  cant_finish_document_description_mandatory_lines: 'Les lignes suivantes sont obligatoires :'
  cells_missing: '{number} cellule(s) incomplète(s)'
  schedule:
    minute: minute | minutes
    hour: heure | heures
    day: jour | jours
    end: Fin
    month: Mois
    no_end: Pas de date de fin
    no_schedule: Pas de répétition
    no_start: Pas de date de début
    publication_date: Date de publication
    as_soon_as_the_publication: Dès la publication
    repeat_each: Répéter tous les
    repeat_each_feminine: Répéter toutes les
    repeat: Répétitions
    recurrence: Récurrence
    specific_recurrence: Récurrence spécifique
    set_hour: Définir l'heure
    set_hour_error: L'heure de fin doit être supérieure à celle de début.
    recurrence_from: De
    recurrence_to: à
    document_is_accessible: La fiche sera accessible
    start: Début
    available_from: Disponible dès
    availablity: Disponibilité
    start_at: Début le
    end_at: Fin le
    week: Semaine | Semaines
    year: Ans | Année | Années
    # year_feminine:
    every_m: Tous
    every_f: Toute | Toutes
    every_with_article: Tous les
    every_day: Tous les {nb} jours
    from_date: à partir du {date}
    until_date: et jusqu'au {date}
    every_week: Toutes les {nb} semaines
    every_week_article: Toutes les
    every_week_days: Tous les {days}
    every_month: Tous les {nb} mois
    every_year: Tous les {nb} ans
    the_m: le | les
    the_f: la | les
    permanently_available: Disponible en permanence
    as_soon_as: Dès le
    no_starting_date: Pas de date de début
    not_recognized: Récurrence pas reconnue
  enter: Saisir
  scheduling: Planification
  scheduling_subtitle: Planifiez l'affichage de la fiche dans l'espace opération
  search: Rechercher une fiche
  select_application_fields: Sélectionner des champs d'application
  start_date: Date de démarrage
  start_to_create_a_new_document: Commencez à créer une nouvelle fiche
  state: Etat
  status_archived: Archivé
  status_in_review: En revue
  status_deleted: Supprimé
  status_draft: Brouillon
  status_finished: Terminé
  status_in_progress: En cours
  status_production_validée: Production validée
  status_revu_quali_en_cours: Revu quali. en cours
  status_lot_libéré: Lot libéré
  status_new: Nouveau
  status_published: Publié
  status_select: Sélectionner
  status_active: Actif
  status_inactive: Inactif
  status_qualified: Qualifié
  status_released: Libéré
  step_check: OK/NOK
  step_checkbox: Case à cocher
  step_link: Lien
  step_measure: Mesure
  step_options_list: Liste de choix
  step_text: Texte
  step_time: Heure
  step_file: Fichier
  step_number: Nombre
  step_photo: Photo
  step_photo_take_picture: Prendre une photo
  step_photo_take_picture_description: Utilisez votre caméra pour prendre une photo. Cliquez sur "Prendre" pour sauvegarder la photo et prévisualisez-la pendant 2 secondes avant que la modal se ferme automatiquement.
  step_photo_take_picture_again: Reprendre la photo
  step_photo_update_picture: Modifier la photo
  step_photo_update_picture_description: Vous pouvez mettre à jour votre photo existante en utilisant la caméra. Cliquez sur "Prendre une photo" pour sauvegarder la nouvelle photo, elle sera prévisualisée pendant 2 secondes et la pop-up se fermera automatiquement, remplaçant ainsi l'ancienne photo.
  step_photo_preview_picture: Apercu de la photo
  step_photo_no_devices_found: Aucun appareil n'a été trouvé. Veuillez connecter une caméra USB.
  step_photo_no_camera_access: L'accès à la caméra est désactivé. Veuillez autoriser l'accès dans les paramètres de votre navigateur.
  multiple_selections: Choix multiple
  title: Mes fiches
  to_fill: A saisir
  trigger: Déclenchement
  trigger_element: Élément déclencheur
  starting_at: Démarre à
  update_research: Modifier ma recherche
  updated_by: Modifié par
  updated_at: Modifié le
  updated_on: Modifié le
  you_have_completed: Vous avez complété
  workflow: Cycle de vie
  confirm_archive_in_document:
    title: Confirmer l'archivage
    description: Vous êtes sur le point d'archiver cette fiche. Une fois archivé, cette fiche ne sera plus disponible pour une utilisation dans le OF. Êtes-vous sûr de vouloir continuer ?
  confirm_archive_many_documents:
    title: Confirmer l'archivage
    description: Vous êtes sur le point d'archiver une fiche. Cette fiche ne pourra plus être utilisé dans les OF. | Vous êtes sur le point d'archiver {count} fiches. Ces fiches ne pourront plus être utilisé dans les OF.
export:
  attachments_of_document: Les_pièces_jointes_de_la_fiche
  change_target: changement de cible
  columns_count: Nombre de colonnes
  download_is_starting: Votre téléchargement va commencer dans un instant...
  download_error: Une erreur s'est produite pendant la connexion au serveur.
  document_export_error: Une erreur s'est produite pendant la préparation de votre fiche.
  export_document_as_pdf: Télécharger la fiche en format pdf
  historic_value: Saisie antérieur
  justification: Justification
  report_title: Titre du rapport
  export_type: Type d'export
  status: Statut
  comment: Commentaire
  workplaces: Postes de charge
  tags: Tags
  type: Type
  modified_by: Modifié par
  modified_at: Modifié le
  created_by: Créé par
  created_at: Créé le
  name: Nom
  step_type: Type
  description: Description
  tool: Moyen de mesure
  goal: Goal
  min: Min
  max: Max
  unit: Unité
  at: à
  operator: Operateur
  update_date: Modifié le
  value: Valeur
  status_history: Historique des status du rapport
  master_session_status_history: Historique des status de l'OF
format:
  fixed: Fixe
  formula: Formule
  quantity: Quantité
  unique: Unique
  unlimited: Illimité
filters:
  machines: Machines
  activities: Activité
  assignee: Personne assignée
  assignation: Assignation
  blocker: Bloquant
  blockers: Bloquants
  raised_by: Lancé par
  site: Site
  parent: Parent
  alternative_workplaces: Postes de charge alternatifs
  add: Filtrer les rapports
  choose_filter: 'Choose a filter'
  categories: Catégorie de fiche
  go_back: Retour aux choix de filtres
  display: Affichage
  display_columns: Colonnes affichées...
  at_least_one_display_column: Au moins une option doit être choisie
  group_by: Grouper
  date: Date
  documents: Fiches
  models: Templates
  products: Produit
  production_orders: Ordre de fabrication
  role: Rôle utilisateur
  title: Filtres
  teams: Équipe
  search: Rechercher un filtre...
  status: Statut
  today: Aujourd'hui
  yesterday: Hier
  no_grouping: Aucun
  last_24_hours: 24 dernières heures
  last_3_days: 3 derniers jours
  last_7_days: 7 derniers jours
  last_14_days: 14 derniers jours
  last_month: Le mois dernier
  users: Opérateur
  workplaces: Poste de charge
  filter_by: Filtrer par
  filter: Filtrer
  no_product: Aucun produit
  no_workplace: Aucun poste de charge
  select_a_date: Sélectionner une date
  templates: Modèles
  remove_all: Tout effacer
  operations: Opération
  operation: Opération
  product: Produit
  workplace: Poste de charge
  work_order: Ordre de fabrication
global:
  performances: Performances
  bread_crumb:
    fiches: Fiches
    modeles: templates
    simulation: simulation
  per_page: par page
  send: Envoyer
  switch_user: Changer d'utilisateur
  switch_user_title: Changer l'utilisateur
  switch_user_description: Vous êtes sur le point de changer de compte utilisateur. Après confirmation, vous serez redirigé vers la page de connexion et vous devrez saisir les identifiants d'un autre utilisateur pour revenir à cette page.
  selected_documents: '{n} fiche sélectionnée | {n} fiches sélectionnées'
  selected_documents_from_to: Fiches {from}-{to} sur un total de {total} fiches
  selected_products_from_to: Produits {from}-{to} sur un total de {total} produits
  selected_workplaces_from_to: Postes de charges {from}-{to} sur un total de {total} postes de charge
  selected_lookups_from_to: Données de type {from}-{to} sur un total de {total} données
  element_not_found: Aucun élément n'a été trouvé
  parent: Parent
  if: Si
  id: Id
  or: Ou
  then: Alors
  days: jours
  group: groupe | groupes
  password_expired: Votre mot de passe a expiré, veuillez le mettre à jour
  roles: Rôles
  change_language_to_apply: Il est fortement recommandé de rafraîchir la page pour appliquer les changements
  unknown: Inconnu
  audit_logs: Journal des connexions # TO CHANGE AFTER
  all_the_of: Tous les of
  people: '{amount} personnes'
  simulation: Simulation
  choice: choix
  element: Aucun élément | 1 élément | {n} éléments
  current_language: fr
  additional_informations: Informations supplémentaires
  no_value: Aucune valeur
  account_and_access: Utilisateurs
  permissions: Rôles et permissions
  integration_parameters: Intégration
  import_export_parameters: Importer/Exporter
  select_type: Sélectionner un type
  please_select_type: Veuillez sélectionner un type
  confirm_import: Confirmer l'import des données
  confirm_import_desc: Importer ce fichier va actualiser les données clients précédemment importées dans JUNO
  workflow_parameters: Cycle de vie
  status_management: Gestion des statuts
  site_management: Gestion du site
  add: Ajouter
  add_user: Ajouter un utilisateur
  network_error: Pas de connexion internet
  add_a_attachment_file: Ajouter une pièce jointe
  alert: Alerte | Alertes
  alerts: Demandes de support
  instructions: Consignes
  alerts_success: Alerte créé avec succès !
  all: Tout
  analyses: Analyses
  application_fields: Champs d'application
  archive: Archiver
  are_you_sure_you_want_to_logout: Êtes-vous sûr de vouloir vous déconnecter
  dashboard: Tableau de bord
  disconnected: Vous avez été déconnecté(e)
  disconnected_for_inactivity: En raison d'une inactivité prolongée, vous êtes déconnecté(e).
  understood: J'ai compris
  are_you_sure_you_want_to_delete_user: Êtes-vous sûr de vouloir supprimer cet utilisteur
  at: à
  and: et
  attachment: Pièce jointe
  attachments: Pièces jointes
  attention_any_unsaved_document_will_be_lost: Attention toute fiche non enregistré sera perdu !
  back_home: Retourner à la page d'accueil
  cancel: Annuler
  click_button_reload: Cliquer sur "Recharger" pour y accéder.
  client: Client
  clients: Clients
  close: Fermer
  complete: Terminer
  content: Contenu
  copy_of: Copie de
  create: Créer
  created_by: Créé par
  created_on: créé le
  created_at: Créé le
  define_as_a_template: Définir comme modèle
  description: Description
  display: Afficher
  document: fiche | fiche | fiches
  integrations: Integrations
  document_parameters: Conception
  operator_interface: Interface opérateur
  steps_parameters: Paramètres des blocs
  notification_parameters: Notifications
  ftp_parameters: FTP
  filter: Filtre | Filtres
  documents: Fiches
  download: Télécharger
  download_as_pdf: Télécharger en pdf
  duplicate: Dupliquer
  edit: Editer
  email: E-mail
  empty_list: Aucun enregistrement trouvé
  enriched_export: Export enrichi
  enriched: Enrichi
  error: Erreur
  export: Exporter
  loading: Chargement...
  first_name: Prénom
  files: Fiches du poste
  finished: Terminé
  forbidden: Accès interdit à cette page
  forbidden_message: Contactez votre administrateur pour vérifier l'accès
  from: à partir de
  hello: Bonjour
  history: Historique
  actions: Actions
  import: Importer
  inProgress: en cours
  in_progress: En cours
  resolved: Résolu
  justification: Justification
  last_modification: Dernière modification
  last_name: Nom de famille
  link: Lien
  list: Liste
  login: Connexion
  logout: Se déconnecter
  mandatory: Obligatoire
  mark_as_read: Tout marquer comme lu
  media_library: Médiathèque
  monitoring: Supervision
  month: Mois
  my_dashboards: Mes Tableaux de bord
  name: Nom
  name_document: Nom de la fiche
  need_reset_password: Avez-vous besoin de réinitialiser votre mot de passe ?
  force_reset_password: Vous vous connectez pour la première fois, veuillez réinitialiser votre mot de passe
  new: Nouveau
  new_version: Une nouvelle version de Juno est disponible
  no: Non
  for: Pour
  next_check_at: Contrôle à effectuer sur la fiche {reportName}, sur l'étape {step}
  number_of_steps: >-
    {number} étape | {number} étapes
  ok: OK
  operation: Opération
  optional: Facultatif
  out_of: sur
  parameters: Paramètres
  password: Mot de passe
  row: Ligne | Lignes
  new_password: Nouveau mot de passe
  current_password: Mot de passe actuel
  confirm_password: Confirmer le mot de passe
  confirm_password_validation_label: Les deux mots de passe
  confirmation_password: Le mot de passe de confirmation
  change_password: Changer le mot de passe
  both_passwords_dont_match: Les deux mots de passe ne correspondent pas
  password_has_changed: Votre mot de passe à été modifié
  password_update_error_message: Une erreur s'est produite lors de la modification du mot de passe
  percentage: pourcentage de tolérance
  picture: Image
  preview: Aperçu
  product: Produit
  publish: Publier
  range: intervalle
  reason: Raison
  reload: Recharger
  refresh: Actualiser
  remove_attachment: Supprimer le fichier joint
  report: Rapport | Rapports
  reports: Rapports
  reset_email_sent: >-
    Un e-mail de reinitilisation de votre mot de passe a été envoyé. Veuillez
    cliquer sur le lien lorsque vous l'obtenez
  restore: Récupérer
  revision: Révisions
  role: Rôle
  save: Enregistrer
  search_means_of_measurement: Rechercher...
  search_unit: Rechercher une unité...
  update: Mettre à jour
  delete: Supprimer
  finish_entry: Finir ma saisie
  finish_document: Terminer la fiche
  save_preferences: Enregistrer les préférences
  search: Rechercher
  search_assignee: Assigner à
  seeDescription: Voir description
  seeAll: Voir tout
  select: Sélectionner
  select_tab: Sélectionnez un onglet
  send_reset_email: Envoyer un e-mail de récupération
  settings: Réglages
  sign_out: Déconnexion
  simple_export: Export simple
  sites: Sites
  start: Commencer
  startTask: Commencer une tâche
  view: Voir
  success: Succès
  summary: Synthèse
  switch_to_operator: Espace opération
  switch_to_supervisor: Espace conception
  tags: Étiquette
  time: Time
  title: Titre
  to_consult: A consulter
  other: Autre
  today: Aujourd'hui
  total_report: Rapports totaux
  user: Utilisateur
  users: Utilisateurs
  user_session_management: Gestion de la session utilisateur
  validate: Valider
  workplace: Poste de travail
  yes: Oui
  you_are_offline: Vous êtes actuellement hors ligne. Merci de vous reconnecter.
  your_profile: Votre profil
  template: Modèle
  templates: Modèles
  template_messages_parameters: Modèles de messages
  confirm: Confirmer
  library: Bibliothèque
  unlink_step: Voulez-vous vraiment dissocier ce bloc de son modèle ?
  confirm_modification: Confirmer les modifications ?
  unlink_step_message: 'Éditer ce bloc implique qu’il ne pourra plus être mis-à jour si vous rompez le lien avec le modèle suivant :'
  warning: Attention
  workflows: Flux de fiche
  allTeams : Toutes les équipes
  selectTeam : Sélectionnez une équipe
  beforeOrAfter: Avant ou aprés
  minutes: minutes
  total_parts: Total de pièces
  total_of_controles: Total de contrôles
  nb_of_boxes: Nombre de boîtes
  label: Label
  all_the_documents: Toutes les fiches
  all_the_templates: Tous les modèles
  modeles: templates
bread_crumb:
  fiches: Fiches
  templates: Modèles
  views: Toutes les vues
  reports: Tous les rapports
  production_orders: Tous les ordres de fabrication
  new_view: Nouvelle vue
  simulation: Simuler un ordre de fabrication
global_fields:
  created_by: created by
  created_on: created on
history:
  history: Historique
  created: a signalé l'alerte
  assigned: a assigné l'alerte à
  commented: a commenté
  updated: a changé le statut de l'alerte
  and_more: et {amount} de plus...
intro:
  aka: Aussi connu sous le nom
  desc: Modèle de démarrage de Vite Opinionated
  dynamic-route: Démo d'itinéraire dynamique
  hi: "Salut, {nom}\_!"
  whats-your-name: Quel est ton nom?
insights:
  valid_control_points: Points de contrôles valides
  valid_controls: Contrôles valides
  valid_blocks: Blocs valides
jobs:
  choose_workplace: Choisissez votre poste de charge
  no-result: Pas de résultat pour cette recherche
  select-of: Veuillez sélectionner un numéro d'OF
  select_product: Veuillez sélectionner un produit
  choose_workplace_desc: >-
    Sélectionnez votre poste de charge afin de faire apparaître les ordres de
    fabrication
  current_jobs: Tableau de bord
  last_30_days: OF des 30 derniers jours
  last_10_days: OF des 10 derniers jours
  not_found: Aucun ordre de fabrication ouvert
  number: Numéro d'OF
  operation: Numéro d'opération
  start_new: Démarrer un ordre de fabrication
  start_new_job_desc_no_jobs: Ouvrez un ordre de fabrication en cliquant sur le bouton en haut à droite de l'écran
  status_finished: Terminé
  status_finished_message: L'ordre de fabrication a été finalisé
  status_in_progress: En cours
  status_new: Nouveau
  no_product_order_selected: Aucun ordre de fabrication trouvé! cliquez pour choisir un produit
  create_production_order: Créer cet ordre de fabrication
  production_order_doesnt_exist: Cet ordre de fabrication n'existe pas !
languages:
  fr: Français
  french: Français
  en: Anglais
  english: Anglais
  de: Allemand
  german: Allemand
  es: Espagnol
  spanish: Espagnol
  it: Italien
  italian: Italien
media_library:
  add: Importer un fichier
  archive_confirmation_message: Êtes-vous sûr de vouloir archiver ce fichier ?
  delete_confirmation_message: vous etes sur que vous voulez supprimer ce fichier
  delete: Archiver un fichier
  media_parameters: Médiathèque
  no_data: Aucun fichier n'a été importer, cliquer sur le button importer pour ajouter vos fichiers
  no_search_result: Aucun fichier ne correspond a votre recherche
  no_file_yet: Ajouter vos fichiers ici pour les importer dans JUNO
  search_file: Rechercher sur votre ordinateur
  staged_file: fichier sélectionné
  staged_files: '1 fichier a été séléctionné | %{count} fichiers séléctionnés '
  show: Activé
  tab_archived: Archives
  tab_files: Fichiers disponibles
  file_update_error_message: Une erreur est survenue lors de la connexion au serveur, veuillez réessayer.
  update_file: Remplacer le fichier
  upload: Importer
  upload_files: Importer des fichiers
  uploaded_files: '1 fichier a été importé avec succès | %{count} fichiers ont été importé avec succès '
  failed_upload: Certains fichiers n'ont pas été télécharger à cause d'une erreur
not-found: Non trouvé
notifications:
  activate_feature: Activer ou désactiver
  alert_type: Notifications d'alerte
  reminder_type: Notifications de rappel
  view: Voir la notification
  go_to_task: Accéder à la tâche
  controls_needed: contrôle à effectuer | contrôles à effectuer
  undefined_document: Fiche sans nom
  machine_stopped: Arrêt de Machine à %{time}. Merci de qualifier immédiatement la raison de cet arrêt.
  qualify_stop: Qualifier l'arrêt
pages:
  alerts: Toutes les demandes de support
  alerts_list: Toutes les demandes de support
  analytics: Analyses
  document: Fiches
  form: Fiches
  history: Historique
  media_library: Médiathèque
  monitoring: Supervision
  reports: Tous les rapports
  settings: Paramètres
  template: Modèles
  library: Bibliothèque
  views: Vues
simulation:
  new_simulation: Nouvelle simulation
  empty_simulation: Vous n'avez pas de simulations pour le moment. Vous pouvez commencer à en créer.
profil:
  my-profile: Mon profil
  description: Gérez toutes les informations personnelles liées à votre profil JUNO
  email: Adresse email
  language: Langue
  security: Sécurité
  trigram: Identifiant usine
  not_specified: Non spécifié
repetition:
  additional_filter_nature: Nature
  select: Séléctionner une répétition
  add: Créer une répétition
  event: Événement
  formula: Formule
  frequency: Fréquence
  no_repetition_available: Il n'y a pas de répétition disponible
  repetitions: Répétitions
  repetition_exists_message: Cette répétition existe déjà
  sampling: Échantillon
  notification: Notification
  times: Fois
  load_more: Charger plus
  search: Rechercher
  reset: Réinitialiser
  save: Enregistrer
  create_sampling: Créer un échantillon
  create_formula: Créer une formule
  select_formula: Selectionnez une formule
  formula_type: Indiquer type de formule
  repeat_every: Se répète
  created_successfully: La répétition a été créée avec succès
  created_successfully_message: vous pouvez commencer à utiliser la nouvelle répétition dans vos fiches
  creation_error: Une erreur inattendue s'est produite
  creation_error_message: Une erreur inattendue s'est produite lors de la création de votre répétition.
report:
  target: Cible
  dynamic_target: Cible dynamic
  controls: Contrôles
  last_update: Dernière sauvegarde le
  execution_date: Date d'exécution
  all_day: Toute la journée
  category: Catégorie
  checkbox: À Faire
  control: Contrôle
  valid_controls: Contrôles valides
  custom_valid_controls: '{header} valides'
  document: Fiche
  dynamic: Dynamique
  static: Statique
  date: Date
  done: Fait
  status_history: Historique des statuts
  filter_last_day: Dernier jour
  filter_last_3_days: Derniers 3 jours
  filter_last_7_days: Dernier 7 jours
  filter_last_month: Dernier mois
  filter_valid: Valide
  filter_invalid: Non valide
  is_valid: État
  no_rows_to_show: Problème d'affichage des données
  job: Ordre de fabrication
  job_abbreviation: OF
  measure: Mesure
  no_steps: Ce fichier est vide
  operator: Opérateur
  product: Produit
  raise_event: Signaler un événement
  report_update_message: La fiche a été enregistré avec succès
  select_option: Sélectionner un/des éléments
  see_alert: Voir l'alerte
  status: Statut
  status_finished: Terminé
  status_in_progress: En cours
  status_new: Nouveau
  step_answer: Réponse
  step_answer_state: État
  step_name: Nom
  step_number: Etape
  view_report: Voir le rapport
  text: Texte
  update_date: Date de mise à jour
  work_order: OF
  workplace: Poste de charge
  cell_history: Historique des saisies
  cell_history_desc: Ce champ a été modifié par un ou plusieurs utilisateurs
  new_data: Nouvelle donnée
  enter_value: Saisir valeur...
  modify_data: Modification d’un champ précédemment saisi
  restore_data_title: Voulez-vous vraiment rétablir ces données ?
  restore_data: Rétablir la saisie
  restore_data_desc: Ces données ont été saisie antérieurement avant d'être remplacées par de nouvelles saisies plus récentes.
  justify_ko_title: Justification de non-conformité
  justify_ko_desc: Veuillez justifier la valeur de la cellule suivante pour continuer la saisie sur cette fiche
  modify_data_error: Tous les champs ne sont pas remplis correctement
  modify_data_success: La nouvelle donnée à bien été enregistré
  modify_data_desc: Les données de cette cellule ont été saisies par un autre utilisateur. Ces nouvelles données seront actualisées sur les grilles de chaque intervenant sur la fiche.
  justification: Justification
  precise_reasons: Préciser les raisons de cette modification...
  downloading: Téléchargement en cours...
  downloading_message:  Veuillez patienter, nous préparons votre fiche pour le téléchargement ! | Veuillez patienter, nous préparons vos fiches pour le téléchargement !
  download_finished:  Votre fiche est prête | Vos fiches sont prêtes
  download_finished_message: Ouvrez les dès maintenant
  download_failed: Erreur
  download_failed_message: Une erreur est survenue pendant la préparation de votre fiche. | Une erreur est survenue pendant la préparation du vos fiches.
  download_limit_reached: Téléchargement limité a 45 rapports
  limit_reached: Vous avez atteint votre limite, veuillez affiner votre recherche.
session:
  automatic_reports: Rapports automatiques
  ongoing_session: Session commencée
  ongoing_session_message: Vous êtes sur le point de commencer une session déja démarrée
  search_po: Rechercher
  refine_search: Affinez votre recherche pour faire apparaître les OFs associés
  workorder_doesnt_exist: Cet ordre de fabrication n'existe pas.
  create_workorder: Créer un nouvel ordre de fabrication
  select_product: Sélectionner le produit
  create_operation: Créer une nouvelle opération
  product_requiered: Sélectionnez au moins un produit pour continuer
  of_name_requiered: Veuillez choisir un nom pour votre ordre de fabrication
  operation_requiered: Veuillez créer au moins une nouvelle opération
  all_documents_not_complete: Vos fiches ne sont pas toutes terminées
  all_documents_not_complete_desc: Vous ne pouvez pas terminer l'opération tant que toutes les fiches obligatoires ne sont pas terminées
  launch_on: Lancer sur
  update: MàJ
settings:
  no_user_found: Aucun utilisateur n'a été trouvé
  alternative_workplaces: Postes de charge alternatifs
  users: Utilisateurs
  new_user: Nouveau utilisateur
  exigence: Exigence
  nature: Nature
  mandatory_fields: Assurez-vous de remplir tous les champs obligatoires
  mold: Moule
  plan: Plan
  members: Membres
  header_list: Séquence
  lookup_exists: Données déjà existantes, veuillez modifier l'un des champ.
  name: Nom
  password_expiration: Expiration des mots de passe
  reset_automatically: Réinitialisation automatique
  number_of_days_of_validity: Nombre de jours de validité du mot de passe actuel
  search_user_placeholder: Rechercher et ajouter des nouveaux membres
  create_group: Créer un groupe
  update_group: Modifier un groupe
  profile: Profil
  users_and_access: Comptes et accès
  mandatory_column: Ce champs est obligatoire
  value_exists: Ce nom est déja utilisé
  workflow: Cycle de vie
  groups: groupes
  audit_logs:
    name: Compte associé
    action_hour: Heure d'activité
    action: Activité
    get_more: Charger + { count } entrée | Charger + { count } entrées
    load_more: Plus d'entrées
    login: Connexion
    logout: Déconnexion
    reactivate: Activation
    deactivate: Désactivation
  status_name: Nom
  justify_ko: Justification NC
  status_icon: Icône
  status_color: Couleur
  status_saved: Le statut a été sauvegardé
  create_status: Créer un statut
  new_status: Statut sans nom
  save_status: Sauvegarder
  conception_rights: Droits de conception
  decimal_values: Valeurs décimales
  number_of_decimals: Nombre de chiffres significatifs
  same_as_tolerance: Identique à la tolérance
  alerts: Alertes
  input_modification: Modification de saisie
  message: Contenu
  additional_data: Données complémentaires
  operator_session: Session
  input_parameters: Saisies
  lookup_keys_error: La configuration du fichier ne correspond pas aux clés de ce type. Veuillez contacter votre responsable des opérations.
  parameter_name: Nom
  parameter_value: Valeur
  maximum_inactivity_time: Temps d'inactivité avant déconnexion
  enabled: Activé
  disabled: Désactivé
  cancel: Annuler
  color: Couleur
  team_name: Nom d'équipe
  update_product: Mettre à jour un produit
  create_product: Créer un nouveau produit
  delete_product_title: Archiver un produit
  delete_product_description: Êtes-vous sûr de vouloir archiver ce produit ?
  update_workplace: Mettre à jour un poste de charge
  create_workplace: Créer un nouveau poste de charge
  delete_workplace_title: Archiver un poste de charge
  delete_workplace_description: Êtes-vous sûr de vouloir archiver ce poste de charge ?
  update_lookup: Modifier une cellule
  create_lookup: Créer une nouvelle donnée
  delete_lookup_title: Archiver la cellule
  delete_lookup_description: Êtes-vous sûr de vouloir archiver cette donnée ?
  get_more_teams: Charger + { count } équipe | Charger + { count } équipes
  load_more_teams: Plus d'équipes
  formula: Formule
  decimal_number: Nombre de décimale
  precise_decimal_number: Préciser le nombre de décimale
  reasons: Justification de modification
  add_option: Veuillez saisir votre option et appuyer sur entrée
  create_setting_success_message: Le paramètre a bien été créé avec succès
  settings_error: Un problème a été rencontré pendant la connexion au serveur
  create_ftp_success_message: Le serveur FTP a bien été enregistrer avec succès
  default: Défaut
  document_status: Fiche statut
  report_status: Report status
  delete: Supprimer le paramètre
  delete_confirmation_message: >-
    Êtes-vous sûr de vouloir supprimer le paramètre ? Cette action est
    irréversible.
  delete_option: Supprimer l'option
  delete_option_confirmation_message: >-
    Êtes-vous sûr de vouloir supprimer cette option ? Cette action est
    irréversible.
  delete_setting_success_message: Le paramètre a bien été supprimé avec succès
  document_category: Catégorie de fiche
  get_more_document_category: Charger + { count } catégorie | Charger + { count } catégories
  load_more_document_category: Plus de catégories
  ending_time: Heure de fin
  frequency: Fréquence
  grid_header: En-tête
  get_more_grid_header: Charger + { count } en-tête | Charger + { count } en-têtes
  load_more_grid_header: Plus d'en-têtes
  is_selected: Sélectionné
  list_options: Liste de sélection
  get_more_list_options: Charger + { count } list de sélection | Charger + { count } Listes de sélection
  load_more_list_options: Plus de listes de sélection
  list_type: Titre de la liste
  list_value: Valeur de l'option
  lookup_tables: Table de mapping
  means_of_measure: Moyen de mesure
  get_more_means_of_measure: Charger + { count } moyen de mesure | Charger + { count } moyens de mesure
  load_more_means_of_measure: Plus de moyens de mesure
  media_library: Activé
  measure_unit: Unité de mesure
  order: Ordre
  products:
    export: Exporter la liste des produits en format csv
    import: Importer un fichier csv
  workplaces:
    export: Exporter la liste des postes de charges en format csv
    import: Importer un fichier csv
  blocker: Bloquant
  roles_access: Accessible à
  mandatory_finish: Saisie intégrale
  organization: Organisation
  product: Produit
  create_role: Ajouter un rôle
  roles: Rôles & permissions
  roles_new: Nouveau rôle
  roles_create: Créer
  roles_edit_name: Editer le nom
  roles_delete_role: Supprimer
  roles_read: Consulter
  roles_modify: Modifier
  roles_change-status: Modif. statut
  roles_delete: Archiver
  roles_ressource: Ressource
  roles_cannot_delete_title: Impossible de supprimer le rôle
  roles_cannot_delete_description: '{amount} utilisateur(s) possèdent ce rôle. Merci de leur attribuer un rôle différent ou de le supprimer au préalable'
  roles_confirm_delete_title: Confirmer la suppression ?
  roles_confirm_delete_description: En confirmant, ce rôle va être définitivement supprimé. Cette action est irréversible.
  repetition: Répétition
  sampling: Echantillonnage
  step_tag: Étiquette
  get_more_step_tag: Charger + { count } étiquette | Charger + { count } étiquettes
  load_more_step_tag: Plus d'étiquettes
  get_more_messages: Charger + { count } message | Charger + { count } messages
  load_more_messages: Plus de messages
  get_more_workflow_models: Charger + { count } cycle de vie | Charger + { count } cycles de vie
  load_more_workflow_models: Plus de modèles
  repetition_type: Type de répétition
  starting_time: Heure de début
  team: Equipe
  theTeam: L'equipe
  tolerance: Tolérance
  enable_tolerance: Activer la tolérance dans les mesures
  value_tolerance: Valeur de la tolérance
  type: Type
  unit: Unité
  units_of_measure: Unités de mesure
  update_password_message_title: Confirmer le changement de mot de passe ?
  update_password_message_description: L'utilisateur devra se reconnecter avec le nouveau mot-de-passe
  get_more_units_of_measure: Charger + { count } unité de mesure | Charger + { count } unités de mesure
  load_more_units_of_measure: Plus d'unités
  update_setting_success_message: Le paramètre a été modifié avec succès
  update_setting_error_message: Une erreur s'est produite. Le paramètre n'a pas été modifié.
  update_setting_tolerance_error_message: Vous ne pouvez pas modifier la tolérance
  value: Valeur
status:
  created: Le statut a été créé avec succès
  updated: Le statut a été mis à jour avec succès
  deleted: Le statut a été supprimé avec succès
  status: Statut
  statuses: Statuts
step:
  add_file: Ajouter un fichier
  answer_nok: Nok
  answer_ok: Ok
  boolean: OK / NOK
  checkbox: Case à cocher
  description_placeholder: Entrer une description
  multiple_selections: Choix multiples
  customized_answer: Réponse personnalisée
  create_answer: Créer une réponse
  done: fini
  file: Fichier
  file_upload_issue: Un problème est rencontré lors de chargements de fichier
  link: Lien
  list: Sélection
  select_condition_item_in_list: Sélectionner une réponse...
  select_condition_step_in_list: Sélectionner les blocs à afficher...
  uncertainty: Incertitude
  mandatory: Obligatoire
  means: Moyen de mesure
  measure: Mesure
  measure_max: Max
  measure_min: Min
  measure_target: Cible
  margin: Marge
  set_value: Valeur définie
  percentage: Pourcentage
  absolute_value: Valeur absolue
  measure_tolerance: +/-
  measure_unit: Unité
  name_placeholder: Entrer un nom
  napossible: Non appliquable
  take: Fois
  delete_step_title: Suppression du bloc impossible
  delete_step_description: La suppression de ce bloc est impossible car il dépend d'un bloc conditionnel.
  add_condition: Ajouter une condition
  no_step_available: Aucun bloc disponible
trigger:
  day: Journée
  production_order: Ordre de fabrication
  team: Equipe
user:
  first_name: Prénom
  last_name: Nom
  unknown_creator: Créateur inconnu
  personal_information: Informations personnelles
  role: Rôle
  sso_authentication: Authentification centralisée (SSO)
  sso_only_error: Uniquement l'authentification SSO est activée pour cet utilisateur
  use_sso_only: En activant ce bouton, l’utilisateur devra obligatoirement avoir une adresse mail et ne pourra pas se connecter autrement qu’avec cette adresse
  role_attribution_mandatory: L'attribution d'un rôle est obligatoire
  email: Email
  updated: L'utilisateur a été mis à jour
  reactivate: Réactiver
  desactivate: Désactiver
  reactivated_successfully: Ce compte à été réactivé
  deactivated_successfully: Ce compte à été désactivé
  confirmation_title_activate_user: Êtes-vous sûr de vouloir réactiver le compte de {name} ?
  confirmation_title_desactivate_user: Voulez-vous vraiment désactiver le compte de {name} ?
  confirmation_description_activate_user: L'utilisateur aura de nouveau accès à JUNO.
  confirmation_description_desactivate_user: L'utilisateur ne pourra plus accéder à JUNO une fois que son compte sera désactivé.
  disabled_at: Désactivé le
  reactivate_account: Réactiver le compte
  disable_account: Désactiver le compte
  active: Actif
  disabled: Désactivé
  status: Statut
  by: par
  language: Langue
  client: Client
  site: Site
  automatic_deactivation: Désactivation automatique
  temporary_account: Compte temporaire
  temporary: Temporaire
  disabled_automatically_at: Désactivé automatiquement le
  edit_information: Modifier les informations
  password: Mot de passe
  modify_password: Modifier le mot de passe
  nfc_authentication_status: Statut d'authentification NFC
  nfc_code: Code NFC
  nfc_text_validity: Tester la validité NFC
  nfc_status: Statut NFC
groups:
  name: Nom
  created_at: Date de création
  users: Utilisateurs
  load_more_groups: Plus de groups
  placeholder: Sélectionnez les personnes ou les groupes à alerter
  no_result: Aucun résultat ne correspond à votre recherche.
validations:
  document_category: La catégorie ne doit pas être vide
  document_description: Le champ description est obligatoire
  document_name: Le champ nom est obligatoire
  minimum_characters: Veuillez saisir au moins {length} caractères
template:
  archive: Archiver le modèle
  create: Nouveau modèle
  search: Rechercher un modèle
  template_name: Nom du modèle
  new_template: Nouveau modèle
  delete_confirmation_message: Voulez-vous vraiment archiver ce modèle?
  template_update_error_message: Une erreur s'est produite lors de l'enregistrement du modèle
  template_update_documents_error_message: Certaines fiches n'ont pas été mis à jour correctement, veuillez réessayer.
  template_update_message: Le modèle a été modifié avec succès
  archive_template: Archiver
  unlink_template: Dissocier
  modify: Modifier le modèle
  publish_template: Publier
  reference: Référence
  template_used_reference:  "Toute modification du modèle est irréversible et entraînera des modifications dans les fiches suivantes :"
  template_delete_used_reference:  "L'archivage du modèle entraînera des modifications irréversible dans les fiches suivantes :"
  are_you_sure_edit: La modification de ce modèle modifiera tous les fiches associées, êtes-vous sûr de vouloir continuer ?
  get_more: Charger + { count } modèles
  load_more: Plus de modèles
users:
  readonly_mode: Mode lecture seule
  get_more: Charger + { count } utilisateur | Charger + { count } utilisateurs
  load_more: Plus d'utilisateurs
workorder:
  set_operation_in_progress: Remettre l'opération en cours
  complete: Terminer l'opération
  confirm_modal_title: Terminer et clôturer l'opération en cours
  confirm_modal_description: Vous êtes sur le point de terminer l'opération, avez-vous bien complété l'ensemble de vos fiches ?
views:
  add: Créer une vue
  title: Supervision
  name: Nom de la vue
  description: Description de la vue
  filters: Filtres actifs
  no_filters_selected: Aucun filtre sélectionné
  no_group: Aucun
  save: Sauvegarder la vue
  delete: Supprimer la vue
  new_vue_template_title: Nouvelle vue
  new_vue_template_description: Entrer une description ici
  saved_views: Vues sauvegardées
  save_view: Sauvegarder
  view_all: Voir tout
  view_created: Une nouvelle vue a été créée avec succès
  view_updated: La vue a été modifiée avec succès
  view_delete: La vue a été supprimée avec succès
  error_create: Une erreur est survenue lors de la création de la vue
  error_update: Une erreur est survenue lors de l'enregistrement de la vue
  error_delete: Une erreur est survenue lors de la suppression de la vue
  error_empty_title: Le titre ne doit pas être vide
  delete_confirmation_message: Êtes-vous certain de vouloir supprimer cette vue ?
  delete_modal_title: Supprimer une vue
  get_more_reports: Charger + { count } rapport | Charger + { count } rapports
  load_more_reports: Plus de rapports
actions:
  new_goal: Nouvelle cible
  alerts: Alertes
  quick_actions: Actions rapides
  report_alert: Signaler
  pin_column: Figer
  unpin_column: Défiger
  report_alert_description: 'Détaillez le signalement et sélectionner les différentes personnes à alerter :'
workflows:
  add: Ajouter une étape
  description: Description
  associated_status: Statut associé
  associated_permissions: Permissions associées
  create: Créer un cycle de vie
  created: Le cycle de vie a été créé avec succès
  updated: Le cycle de vie a été mis à jour avec succès
  error: Une erreur s'est produit lors de la création/mis à jour du cycle de vie
  error_inputs: Veuillez remplir les champs obligatoires pour valider la nouvelle étape
  add_description: Ajouter une description
  name: Nom du cycle de vie
  built_in: Cycle de vie par défaut
  customizable: Cycles de vie customisables
  new_workflow_title: Nouveau cycle de vie
  not_found: Aucun cycle de vie trouvé
  not_found_description: Créer un nouveau cycle de vie en cliquant sur le bouton en haut à droite de l'écran
  save: Sauvegarder le cycle de vie
  select_workflow: Sélectionner un cycle de vie
  no_workflow: Aucun cycle de vie
  select_workflow_type: Sélectionnez un type de cycle de vie pour en créer un nouveau
  unnamed: Cycle de vie sans nom
  search_status: Rechercher un statut...
  select_option: Sélectionner un statut
  templates: Modèles
  title: Cycle de vie
  default: Cycle de vie par défaut
  type: Type
  type_select: Sélectionner un type
  type_documents: Fiches
  type_reports: Rapport
  type_alerts: Alert
  type_operations: Opération
  type_of: Ordre de fabrication
  default_document_step_1: Fiche en cours d'édition
  default_document_step_2: Fiche publié
  default_report_step_1: Nouveau rapport
  default_report_step_2: Rapport en cours
  default_report_step_3: Rapport terminé
  default_alert_step_1: Nouvelle alerte
  default_alert_step_2: Alerte en cours
  default_alert_step_3: Alerte gérée
  default_operations_step_1: Nouvelle opération
  default_operations_step_2: Opération en cours
  default_operations_step_3: Terminer opération
  default_of_step_1: Nouvel OF
  default_of_step_2: OF en cours
  default_of_step_3: OF complété
  default_of_step_11: OF libéré
firebase:
  wrong_password: Mot de passe incorrect
  wrong_actual_password: Mot de passe actuel incorrect
  wrong_email: Adresse e-mail incorrecte
  weak_password: Le mot de passe doit faire au moins 6 caractères
  too_many_attempts: Votre compte est temporairement désactivé, vous avez effectué trop de tentative. Veuillez réinitialiser votre mot de passe
  unexpected_error: Une erreur inattendue est servenue. Veuillez rafraîchir la page
  user_disabled: Votre compte a été désactivé
operator:
  search_workplace: Rechercher un poste de charge
  sel: Rechercher un poste de charge
  search_workplace_message: Affinez votre recherche pour faire apparaître les poste de charges associés
  select_all_workplaces: Sélectionner tous les postes de charge
  workplaces_recently_opened: Postes de charges récemment ouverts
  all_workplaces: Tous les postes de charges
  workplace_doesnt_exist: Ce poste de charge n'existe pas
  no_workplaces_recently_opened: Vous n'avez pas encore ouvert de poste de charge
  filter_by_workplaces: Filtrer par poste de charge
  workplace: Poste de charge
  sidebar_nir_monitoring: Surveillance NIR
  sidebar_operations_support: Support opérationnel
  sidebar_workplace_documents: Fiches de poste
  sidebar:
    workplace-documents: Fiches de poste
    nir: Surveillance NIRs
    machine-monitoring: Suivi des machines
    qualify: Qualifier
    requalify: Requalifier
    machine-is-available-again: Machine de nouveau disponible
    machine-is-off: Machine éteinte
  qualify:
    modal:
      title: Qualifier l’arrêt machine
      description: Un arrêt de production a été détecté sur la machine %{machine} à %{time}. Veuillez fournir les détails suivants pour qualifier l'événement d'arrêt.
      reason: Raison d’arrêt
      reason-placeholder: Sélectionner une raison d’arrêt...
      comment: Commentaire
      comment-placeholder: Ajouter un commentaire...
      optional: Optionnel
  requalify:
    modal:
      title: Requalifier l’arrêt machine
      description: Veuillez fournir les informations suivantes pour requalifier cet événement d'arrêt.
      reason: Raison de requalification
      reason-placeholder: Sélectionner une raison de requalification...

error:
  password_policy_not_valid: Le mot de passe doit avoir au moins 8 caractères et inclure des lettres minuscules et majuscules
  nfc_code_policy_not_valid: Le code doit comporter 16 caractères
  passwords_not_match: Les mots de passe ne correspondent pas
  not_same_old_password: Le mot de passe ne peut pas être identique à l'ancien mot de passe
  email_already_in_use: Email déjà utilisé
welcome_url:
  operator_selection: Sélection de poste de charge
  operator_free: Tous les postes de charges
  designer_space: Espace conception
  select_option: Sélectionner...
  operator_space: Espace opération
  library: Bibliothèque
  supervision: Supervision
  analytics: Analyse
  space: Espace
  conception: Conception
  operation: Opération
  welcome_page: Ecran d'accueil
validate_nc: Valider les NC
sidebar:
  manage_production: Piloter ma production
  production_monitoring: Suivi de production
  incidents: Incidents
  production_order_monitoring: Suivi des OFs
  performance_analysis: Analyser ma performance
  performance_analysis_quality: Qualité
  performance_analysis_production: Production
  handle_documents: Gérer mes fiches
  documents: Fiches
  media_library: Médiathèque
sso:
  authentication_error: Une erreur s'est produite lors de la connexion au fournisseur sso, veuillez vérifier auprès de votre administrateur
  connect: Connecter avec votre fournisseur SSO
  microsoft_connection: Connexion Microsoft (SSO)
mes:
  production-rate: Cadence de production
  po: OF
  paretor-unknown-stoppage: Pareto des causes d’arrêt
  digram-trs: Diagramme en cascade TRS
  no-machines-found: Aucune machine trouvée
  production-monitoring: Suivi de production
  machine-has-no-data: La machine n'a pas de données
  performances: Performances
  production-indicators: Indicateurs de production
  setting: Paramétrage
  setting-shutdown-reasons: Paramétrage des raisons d’arrêt
  machine: Machine
  production: Production
  unknown-stoppage: Arrêt non qualifié
  total-time: Temps total
  opened-time: Temps d'ouverture
  production-time: Temps de prod.
  quality-waste: Rebuts
  production-rate-delta: Ecarts de cadence
  net-production-time: Temps net
  available: Disponible
  batch: Lot
  settings:
    modal:
      reason: Titre de la raison d’arrêt
      reason-placeholder: Saisir un titre...
      machine: Machine
      stop-machine-reason: Sélectionner une machine
      color: Couleur du paramétrage
      new:
        title: Nouvelle raison d’arrêt
      edit:
        title: Modifier la raison d’arrêt
    table:
      title: Title
      status: Status
      machine: Machine
      color: Color
  associate-po-to-my-machine:
    title: Associer un OF à ma machine
    description: Veuillez saisir les informations requises pour associer un OF à votre machine et voir la quantité prévue à produire.
    production-order-number-label: Numéro de l’OF
    production-order-number-placeholder: Saisir le numéro de l’OF...
    quantity-label: Quantité à produire
    quantity-placeholder: Saisir la quantité à produire...
    save: Associer l’OF
