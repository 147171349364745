actions:
  alerts: Alerts
  new_goal: New goal
  quick_actions: Quick actions
  pin_column: Pin
  unpin: Unpin
  unpin_column: Unpin column
  report_alert: Alert
  report_alert_description: 'Detail the report and select the different people to be alerted :'
alerts:
  alert_center: Alerts center
  comment: Comment
  information_about_alerts: Additional information about the alert
  no_saved_answers: No answer has been pre-registered
  search_placeholder: Search by document name
  people_to_alert: 'People or groups to mention:'
  select_users_to_alert: Select user(s) to mention
  to_signal: 'To signal:'
  new_alert: 'New alert'
  no_justification_provided: No justification has been provided
  minimum_one_selected_people: At least one people must be assigned to the alert
  minimum_one_justification: You must provide a justification
  raised_by_me: Raised by me
  my_interventions: My interventions
  assigned_to: assigned alert to
  removed_from: has removed
  status_history: Status history
  get_more: Get { count } more alert | Get { count } more alerts
  load_more: Get more alerts
  add_comment: Comment on the alert or add some details...
analysis:
  analysis: Analysis
authentication:
  wrong_authentication_method: your user doesn't have the permission to connect using a password, please refer to your administrator for more information.
instructions:
  instructions_transfer: Instructions transfer
  modify_instructions: Modify
  selected: selected
  save_instruction: Save
  unarchive: Unarchive
  export: Export
  no_instructions_found: No instructions found
  new_instruction: New instruction
  archive: Archive
  created_successfully: Instruction successfully created
  create_error: An error occured
  create_error_msg: An error occured please try again later
  updated_successfully: Instruction successfully modified
  update_error: An error occured
  update_error_msg: An error occured please try again later
  write_instructions: Write down your instructions for the next operator...
application_fields:
  all_operations: All operations
  all_operations_search: 'All operations containing: { entry }'
  all_products: All products
  all_products_search: 'All products containing: { entry }'
  all_workplaces: All workplaces
  all_workplaces_search: 'All workplaces containing: { entry }'
  application_fields: Application Fields
  charging-station: Charging Station
  operations: Operations
  product: Product
  products: Products
  reference: References
  select_product: Select a product...
  select_workplace: Select a workplace...
  select_operation: Select an operation...
  select_all_operations: Select all operations
  select_all_products: Select all products
  select_all_workplaces: Select all workplaces
  workplaces: Workplaces
batch_record:
  add_comment: Add a comment
  batch: PO
  batch_sumup: See OF summary
  information: PO information
  delete_document_from_batch_title: Delete an attachment file
  delete_document_from_batch_description: >-
    Are you sure you want to delete this attachment file? This action cannot be undone.
  documents: PO documents
  release_title: PO release
  release_title_sidebar: Batch release
  modal_release_title: Are you sure you want to release the PO { name }?
  modal_release_description: You are going to release the PO. Make sure the PO is fully compliant before validating. Please enter your login details to confirm.
  new_document: New document
  update_document: Update document
  release: Release
  release_batch_error: Wrong credentials.
  released_batch: PO released at { date } by
  last_released_by_title: Latest PO release
bread_crumb:
  documents: Documents
  new_view: New view
  templates: Templates
  views: All reports
  production_orders: All production orders
  simulation: Simulate a production order
button:
  about: About
  back: Back
  go: GO
  home: Home
  toggle_dark: Toggle dark mode
  toggle_langs: Change languages
dashboard:
  empty_dashboard: No dashbord has been selected select one from the list to see it here
  last_30_days: Last 30 days
  select: Select a dashboard
  select_dashboard_placeholder: Select
dates:
  month: '{amount} month ago | {amount} months ago'
  day: '{amount} day ago | {amount} days ago'
  hour: '{amount} hour ago | {amount} hours ago'
  minute: '{amount} minute ago | {amount} minutes ago'
  second: '{amount} second ago | {amount} seconds ago'
days:
  daily: Daily
  friday: Friday
  monday: Monday
  saturday: Saturday
  sunday: Sunday
  thursday: Thursday
  tuesday: Tuesday
  wednesday: Wednesday
monthes:
  january: January
  february: February
  march: March
  april: April
  may: May
  june: June
  july: July
  august: August
  september: September
  october: October
  november: November
  december: December
display:
  all_columns: All columns
  editable_columns: Editable columns
  all_lines: All lines
  editable_lines: Editable lines
document:
  type: Document type
  type_manual: Manual
  type_readonly: Lecture seule
  type_auto: Automatic (NIR)
  device: Device
  notify_operator: Notify operator
  simulate: Simulate
  production_order: Production order
  display: Display
  valid: compliant
  uncertain: uncertain
  non_valid: not compliant
  display_parameters: Display settings
  status: Status
  get_more_revisions: Get { count } more revision | Get { count } more revisions
  load_more_revisions: More revisions
  use_as_template: Use as a template
  document_to_model_success: Document successfully converted to a template
  unlink_steps: First disassociate the already imported blocks from a template.
  exit_without_saving: Exit without saving
  create: New document
  add_new_block: Add a new block
  application_field: Application fields
  archive: Archive document
  archive_confirmation_message: >-
    Are you sure you want to archive this document? This action cannot be
    undone.
  ask_admin: Please contact the administrator
  category: Category
  column_number: Column number
  columns: Columns
  capture: Capture
  connect_to_balance: Connect the device
  lines: Lines
  created_by: Created by
  created_on: Created on
  cycle_of_document: Cycle of document
  delete: Delete document
  delete_confirmation_message: Are you sure you want to delete this document? This action cannot be undone.
  document_archive_message: The document has been successfully archived
  document_archive_title: Archived document
  document_creation_message: The document has been successfully created
  document_delete_message: The document has been successfully deleted
  document_update_error_message: An error has occured when saving the document
  document_update_message: The document has been successfully modified
  enter: Enter
  expiration_date: Expiration date
  file: File
  file_extension_error: The extension is not authorized
  finish: Finish document
  finish_confirmation_message: >-
    Finishing the document will finish it for the current production order, are
    you sure you want to proceed?
  format: Format
  frequency: Frequency
  input_frequency: Input frequency
  input_frequency_activate: Enable frequency inputs
  get_more: Get { count } more document | Get + { count } more documents
  load_more: Get more documents
  last_updated_on: Last updated on
  lifecycle: Lifecycle
  measures: Measure
  name: Name
  new_document: New document
  no_files: There are no files available
  no_workplace_documents: No document available on this workplace
  not_found: No document found
  note: Note
  number_of_columns: Number of columns
  order: Order
  cant_finish_document: There are still some entries to be made
  cant_finish_document_description: You cannot complete this document without having entered the cells on the mandatory (non-optional) lines. However, you can fill in "N/A" in the cells concerned or raise an alert about them.
  cant_finish_document_description_mandatory_lines: 'The following lines are mandatory:'
  cells_missing: '{number} unfilled cell(s)'
  publish_document: Publier le document
  create_new_document: Confirm changes?
  create_new_document_message: You are going to create the first version of this document
  create_new_version: Create a new version?
  create_new_version_message: You are going to change the document version by saving it
  schedule:
    minute: Minute
    hour: Hour
    day: Day
    days: Days
    document_is_accessible_every: The document will be available every
    end: End
    every: Every
    every_feminine: Every
    month: Month
    months: Months
    no_end: No ending date
    no_schedule: No schedule
    no_start: No starting date
    publication_date: Publication date
    as_soon_as_the_publication: As soon as the publication
    repeat_each: Repeat each
    repeat_each_feminine: Repeat each
    specific_recurrence: Specific recurrence
    set_hour: Set hour
    set_hour_error: The end time must be greater than the start time.
    recurrence_from: From
    recurrence_to: To
    document_is_accessible: The document will be available
    recurrence: Recurrence
    repeat: Repetition
    start: Start
    available_from: Available from
    availablity: Availability
    start_at: Start at
    end_at: End at
    the_m: The
    the_f: The
    the: The
    the_feminine: the
    the_masculine: the
    the_plurial: The
    week: Week
    weeks: weeks
    year: Year
    year_feminine: Years
    every_m: Every
    every_f: Every
    every_day: Everyday | Every {nb} days
    from_date: from {date}
    until_date: until {date}
    every_week_article: Every
    every_week_days: Every {days}
    every_with_article: Every
    every_week: Every week | Every {nb} weeks
    every_month: Every month | Every {nb} months
    every_year: Every year | Every {nb} years
    permanently_available: Permanently available
    as_soon_as: As soon as
    no_starting_date: No starting date
    years: Years
    not_recognized: Recurrence type isn't recognized
  scheduling: Scheduling
  scheduling_subtitle: Schedule the display of the document in the operation area
  search: Search a document
  select_application_fields: Select application fields
  start_date: Starting date
  start_to_create_a_new_document: Start to create a new document
  state: State
  status_archived: Archived
  status_deleted: Deleted
  status_draft: Draft
  status_finished: Finished
  status_in_progress: In progress
  status_in_review: In review
  status_new: New
  status_published: Published
  status_select: Select
  status_released: Released
  status_active: Active
  status_inactive: Inactive
  status_qualified: Qualified
  step_check: OK/NOK
  step_checkbox: Checkbox
  step_file: File
  step_link: Link
  step_measure: Measure
  step_options_list: Options list
  step_text: Text
  step_time: Time
  step_number: Number
  step_photo: Picture
  step_photo_take_picture: Take a picture
  step_photo_take_picture_description: Use your camera to take a photo. Click "Capture" to save the photo and preview it for 2 seconds before the modal automatically closes.
  step_photo_take_picture_again: Retake picture
  step_photo_update_picture: You can update your existing photo by using the camera. Click on "Take a picture" to save the new photo, and it will be previewed for 2 seconds before the modal automatically closes, replacing the previous one.
  step_photo_update_picture_description: Update picture description
  step_photo_preview_picture: Preview picture
  step_photo_no_devices_found: No device found. Please connect a USB Camera
  step_photo_no_camera_access: Camera access denied. Please grant the access on your browser settings.
  steps_linked_to_template: Some steps are already linked to a template
  title: My Documents
  to_fill: To fill
  trigger: Trigger
  trigger_element: Triggered on
  starting_at: Starting at
  unsaved_changes_description: Any changes will be lost if you don't save this document
  unsaved_changes_title: This document is not saved
  update_research: Update search
  updated_by: Updated by
  updated_at: Updated at
  updated_on: Updated on
  version: Version
  version_reason_add: Add a note about version upgrade
  version_reason_error: You must justify the version change
  you_have_completed: You have
  workflow: Workflow
  confirm_archive_in_document:
    title: Confirm Archive
    description: You are about to archive this document. Once archived, this document will no longer be available for use in the PO. Are you sure you want to proceed?
  confirm_archive_many_documents:
    title: Confirm archiving
    description: You are about to archive 1 document. This document will no longer be available for use in the PO. | You are about to archive {nbDocument} documents. These documents can no longer be used in the PO.
firebase:
  too_many_attempts: >-
    Your account is temporarily disabled, you have made too many attempts.
    Please reset your password
  unexpected_error: An unexpected error has occurred. Please refresh the page
  weak_password: Password should be at least 6 characters
  wrong_actual_password: Incorrect current password
  wrong_email: Wrong email address
  wrong_password: incorrect password
  user_disabled: Your account has been disabled
export:
  attachments_of_document: Attachments_of_the_document
  change_target: Dynamic target
  columns_count: Number of columns
  download_is_starting: Your  download will start in a second...
  download_error: An error was encountered while connecting to the server.
  document_export_error: An error was encountered while working on your document.
  export_document_as_pdf: Export document in a pdf format
  report_title: Report's Title
  export_type: Export type
  historic_value: Historic value
  justification: Justification
  comment: Comment
  workplaces: Workplaces
  status: Status
  type: Type
  tags: Tags
  modified_by: Updated by
  modified_at: Updated at
  created_by: Created by
  created_at: Created at
  name: Name
  step_type: Type
  description: Description
  tool: Measurement tool
  goal: Goal
  min: Min
  max: Max
  unit: Unit
  at: at
  operator: Operator
  update_date: Filled at
  value: Value
  status_history: Report's status history
  master_session_status_history: Work order's status history
format:
  fixed: Fixed
  formula: Formula
  quantity: Quantity
  unique: Unique
  unlimited: Unlimited
filters:
  machines: Machines
  assignee: Assigne
  assignation: Assignation
  raised_by: Raised by
  blocker: Blocker
  blockers: Blocker
  add: Filter reports
  categories: Category
  choose_filter: Choose a filter
  go_back: Back to filter choices
  group_by: Group by
  display: Display
  display_columns: Displayed columns...
  at_least_one_display_column: At least one option must be chosen
  date: Date
  documents: Document
  models: Models
  products: Product
  production_orders: Work order
  title: Filters
  teams: Team
  search: Search filter...
  status: Status
  role: Role
  today: Today
  yesterday: Yesterday
  last_24_hours: Last 24 hours
  last_3_days: Last 3 days
  last_7_days: Last 7 days
  last_14_days: Last 14 days
  last_month: Last month
  no_grouping: No grouping
  users: Operator
  workplaces: Workplace
  filter_by: Filter by
  filter: Filter
  select_a_date: Select a date
  templates: Templates
  remove_all: Remove all
  operations: Operation
  operation: Operation
  product: Product
  workplace: Workplace
  work_order: Work order
global:
  performances: Performances
  bread_crumb:
    documents: documents
    templates: templates
    simulation: simulation
  per_page: per page
  send: Send
  switch_user: Switch user
  switch_user_title: Switch user
  switch_user_description: You are about to switch the user account. Upon confirmation, you will be redirected to the login page and you will have to enter the credentials of another user.
  selected_documents: '{n} selected document | {n} selected documents'
  selected_documents_from_to: Documents {from}-{to} on a total of {total} documents
  selected_products_from_to: Products {from}-{to} sur un total de {total} products
  selected_workplaces_from_to: Workplaces {from}-{to} sur un total de {total} workplaces
  selected_lookups_from_to: Lookup {from}-{to} sur un total de {total} lookups
  element_not_found: No element found
  if: If
  id: Id
  or: Or
  then: Then
  group: Group | Groups
  days: Days
  parent: Parent
  roles: Roles
  change_language_to_apply: It is strongly recommended to refresh the page to apply the changes
  password_expired: Your password has expired, please reset you password
  unknown: Unknown
  audit_logs: Connection logs # TO CHANGE AFTER
  all_the_of: All the OF
  people: '{amount} people'
  simulation: Simulation
  choice: choice | choices
  element: No element | 1 element | {n} elements
  account_and_access: Users
  permissions: Roles and permissions
  actions: Actions
  add: Add
  add_user: Add user
  add_a_attachment_file: Add an attachment file
  additional_informations: Additional informations
  alert: Alert | Alerts
  alerts: Alerts
  instructions: Instructions
  alerts_success: Successfully alerted!
  all: All
  allTeams: All teams
  all_the_documents: All the Documents
  all_the_templates: All the Templates
  analyses: Analytics
  select_type: Select a type
  please_select_type: Please select a type
  and: and
  application_fields: Fields of application
  archive: Archive
  are_you_sure_you_want_to_delete_user: Are you sure you want to this user?
  are_you_sure_you_want_to_logout: Are you sure you want to logout
  at: at
  attachment: Attached files
  attachments: Attachments
  attention_any_unsaved_document_will_be_lost: Attention any unsaved Documents will be lost !!
  back_home: Go back home
  beforeOrAfter: Before or after
  both_passwords_dont_match: Both passwords don't match
  cancel: Cancel
  change_password: Change password
  click_button_reload: Please click on the button below to reload !
  client: Client
  clients: Clients
  close: Close
  complete: Complete
  confirm: Confirm
  confirm_modification: Confirm changes ?
  confirm_password: Confirm password
  confirm_password_validation_label: Both passwords
  confirmation_password: Confirm password
  content: Content
  copy_of: Copy of
  create: Create
  create_setting_success_message: The setting has been successfully created
  created_by: Created by
  created_on: Created on
  created_at: Created at
  current_language: eng
  current_password: Current password
  dashboard: Dashboard
  define_as_a_template: Define as a template
  delete: Delete
  delete_setting_success_message: The setting has been successfully deleted
  description: Description
  disconnected: You have been disconnected
  disconnected_for_inactivity: Due to prolonged inactivity, you are disconnected.
  display: Display
  document: document | document | Documents
  document_parameters: Document parameters
  operator_interface: Operator space
  documents: Documents
  download: Download
  download_as_pdf: Download as pdf
  duplicate: Duplicate
  edit: Edit
  email: Email
  empty_list: No records found
  enriched_export: Enriched Export
  enriched: Enriched
  error: Error
  export: Export
  files: Files
  finish_document: End the document
  finish_entry: Finish my entry
  finished: Finished
  first_name: First name
  for: For
  forbidden: Forbidden access to this page
  forbidden_message: Please check the URL in the address bar and try again.
  force_reset_password: You are logging in for the first time, please reset your password
  from: from
  row: Row | Rows
  ftp_parameters: FTP
  filter: Filter | Filters
  hello: Hello
  history: History
  import: Import
  import_export_parameters: Import/Export
  confirm_import: Confirm data import
  confirm_import_desc: Importing this file will update customer data previously imported into JUNO
  inProgress: in progress
  in_progress: In progress
  resolved: Resolved
  integration_parameters: Integrations
  integrations: Integrations
  justification: Justification
  label: Label
  last_modification: Last modification
  last_name: Last name
  library: Library
  link: Link
  list: List
  loading: Loading...
  login: Login
  logout: Logout
  mandatory: Mandatory
  mark_as_read: Mark everything as read
  media_library: Media library
  minutes: Minutes
  modeles: templates
  monitoring: Monitoring
  month: Month
  my_dashboards: My dashboards
  name: Name
  name_document: Name of the document
  nb_of_boxes: Number of boxes
  need_reset_password: Do you need to reset your password ?
  network_error: No internet connexion
  new: New
  new_password: New password
  new_version: A new version of Juno is available
  next_check_at: A check need to be done on step {step} of report {reportName}
  no_value: No value
  notification_parameters: Notifications
  number_of_steps: '{number} step | {number} steps'
  ok: Ok
  operation: Operation
  optional: Optional
  out_of: out of
  parameters: Parameters
  password: Password
  password_has_changed: Your password has been modified
  password_update_error_message: An error has occurred while changing the password
  percentage: percentage of tolerance
  picture: Picture
  preview: Preview
  product: Product
  publish: Publish
  range: range
  reason: Reason
  reload: Reload
  refresh: Refresh
  remove_attachment: Remove attachment
  report: Report | Reports
  reports: Reports
  reset_email_sent: >-
    An email to reset your password has been sent. Please click on the link when
    you get it
  restore: restore
  revision: Revision
  role: Role
  save: Save
  save_preferences: Save preferences
  search_assignee: Assign for
  search: Search
  seeAll: See all
  seeDescription: see description
  select: Select
  selectTeam: Select a Team
  select_tab: Select a tab
  send_reset_email: Send reset password email
  settings: Settings
  sign_out: Sign out
  simple_export: Simple Export
  site_management: Site management
  sites: Sites
  start: Start
  startTask: Start task
  steps_parameters: Steps parameters
  success: Success
  summary: Synthesis
  switch_to_operator: Operations space
  switch_to_supervisor: Design space
  tags: Tags
  template: Template
  templates: Templates
  template_messages_parameters: Template messages
  time: Temps
  title: Title
  to_consult: To consult
  other: Other
  today: Today
  total_of_controles: Total number of controls
  total_parts: Total parts
  total_report: Total reports
  understood: I understood
  unlink_step: Are you sure you want to unlink this block from its template ?
  unlink_step_message: >-
    Editing this block implies that it can no longer be updated if you break the
    link with the following template :
  update: Update

  update_setting_success_message: The setting has been successfully updated
  update_setting_tolerance_error_message: You cannot change the tolerance
  updated_by: Updated by
  updated_on: Updated on
  user: User
  user_session_management: User session management
  users: Users
  validate: Validate
  view: View
  warning: Warning
  week: Week
  workflow_parameters: Workflows
  status_management: Status management
  workflows: Workflows
  workplace: Workplace
  yes: 'Yes'
  no: 'No'
  you_are_offline: You are currently offline, please reconnect.
  your_profile: Your profile
global_fields:
  created_by: Created by
  created_on: Created on
history:
  history: History
  created: raised an alert
  assigned: assigned the alert to
  commented: added the following comment
  updated: changed the status of the alert
  and_more: and {amount} more...
insights:
  valid_blocks: Valid blocks
  valid_control_points: Valid control points
  valid_controls: Valid controls
intro:
  aka: Also known as
  desc: Opinionated Vite Starter Template
  dynamic-route: Demo of dynamic route
  hi: Hi, {name}!
  whats-your-name: What's your name?
jobs:
  choose_workplace: Choose your workplace
  choose_workplace_desc: Select your workplace to display the production orders
  create_production_order: Create this production order
  current_jobs: My production orders
  last_10_days: Workorders of the last 10 days
  last_30_days: Workorders of the last 30 days
  no-result: No result for your search
  no_product_order_selected: >-
    no fabrication order matches your request, click here to choose a product
    manually
  not_found: No open production order
  number: Job number
  operation: Operation number
  production_order_doesnt_exist: This production order does not exist!
  select-of: Please select an order number
  select_product: Please select a product
  start_new: Start a new job
  start_new_job_desc_no_jobs: >-
    Please start a new production order by clicking on the button at the top
    right of the screen
  status_finished: Finished
  status_finished_message: The order manufacturing has been finalised
  status_in_progress: In progress
  status_new: New
languages:
  en: English
  english: English
  fr: French
  french: French
  de: German
  german: German
  it: Italian
  italian: Italian
  es: Spanish
  spanish: Spanish
media_library:
  add: Upload a file
  archive_confirmation_message: Are you sure you want to archive the selected attachment
  delete: Remove a file
  delete_confirmation_message: Are you sure you want to delete this file
  failed_upload: we couldn't upload some files due to some errors
  media_parameters: Media library
  no_data: No files has been uploaded yet, click in the upload button to add your files
  no_file_yet: Add some files here and upload them on Juno
  no_search_result: No file matches your search query
  file_update_error_message: Error occurred while connecting to server, please try again.
  search_file: Upload a file on your computer
  show: Activé
  staged_file: file selected
  staged_files: '1 file has been selected | %{count} files has been selected '
  tab_archived: Archived
  tab_files: Files
  update_file: Update file
  upload: Upload
  upload_files: Upload files
  uploaded_files: >-
    1 files has been uploaded successfully | %{count} files has been uploaded
    successfully
not-found: Not found
notifications:
  activate_feature: Activate or desactivate
  alert_type: Alert notifications
  controls_needed: control to be done | controls to be done
  go_to_task: Go to task
  reminder_type: Reminder notifications
  undefined_document: Unnamed document
  view: View notification
  machine_stopped: Machine stopped at %{time}. Please immediately specify the reason for this stop.
  qualify_stop: Qualify the stoppage
pages:
  alerts: All alerts
  alerts_list: All alerts
  analytics: Analytics
  document: Documents
  form: Forms
  history: History
  library: Library
  media_library: Media library
  monitoring: Monitoring
  reports: All reports
  settings: Settings
  template: Templates
  views: Views
simulation:
  new_simulation: New simulation
  empty_simulation: You don't have any simulations yet. You can start creating them.
profil:
  description: Manage all personal information related to your JUNO profile
  email: Email adress
  language: Language
  my-profile: My profile
  security: Security
  trigram: Factory ID
  not_specified: Not specified
repetition:
  additional_filter_nature: Nature
  add: Add a repetition
  select: Select a repetition
  event: Event
  formula: Formula
  frequency: Frequency
  no_repetition_available: There are no repetition
  repetition_exists_message: A similar repetition already exists
  notification: Notification
  repetitions: Repetitions
  sampling: Sampling
  times: Times
  load_more: Load more
  search: Search
  reset: Reset
  save: Save
  create_sampling: Create a sampling
  create_formula: Create a formula
  select_formula: Select a formula
  formula_type: Formula type
  repeat_every: Repeat every
  created_successfully: the repetition was created successfully
  created_successfully_message: you can start using the new repetition in your documents
  creation_error: An unexpected error was encountered
  creation_error_message: An unexpected error was encountered during the creation of your repetition
report:
  target: Target
  dynamic_target: Dynamic target
  all_day: All day
  category: Category
  checkbox: To do
  control: Control
  controls: Controls
  valid_controls: Valid controls
  custom_valid_controls: 'Valid {header}'
  date: Date
  document: Document
  done: Done
  dynamic: Dynamic
  static: Static
  no_rows_to_show: Problem to display the data
  status_history: Status history
  execution_date: Execution date
  filter_invalid: Invalid
  filter_last_3_days: Last 3 days
  filter_last_7_days: Last 7 days
  filter_last_day: Last day
  filter_last_month: Last month
  filter_valid: Valid
  is_valid: Validity
  job: Work order
  job_abbreviation: FO
  last_update: Last save on
  measure: Measure
  no_steps: this file is empty
  operator: Operator
  product: Product
  raise_event: Trigger an event
  report_update_message: The file has been successfully saved
  select_option: Please select options
  see_alert: See alert
  status: Status
  status_finished: Finished
  status_in_progress: In progress
  status_new: New
  step_answer: Answer
  step_answer_state: State
  step_name: Name
  step_number: Step
  step_photo: Photo
  text: Text
  update_date: Update date
  view_report: View report
  work_order: Work order
  workplace: Workplace
  cell_history: History of entries
  cell_history_desc: This field has been modified by one or more users
  new_data: New data
  enter_value: Enter value...
  modify_data: Modification of a previously entered field
  justify_ko_title: Justification of non-conformity
  justify_ko_desc: Please justify the value of the following cell to continue typing on this document
  restore_data_title: Do you really want to restore this data?
  restore_data: Reset the entry
  restore_data_desc: These data were previously entered before being replaced by new, more recent entries.
  modify_data_error: Not all fields are filled in correctly
  modify_data_success: The new data has been successfully registered
  modify_data_desc: The data for this cell was entered by another user. This new data will be updated on the grids of each stakeholder on the document.
  justification: Justification
  precise_reasons: Specify the reasons for this modification...
  downloading: Downloading...
  downloading_message: We are preparing your document, please wait! | We are preparing your documents, please wait!
  download_finished: your document is ready | your documents are ready
  download_finished_message: Open your files now.
  download_failed: Error
  download_failed_message: An error was encountred while working on your document. | An error was encountred while working on your documents.
  download_limit_reached: Download is limited to 45 reports
  limit_reached: You have reached the limit, please refine your search.
session:
  automatic_reports: Automatoc reports
  ongoing_session: Production order started
  ongoing_session_message: You're going to resume a previously started session
  search_po: Search
  groups: Groups
  refine_search: Refine your search to bring up the associated workorders
  workorder_doesnt_exist: This production order doesn't exist
  create_workorder: Create a new workorder
  select_product: Select product
  launch_on: Launch on
  create_operation: Create new operation
  product_requiered: Select at least one product to continue
  of_name_requiered: Please choose a name for your production order
  operation_requiered: Please create at least one new operation
  all_documents_not_complete: Your documents are not all complete
  all_documents_not_complete_des: You cannot complete the operation until all the mandatory documents have been submitted.
  update: Updated at
settings:
  no_user_found: No user has been found
  users: Users
  alternative_workplaces: Alternatives workplaces
  mandatory_fields: Please be sure to fill all the mandatory fields
  new_user: New user
  members: Membres
  header_list: Sequence
  exigence: Exigence
  lookup_exists: A similar data record already exists, please modify one of the fields.
  nature: Nature
  mold: Mold
  plan: Plan
  password_expiration: Password expiration
  reset_automatically: Reset password automatically
  number_of_days_of_validity: Number of days the current password is valid
  name: Name
  search_user_placeholder: Search and add new members
  create_group: Create a group
  update_group: Update a group
  profile: Profile
  users_and_access: Users and access
  workflow: Workflow
  mandatory_column: This field is mandatory
  value_exists: This name exists
  audit_logs:
    name: Associated account
    action_hour: Triggered at
    action: Action
    get_more: Get + { count } entry | Get + { count } entries
    load_more: More entries
    login: Login
    logout: Logout
    reactivate: Activation
    deactivate: Deactivation
  status_name: Name
  justify_ko: NC Justification
  status_icon: Icon
  status_color: Color
  status_saved: Status has been successfully saved!
  create_status: Create a status
  conception_rights: Conception rights
  new_status: New status
  additional_data: Additional data
  update_product: Update a product
  create_product: Create a new product
  delete_product_title: Archive a product
  delete_product_description: Are you sure you want to delete this product ?
  update_workplace: Update a workplace
  create_workplace: Create a new workplace
  delete_workplace_title: Archive a workplace
  delete_workplace_description: Are you sure you want to delete this workplace ?
  update_lookup: Update lookup
  create_lookup: Create a new lookup
  delete_lookup_title: Archive data
  delete_lookup_description: Are you sure you want to archive the ?
  save_status: Save status
  decimal_values: Decimal values
  number_of_decimals: Number of significant digits
  lookup_keys_error: The file configuration doesn’t match the keys for this type. Please contact your operations manager
  same_as_tolerance: Same as tolerance
  alerts: Alerts
  input_modification: Input modification
  message: Content
  operator_session: Session
  input_parameters: Inputs
  parameter_name: Name
  parameter_value: Value
  add_option: Please enter your option and click on enter
  blocker: Blockers
  roles_access: Available to
  mandatory_finish: Complete inputs
  cancel: Cancel
  color: Color
  create_ftp_success_message: The server FTP has been successfully saved
  settings_error: A problem was encountered while connecting to the server, please try again
  create_setting_success_message: The parameter has been successfully created
  default: Défaut
  delete: Delete parameter
  delete_option: Delete option
  delete_option_confirmation_message: Are you sure you want to delete this option? This action cannot be undone.
  delete_setting_success_message: The setting has been successfully deleted
  disabled: Disabled
  document_category: Document category
  get_more_document_category: Get { count } more category | Get { count } more categories
  load_more_document_category: Get more categories
  document_status: Document status
  enable_tolerance: Enable Tolerance in Metrics
  enabled: Enabled
  ending_time: Ending time
  export:
    products: Export products data
    workplaces: Export workplaces data
  formula: Formula
  frequency: Fréquence
  grid_header: Grid header
  get_more_grid_header: Get { count } more header | Get { count } more headers
  load_more_grid_header: Get more headers
  import:
    products: Upload file for importing products
    workplaces: Upload file for importing workplaces
  is_selected: Is Selected
  list_options: List options
  get_more_list_options: Get { count } more list option | Get { count } more list options
  load_more_list_options: Get more list options
  get_more_messages: Get { count } more message | Get { count } more messages
  load_more_messages: Get more messages
  get_more_workflow_models: Get { count } more template | Get { count } more templates
  load_more_workflow_models: Get more templates
  list_type: List type
  list_value: List value
  lookup_tables: Lookup table
  maximum_inactivity_time: Inactivity time before automatic logout
  means_of_measure: Means of measure
  get_more_means_of_measure: Get { count } more mean of measure | Get { count } more means of measure
  load_more_means_of_measure: Get more means of measure
  measure_unit: Measure unit
  media_library: Active
  order: Order
  organization: Organisation
  product: Product
  create_role: Create role
  roles: Roles & rights
  roles_new: New role
  roles_create: Create
  roles_edit_name: Edit name
  roles_read: Read
  roles_write: Edit
  roles_change-status: Change Status
  roles_delete: Archive
  roles_ressource: Resource
  roles_cannot_delete_title: Cannot delete this role
  roles_cannot_delete_description: '{amount} user(s) own this role. Please assign them a different role or delete them beforehand'
  roles_confirm_delete_title: Confirm removal ?
  roles_confirm_delete_description: By confirming, this role will be permanently removed. This action is irreversible.
  repetition: Repetition
  repetition_type: Repetition type
  report_status: Report status
  sample: Sample
  sampling: Sampling
  starting_time: Starting time
  step_tag: Tag
  get_more_step_tag: Get { count } tag | Get { count } tags
  load_more_step_tag: Get more tags
  team: Team
  team_name: Team name
  get_more_teams: Get { count } more team | Get { count } more teams
  load_more_teams: Get more teams
  theTeam: The team
  tolerance: Tolerance
  type: Type
  update_password_message_title: Do you confirm changing the user's password ?
  update_password_message_description: the user will have to reconnect using the new password
  unit: Unit
  units_of_measure: Units of measure
  get_more_units_of_measure: Get { count } more unit | Get { count } more units
  load_more_units_of_measure: Get more units
  update_setting_success_message: The parameter has been successfully updated
  update_setting_error_message: An error occured. The parameter has not been updated.
  value: Value
  value_tolerance: Tolerance value
  workplaces:
    export: Export the list of workplaces in csv format
    import: Import a csv file
status:
  created: The status has been created successfully
  updated: The status has been updated successfully
  deleted: The status has been deleted successfully
  status: Status
  statuses: Statuses
step:
  add_file: Attach a file
  answer_nok: 'No'
  answer_ok: 'Yes'
  answer_state: State
  boolean: Yes/No
  checkbox: Checkbox
  description_placeholder: Enter a description
  done: done
  file: file
  file_upload_issue: A problem was encountered when loading the file
  link: link
  list: List
  uncertainty: Uncertainty
  select_condition_item_in_list: Select an option...
  select_condition_step_in_list: Select steps to display...
  mandatory: mandatory
  means: Measurement means
  measure: measure
  measure_max: Max
  measure_min: Min
  measure_target: Target
  margin: Margin
  set_value: Set value
  percentage: Percentage
  absolute_value: Absolute value
  measure_tolerance: +/-
  measure_unit: Unit
  multiple_selections: Multiple selections
  customized_answer: Customized answer
  create_answer: Create answer
  name_placeholder: Enter a name
  napossible: N/A
  take: Times
  delete_step_title: This step cannot be deleted
  delete_step_description: This step cannot be deleted because it depends on a conditional step. Please unlink them first.
  add_condition: Add a condition
  no_step_available: No step available
template:
  archive_template: Make it archived
  are_you_sure_edit: >-
    Changing this template will change all related document, are you sure you
    want to continue?
  create: New template
  archive: Archive template
  delete_confirmation_message: Are you sure you want to archive this template?
  modify: Modify the template
  new_template: New template
  publish_template: Make it published
  reference: Reference
  search: Search a template
  template_name: Template name
  template_update_error_message: An error has occurred when saving the template
  template_update_documents_error_message: Some documents are not updated correctly, would you please try again.
  template_update_message: The template has been successfully modified
  template_used_reference: >-
    Any modification of the template is irreversible and will lead to
    modifications in the following documents:
  template_delete_used_reference: >-
    Archiving the template is irreversible and will lead to modifications in 
    the following documents
  unlink_template: Unlink
trigger:
  day: Day
  production_order: Production order
  team: Team
user:
  first_name: First name
  last_name: Last name
  unknown_creator: Unknown creator
  personal_information: Personal Information
  role: Role
  sso_only_error: Only the SSO authentication is active for this user
  sso_authentication: Single sign on (SSO)
  use_sso_only: By activating this button, the user must have an email address and will not be able to connect other than with this address.
  role_attribution_mandatory: Role assignment is mandatory
  email: Email
  updated: User has been updated
  reactivate: Reactivate
  deactivate: Disable
  reactivate_account: Reactivate account
  disable_account: Disable account
  reactivated_successfully: The account has been reactivated successfully
  deactivated_successfully: The account has been deactivated successfully
  confirmation_title_activate_user: Are you sure you want to reactivate {name} account ?
  confirmation_title_desactivate_user: Are you sure you want to deactivate {name} account ?
  deactivate_account: Deactivate the account
  confirmation_description_activate_user: The operator will have access to JUNO again.
  confirmation_description_desactivate_user: The user will no longer be able to access JUNO once their account is deactivated.
  disabled_at: Disabled at
  active: Active
  disabled: Disabled
  status: Status
  by: by
  language: Language
  client: Client
  site: Site
  automatic_deactivation: Automatic deactivation
  temporary: Temporary
  disabled_automatically_at: Disabled automatically at
  temporary_account: Temporary account
  edit_information: Edit informaton
  password: Password
  modify_password: Modify password
  load_more_groups: More reports
  nfc_authentication_status: NFC authentication status
  nfc_code: NFC code
  nfc_text_validity: Test NFC validity
  nfc_status: NFC status
groups:
  name: Name
  created_at: Creation date
  users: Users
  placeholder: Search for people or groups to alert
  no_result: No results match your search
validations:
  document_category: Category should not be empty
  document_description: Document description is missing
  document_name: Document name is missing
  minimum_characters: Please enter at least {length} characters
views:
  add: Create new view
  delete: Delete report
  description: Report description
  error_create: An error occurred while creating the view
  error_delete: An error occurred while deleting the view
  error_empty_title: The title must not be empty
  error_update: An error occurred while saving the view
  filters: Active filters
  name: Report name
  new_vue_template_description: Enter a description here
  new_vue_template_title: New report
  no_filters_selected: No filters selected
  no_group: No group
  save: Save report
  save_view: Save
  saved_views: Saved reports
  title: Reports
  view_all: View all
  view_created: A new view has been successfully created
  view_delete: View has been successfully deleted
  view_updated: View has been successfully modified
  delete_confirmation_message: Are you sure you want to delete this view ?
  delete_modal_title: Delete a view
  get_more_reports: Get { count } more report | Get { count } more reports
  load_more_reports: More reports
users:
  readonly_mode: Readonly mode
  get_more: Get { count } more user | Get { count } more users
  load_more: More users
word: {}
workflows:
  add: Ajouter une étape
  description: Description
  associated_permissions: Associated permissions
  associated_status: Associated status
  create: Create a new workflow
  created: Workflow has been successfully created
  updated: Workflow has been successfully updated
  error: An error occured while creating/updating the workflow
  error_inputs: Please fill all the required fields to validate the new step
  default: Default workflow
  no_workflow: No workflow
  add_description: Add description
  name: Workflow name
  built_in: Default workflow
  customizable: Customizable workflows
  new_workflow_title: New workflow
  not_found: No workflow found
  not_found_description: >-
    Create a new workflow by clicking on the button at the top right of the
    screen
  select_workflow_type: Select a workflow type in order to create a new workflow
  save: Save workflow
  search_status: Search status...
  select_option: Select a status
  select_workflow: Select a workflow
  templates: Templates
  title: Workflows
  unnamed: Unnamed workflow
  type: Type
  type_select: Select a workflow type
  type_documents: Document
  type_reports: Report
  type_alerts: Alert
  type_of: Production order
  type_operations: Operation
  default_document_step_1: Document in progress
  default_document_step_2: Document published
  default_report_step_1: New report
  default_report_step_2: Report in progress
  default_report_step_3: Report finished
  default_alert_step_1: New alert
  default_alert_step_2: Alert in progress
  default_alert_step_3: Alert resolved
  default_of_step_1: New production order
  default_of_step_2: Production order in progress
  default_of_step_3: Production order completed
  default_of_step_11: Production order released
  default_operations_step_1: New operation
  default_operations_step_2: Operation in progress
  default_operations_step_3: Finish operation
workorder:
  set_operation_in_progress: Set operation in progress
  complete: Finish the operation
  confirm_modal_title: End and close the current operation
  confirm_modal_description: You are about to complete the operation, have you completed all your documents?
operator:
  search_workplace: Search a workplace
  search_workplace_message: Refine your search to dislay the right workplaces
  select_all_workplaces: Select all workplaces
  workplaces_recently_opened: Recently opened workplaces
  all_workplaces: All workplaces
  workplace_doesnt_exist: This workplace doesn't exist
  no_workplaces_recently_opened: You haven't opened yet workplaces
  filter_by_workplaces: Filter by workplace
  workplace: Workplace
  sidebar_nir_monitoring: NIR Monitoring
  sidebar_workplace_documents: Workplace documents
  sidebar_operations_support: Operations support
  sidebar:
    workplace-documents: Job Sheets
    nir: NIR Surveillance
    machine-monitoring: Machine Monitoring
    qualify: Qualify
    requalify: Requalify
    machine-is-available-again: Machine Available Again
    machine-is-off: Machine is off
  qualify:
    modal:
      title: Qualify Machine Downtime
      description: A production stoppage has been detected on machine %{machine} at %{time}. Please provide the following details to qualify the downtime event.
      reason: Stoppage Reason
      reason-placeholder: Select a stoppage reason...
      comment: Comment
      comment-placeholder: Add a comment...
      optional: Optional
  requalify:
    modal:
      title: Requalify Machine Stop
      description: Please provide the following information to requalify this stop event.
      reason: Requalification Reason
      reason-placeholder: Select a requalification reason...

error:
  password_policy_not_valid: The password must have a minimum of 8 characters and include lowercase and uppercase letters
  nfc_code_policy_not_valid: The code must have 16 characters
  passwords_not_match: Passwords do not match
  not_same_old_password: The password cannot be the same as the old password
  email_already_in_use: Email already in use
welcome_url:
  operator_selection: Workplace selection
  operator_all_workplaces: Operator space (all workplaces)
  operator_free: All workplaces
  designer_space: Designer space
  select_option: Select...
  operator_space: Operation space
  library: Library
  supervision: Supervision
  analytics: Analytics
  space: Space
  conception: Conception
  operation: Operation
  welcome_page: Welcome page
validate_nc: Validate NC
sidebar:
  manage_production: Manage production
  production_monitoring: Production monitoring
  incidents: Incidents
  production_order_monitoring: PO monitoring
  performance_analysis: Performance analysis
  performance_analysis_quality: Quality
  performance_analysis_production: Production
  handle_documents: Manage documents
  documents: Documents
  media_library: Media library
mes:
  production-rate: Production rate
  po: PO
  paretor-unknown-stoppage: Downtime pareto
  digram-trs: OEE waterfall
  no-machines-found: No machines found
  production-monitoring: Production monitoring
  machine-has-no-data: Machine has no data
  performances: Performances
  production-indicators: Production Indicators
  setting: Setting
  setting-shutdown-reasons: Setting Shutdown Reasons
  machine: Machine
  production: Production
  unknown-stoppage: Unknown stoppage
  total-time: Total time
  opened-time: Opened time
  production-time: Prod. time
  quality-waste: Scrap
  production-rate-delta: Δ prod. rate
  net-production-time: Net time
  available: Available
  batch: Batch
  settings:
    modal:
      reason: Shutdown Reason Title
      reason-placeholder: Enter a title...
      machine: Machine
      stop-machine-reason: Select a machine
      color: Setting Color
      new:
        title: New Shutdown Reason
      edit:
        title: Edit Shutdown Reason
    table:
      title: Title
      status: Status
      machine: Machine
      color: Color
  associate-po-to-my-machine:
    title: Associate a PO to my Machine
    description: Please enter the required information to associate a PO to your machine and view the expected quantity to produce.
    production-order-number-label: PO Number
    production-order-number-placeholder: Enter the PO number...
    quantity-label: Quantity to Produce
    quantity-placeholder: Enter the quantity to produce...
    save: Associate PO

sso:
  authentication_error:  An error was encountered while connecting to the sso provider, please check with your administrator
  connect: Connect with your SSO provider
  microsoft_connection: Microsoft connection (SSO)