import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { type JStep, StepType } from '~/models/documents/jStep'

export class MeasureCell extends Cell {
  constructor(rowIndex: number, colIndex: number, inputs: JInputData[], step: JStep, isHistory: boolean) {
    if (step.type !== StepType.Measure)
      throw new Error('Type mismatch')

    super(rowIndex, colIndex, inputs, step, isHistory)
  }

  get latestValue(): string {
    let value = super.latestValue

    if (this._step.separatorDecimal === ',')
      value = `${value}`.replace('.', ',')

    return value
  }

  parseAnswer(): string {
    const answer: JInputData | null = this.restoreValue ?? this.justifyKoValue ?? this.getLatestInputData()
    return `${answer?.value} ${answer?.unit || ''}`
  }
}

export default MeasureCell
