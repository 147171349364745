export const DOCUMENTS_COLLECTION_NAME = 'documents'
export const TEMPLATES_COLLECTION_NAME = 'templates'
export const DOCUMENT_SETTINGS_COLLECTION_NAME = 'document_settings'
export const NOTIFICATIONS_COLLECTION_NAME = 'notifications'
export const REPORTS_COLLECTION_NAME = 'reports'
export const STEPS_COLLECTION_NAME = 'steps'
export const USERS_COLLECTION_NAME = 'users'
export const SETTINGS_COLLECTION_NAME = 'settings'
export const SESSIONS_COLLECTION_NAME = 'sessions'
export const MASTER_SESSIONS_COLLECTION_NAME = 'master_sessions'
export const MASTER_SESSIONS_HISTORY_COLLECTION_NAME = 'master_sessions_history'
export const VIEWS_COLLECTION_NAME = 'views'
export const WORKFLOW_COLLECTION_NAME = 'workflows'
export const AUDIT_LOGS_COLLECTION_NAME = 'audit_logs'
export const EVENT_TRIGGERS_COLLECTION_NAME = 'event_triggers'
