<script setup lang="ts">
import Camera from 'simple-vue-camera'
import { usePermission } from '@vueuse/core'
import { format } from 'date-fns'
import { Icons } from '~/models/Icon'
import { RoleGrid } from '~/models/documents/jReport'
import { gridStore } from '~/store/grid'
import { getLastUpdatedByOnCell } from '~/helpers/UtilsHelper'
import type Grid from '~/grid/Grid'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const camera = ref<InstanceType<typeof Camera>>()
const cameraAccess = usePermission('camera')

const state = reactive({
  displayCameraModal: false,
  isActivated: true,
  isPreviewPhoto: false,
  isPhotoUploading: false,
  hasVideoDevices: false,
  cameraStopped: false,
  modalCanceled: false,
  rowIndex: props?.params?.rowIndex,
  colIndex:
    props?.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

const openModal = () => {
  state.displayCameraModal = true
  state.modalCanceled = false
}

const closeModal = () => {
  state.displayCameraModal = false
  state.modalCanceled = true
}

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed(() => {
  return grid?.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params)
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const isEditable = computed(() => {
  return cell?.value?.isEditable(props.params)
})

const isActivated = computed(() => {
  return cell?.value?.isActivated ?? true
})

watch(
  () => state.displayCameraModal,
  async (val: boolean) => {
    if (val) {
      if (cameraAccess.value !== 'granted' && !state.isPreviewPhoto) {
        await navigator.mediaDevices.getUserMedia({ video: true })

        state.cameraStopped = false
        camera.value?.start()
      }

      if (cell?.value?.latestValue) state.isPreviewPhoto = true
    }
  },
)

const modalInfos = computed(() => {
  if (cell?.value?.isHistory.value || state.isPreviewPhoto) {
    return {
      title: 'document.step_photo_preview_picture',
      saveBtnText: 'document.step_photo_take_picture_again',
    }
  }

  return {
    title: cell?.value?.latestValue
      ? 'document.step_photo_update_picture'
      : 'document.step_photo_take_picture',
    description: cell?.value?.latestValue
      ? 'document.step_photo_update_picture_description'
      : 'document.step_photo_take_picture_description',
    saveBtnText: 'document.step_photo_take_picture',
    //     saveBtnText: cell?.value?.latestValue ? 'document.step_photo_take_picture_again' : 'document.step_photo_take_picture',
  }
})

const noCameraAccess = computed(() => {
  return cameraAccess.value !== 'granted' || !state.hasVideoDevices
})

const isPreview = computed(() => {
  return props.params.colDef.gridType === RoleGrid.preview
})

state.isPhotoUploading = false

const savePhoto = async () => {
  state.isPhotoUploading = true
  camera.value?.pause()

  const imageBlob = await camera.value?.snapshot()
  // @ts-expect-error
  const path = await cell?.value?.uploadImage(imageBlob)

  state.isPhotoUploading = false
  props.params?.node?.setDataValue(props.params?.column?.colId, path)
}

const checkVideoDevices = async () => {
  const devices = (await camera.value?.devices(['videoinput'])) || []

  state.hasVideoDevices = devices && devices.length > 0
  if (!state.hasVideoDevices) camera.value?.stop()
}

const stopCamera = () => {
  state.cameraStopped = !state.modalCanceled
}

const removeAttachment = () => {
  props.params?.node?.setDataValue(props.params?.column?.colId, null)
}

const newPhoto = () => {
  state.isPreviewPhoto = false
}
// const init = () => {
//   if (state.isActivated && props.params.value === '')
//     props.params.node.setDataValue(props.params.column.colId, null)

//   if (props.params.value)
//     state.isPreviewPhoto = true

//   state.isGridPreview = props.params.colDef.gridType === RoleGrid.preview
// }

const activateCell = () => {
  cell.value?.setIsActivated(isEditable.value)
}
</script>

<template>
  <div
      v-if="!cell?.step?.hidden"
  >
    <div
      v-if="cell?.latestValue"
      class="group"
    >
      <div class="relative inline-block">
        <img
          :src="cell?.latestValue"
          class="mx-auto rounded-md object-cover"
          :class="`${props.params.data.description ? 'h-32 w-40' : 'h-fit w-fit'}`"
        />
        <component
          :is="Icons.CLOSE"
          v-if="!cell?.isHistory && isEditable && !grid.onExport"
          class="absolute right-[-12px] top-[-14px] z-50 h-6 w-6 cursor-pointer rounded-full border border-black bg-white p-0.5 text-black"
          @click="removeAttachment"
        />
        <div
          v-if="
            props.params?.colDef?.answers?.some(
              (a) => a.row_id === props.params?.rowIndex,
            )
          "
          v-can.any="['read-report', 'create-report']"
          class="absolute bottom-[-8px] right-[-12px] z-50"
          data-cy="avatar-button"
        >
          <AvatarMultiple
            :user-ids="[getLastUpdatedByOnCell(props.params)]"
            :hide-full-name="!grid?.onExport"
            display-amount
            is-enable-modal
          />
        </div>
      </div>
      <div
        v-if="
          props.params?.colDef?.answers?.some(
            (a) => a.row_id === props.params?.rowIndex,
          )
        "
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-[-8px] right-[-12px] z-50 flex flex-col-reverse items-center"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[getLastUpdatedByOnCell(props.params)]"
          display-amount
          :hide-full-name="!grid?.onExport"
          is-enable-modal
        />
        <span
          v-if="latestInputData?.update_date && grid.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>

      <div
        v-if="latestInputData?.updated_by"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-0 z-50 flex flex-col-reverse items-center gap-2"
        :class="{ 'right-[-12px]': !grid.onExport, 'right-0': grid.onExport }"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[latestInputData?.updated_by]"
          :update-date="latestInputData?.update_date"
          :hide-full-name="!grid?.onExport"
          display-amount
          is-enable-modal
        />
        <span
          v-if="latestInputData?.update_date && grid.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>
      <o-button
        v-if="!grid.onExport"
        alternate
        class="absolute left-1/2 hidden -translate-x-2/4 translate-y-0 group-hover:block"
        :class="`${props.params.data.description ? '-mt-20' : '-mt-16'}`"
        @click="openModal"
      >
        <component
          :is="cell?.isHistory ? Icons.VIEW : Icons.EDIT"
          class="h-4 w-4"
        />
      </o-button>
    </div>

    <div v-else-if="!cell?.latestValue && isActivated">
      <o-button
        :is-disabled="isPreview || cell?.isHistory || !isEditable"
        alternate
        @click="openModal"
      >
        <component
          :is="Icons.CAMERA"
          class="self-center"
        />
        <span v-if="!grid.onExport">
          {{ $t('document.step_photo_take_picture') }}
        </span>
      </o-button>
    </div>

    <div
      v-else
      class="rounded bg-[#EEEEEE] px-4 py-1.5 text-[#161616]"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
  <ModalDynamic
    v-model="state.displayCameraModal"
    :is-cross-close="isPreview || cell?.isHistory || !isEditable"
    :width="{ outer: 'w-[900px]', inner: 'w-full' }"
    :modal-title="modalInfos.title"
    :modal-description="modalInfos.description"
    :save-text="modalInfos.saveBtnText"
    :icon="Icons.CAMERA"
    :disable-save-btn="
      !state.hasVideoDevices &&
      (state.isPhotoUploading || !state.isPreviewPhoto || cell?.isHistory)
    "
    @save="state.isPreviewPhoto ? newPhoto() : savePhoto()"
    @action-on-close="closeModal"
  >
    <template #body>
      <div :class="{ 'pt-8': modalInfos.description?.length }">
        <Camera
          v-if="
            !state.isPreviewPhoto &&
            !cell?.isHistory &&
            cameraAccess === 'granted'
          "
          ref="camera"
          class="rounded-md"
          :resolution="{ width: 375, height: 200 }"
          autoplay
          @loading="checkVideoDevices"
          @stopped="stopCamera"
        />
        <img
          v-if="(cell?.isHistory || state.isPreviewPhoto) && cell?.latestValue"
          :src="cell?.latestValue"
          class="relative mx-auto h-full w-full rounded-md object-cover"
          alt=""
        />
        <component
          :is="Icons.CAMERA_OFF"
          v-if="noCameraAccess && !cell?.isHistory && !state.isPreviewPhoto"
          class="mx-auto h-16 w-16"
        />
        <div
          v-if="
            state.cameraStopped &&
            !state.hasVideoDevices &&
            !state.isPreviewPhoto
          "
          class="p-6 text-center text-sm text-gray-600"
        >
          {{ $t('document.step_photo_no_devices_found') }}
        </div>
        <div
          v-if="!cell?.isHistory && noCameraAccess && !state.isPreviewPhoto"
          class="p-6 text-center text-sm text-gray-600"
        >
          {{ $t('document.step_photo_no_camera_access') }}
        </div>
      </div>
    </template>
  </ModalDynamic>
</template>
