export interface JWorkflowStepErrors {
  description: boolean
  status: boolean
  permissions: boolean
}
export interface JWorkflowStep {
  builtIn: boolean
  description: string
  open: boolean
  permissions: any[]
  status: number
  errors?: JWorkflowStepErrors
}

export class JWorkflowTransition {
  name = ''
  from = ''
  to = ''
}

export class JWorkflow {
  id = ''
  name = ''
  client_id = ''
  site_id = ''
  created_by = ''
  created_at = ''
  transitions: JWorkflowTransition[] = []
  type: any = ''
  report_validation_status_id: any = ''
  steps = [] as JWorkflowStep[]

  constructor(object: JWorkflow | object = {}) {
    for (const key in object)
      this[key] = object[key]
  }
}
