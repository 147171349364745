const textClasses = {
  wrapper: '$reset flex flex-col text-sm',
  label: '$reset text-left text-gray-900 mb-1',
  inner: 'border border-gray-300 rounded',
  input: 'p-2 w-full h-[32px] sm:text-sm rounded placeholder:text-gray-600',
}

// TODO
const boxClasses = {
  fieldset: 'max-w-md border border-gray-400 rounded-md px-2 pb-1',
  legend: 'font-bold text-sm',
  wrapper: 'flex items-center mb-1 cursor-pointer',
  help: 'mb-2',
  input: 'form-check-input h-5 w-5 border border-gray-500 rounded-sm bg-white checked:bg-blue-500 focus:outline-none focus:ring-0 transition duration-200',
  label: 'text-sm text-gray-700 mt-1',
}

const buttonClasses = {
  wrapper: 'mb-1',
  input: 'inline-flex items-center mx-1 px-4 h-8 text-white py-1.5 border border-indigo-500 rounded text-white bg-indigo-500 transition hover:bg-white hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
}

export const formkitTheme: any = {
  // the global key will apply to _all_ inputs
  'global': {
    outer: '',
    help: 'text-xs text-gray-500',
    messages: 'list-none p-0 mt-1 mb-0',
    message: 'mb-1 text-xs text-red-500',
  },

  'button': buttonClasses,
  'color': {
    label: 'block mb-1 font-bold text-sm',
    input: 'w-16 h-8 appearance-none cursor-pointer border border-gray-300 rounded-md mb-2 p-1',
  },
  'date': textClasses,
  'datetime-local': textClasses,
  'checkbox': boxClasses,
  'email': textClasses,

  // TODO
  'file': {
    label: 'block mb-1 font-bold text-sm',
    inner: 'max-w-md cursor-pointer',
    input: 'text-gray-600 text-sm mb-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600',
    noFiles: 'block text-gray-800 text-sm mb-1',
    fileItem: 'block flex text-gray-800 text-sm mb-1',
    removeFiles: 'ml-auto text-blue-500 text-sm',
  },
  'month': textClasses,
  'number': textClasses,
  'password': textClasses,
  'radio': {
    // Override
    // ...boxClasses,
    // input: boxClasses.input.replace('rounded-sm', 'rounded-full'),
  },

  // TODO
  'range': {
    inner: 'max-w-md',
    input: 'form-range appearance-none w-full h-2 p-0 bg-gray-200 rounded-full focus:outline-none focus:ring-0 focus:shadow-none',
  },
  'search': textClasses,
  'select': {
    ...textClasses,
    inner: 'pr-2 bg-white border border-gray-300 drop-shadow-sm rounded',
    input: 'px-1 w-full h-[32px] sm:text-sm rounded placeholder:text-gray-700',
  },
  'submit': buttonClasses,
  'tel': textClasses,
  'text': textClasses,
  'textarea': {
    ...textClasses,
    input: 'block w-full h-32 pt-1 px-2 border-none text-base text-gray-700 placeholder-gray-400 focus:shadow-outline',
  },
  'time': textClasses,
  'url': textClasses,
  'week': textClasses,
}
