import { useQuery } from '@tanstack/vue-query'
import type { Ref } from 'vue'
import { usersStore } from '~/store/users'
import { trpc } from '../trpc'

export type TAllMachines = ReturnType<
  typeof useFetchAllMachines
>['data']['value']
export type TMachineStatuses = ReturnType<
  typeof useFindMachine
>['data']['value']

export type TMachineWorkplace =
  NonNullable<
    ReturnType<typeof useFindMachinesByWorkplace>['data']['value']
  > extends Array<infer T>
    ? T
    : null

export const useFetchAllMachines = () => {
  const { site_id, client_id } = usersStore().user
  return useQuery({
    queryKey: ['fetchAllMachines'],
    queryFn: async () =>
      await trpc.fetchAllMachines.query({ site_id, client_id }),
    initialData: [],
  })
}

export const useFindMachine = (id: string) => {
  return useQuery({
    queryKey: ['findMachine', id],
    queryFn: async () =>
      trpc.fetchOneMachine.query({
        id,
      }),
    initialData: null,
    refetchOnWindowFocus: true,
    enabled: !!id,
  })
}

export const useFindMachinesByWorkplace = ({
  workplaceId,
  enabled,
}: {
  workplaceId: Ref<number>
  enabled?: Ref<boolean>
}) => {
  return useQuery({
    queryKey: ['findMachinesByWorkplace', workplaceId],
    queryFn: async () =>
      trpc.fetchMachinesForWorkplace.query({
        workplaceId: workplaceId.value,
      }),
    initialData: null,
    refetchOnWindowFocus: true,
    enabled,
  })
}
