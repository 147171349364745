import api from '~/helpers/ApiHelper'

class RoleController {
  async createRole(name) {
    try {
      const role = await api.createRole(name)

      return role
    }
    catch (e) {
      console.error('Cannot create role', e)
    }
  }

  async getRoles() {
    try {
      const roles = await api.getRoles()

      return roles
    }
    catch (e) {
      console.error('Cannot get roles', e)
    }
  }

  async getUserRolePermissions(roleId: number) {
    try {
      const permissions = await api.getUserRolePermissions(roleId)

      return permissions
    }
    catch (e) {
      console.error('Cannot create status', e)
    }
  }

  async updatePermission(updatePermissionForm) {
    try {
      const permissionUpdated = await api.updatePermission(updatePermissionForm)

      return permissionUpdated
    }
    catch (e) {
      console.error('Cannot update permission', e)
    }
  }

  async updateRole(roleId, roleData) {
    try {
      const roleUpdated = await api.updateRole(roleId, roleData)

      return roleUpdated
    }
    catch (e) {
      console.error('Cannot update permission', e)
    }
  }

  async deleteRole(roleId) {
    try {
      const roleUpdated = await api.deleteRole(roleId)

      return roleUpdated
    }
    catch (e) {
      console.error('Cannot delete role', e)
    }
  }
}

const roleController = new RoleController()

export default roleController
