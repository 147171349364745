<script setup lang="ts">
import { usersStore } from '~/store/users'
import { redirectToPage, verifyIfUserHaveAccessRightToPath } from '~/utils/auth'

const router = useRouter()

const init = async () => {
  const user = usersStore().user
  localStorage.removeItem('force_normal_redirect')

  const redirectTo = await redirectToPage(user)

  if (redirectTo) {
    const route = router.resolve({ path: redirectTo })
    if (verifyIfUserHaveAccessRightToPath(user, route))
      return router.push(redirectTo)
  }

  const welcomePage = usersStore().checkUserRedirection()
  return router.push(welcomePage)
}

init()
</script>

<template>
</template>

<route lang="yaml">
meta:
  requiresAuth: true
  requiresForcePass: true
</route>

<style>
  input, button, textarea, select, div{
    outline: none !important;
  }
</style>
