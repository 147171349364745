import { defineStore } from 'pinia'
import _ from 'lodash'
import { usersStore } from './users'
import { apiStore } from '~/store/api'
import { ApplicationFieldType } from '~/models/documents/jDocument'
import { TMachineWorkplace } from '~/composables/mesx/requests/machine'
import { BuiltInStatus } from '~/common/models/status'

export interface IWorkplace {
  id: string
  name: string
  description: null | string
  justification: null | string
  is_deleted: boolean
  updated_by: null | string
  alternative_workplaces: null | any[]
  parentId: null | string
  siteId: string
  alternativeWorkplaces: null | any[]
  updateDate: null | Date
  db_id: number
  site_id: string
  alternativeWorkplacesList: any[]
  siteName: string
  updated_by_id: string | null
  status: BuiltInStatus | null
  update_date: Date
  label: string
  type: string
  machines?: TMachineWorkplace[]
}

export const workplaceStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'workplaces',
  // a function that returns a fresh state
  state: () => ({
    operatorCurrentWorkplaces: [] as string[],
    currentWorkplace: {} as IWorkplace,
    workplaces: [] as IWorkplace[],
    workplacesHistory: [] as any[],
  }),

  // optional getters
  getters: {
    getWorkplaces(): any {
      return this.workplaces
    },

    getWorkplacesHistory(): any {
      return this.workplacesHistory
    },

    getOperatorCurrentWorkplaces(): any[] {
      return this.operatorCurrentWorkplaces
    },
  },
  // optional actions
  actions: {
    async loadWorkplaces(forceFetch = false): Promise<any> {
      const api = apiStore().getApiClient

      let workplaces = await api.getWorkplaces(forceFetch)

      workplaces.map((p: any) => {
        p.label = `${p.id} - ${p.name}`
        p.type = ApplicationFieldType.workplace
        p.machines = []
        return p
      })

      workplaces = _.uniqBy(workplaces, (e: any) => e.id)

      let workplacesHistory =
        usersStore().user?.configuration?.visited_workplaces ?? []

      if (workplacesHistory.length) {
        workplacesHistory = workplacesHistory.map((e) => {
          const findWorkplace = workplaces.find((x) => e === x.id)
          e = { ...findWorkplace }
          return e
        })
      }

      this.workplaces = workplaces
      this.workplacesHistory = workplacesHistory
    },
    findAndFormatWorkplace(id: string) {
      const wp = this.findWorkplace(id)

      return `${wp.id} - ${wp.name}`
    },
    findWorkplace(id: string) {
      return this.workplaces.find((wp: any) => wp?.id === id)
    },
    findWorkplaceByName(name: string) {
      return this.workplaces.find((wp: any) => wp?.name === name)
    },
    filterWorkplaces(ids: any) {
      const workplaces: any = this.workplaces.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return workplaces
    },
    setCurrentWorkplace(workplace: any) {
      this.currentWorkplace = workplace
    },
    setFilteredWorkplace(workplaces) {
      this.operatorCurrentWorkplaces = workplaces
    },
    unshiftWorkplaceHistory(workplace: any) {
      this.workplacesHistory = this.workplacesHistory.filter(
        (wp) => wp.id !== workplace.id,
      )
      this.workplacesHistory.unshift(workplace)
    },
  },
})
