export const ALERTS_COLLECTION_NAME = 'alerts'
export const DOCUMENTS_COLLECTION_NAME = 'documents'
export const TEMPLATES_COLLECTION_NAME = 'templates'
export const DOCUMENT_SETTINGS_COLLECTION_NAME = 'document_settings'
export const NOTIFICATIONS_COLLECTION_NAME = 'notifications'
export const REPORTS_COLLECTION_NAME = 'reports'
export const STEPS_COLLECTION_NAME = 'steps'
export const USERS_COLLECTION_NAME = 'users'
export const SETTINGS_COLLECTION_NAME = 'settings'
export const SESSIONS_COLLECTION_NAME = 'sessions'
export const SITES_COLLECTION_NAME = 'sites'
export const FILES_ATTACHMENT_COLLECTION_NAME = 'files_attachment'
export const REPORT_INPUTS_COLLECTION_NAME = 'REPORT_INPUTS_BACKUP'
