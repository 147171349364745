import type { Nullable } from 'vitest'
import { type JStep, StepMeasureOptions } from '~/models/documents/jStep'
import type { JSiteDecimal } from '~/models/sites'
import { siteStore } from '~/store/site'
import { isUndefinedOrNullOrEmpty } from '~/utils/object'

interface MeasureStepValidationOptions {
  step: JStep
  cellValue?: any
  tolerance?: number | undefined
  indexCol?: number | undefined
  decimals?: JSiteDecimal | undefined
}

interface UncertaintyOptions {
  target: any
  minRange: number
  maxRange: number
  step: JStep
}

export enum ValidationType {
  WARNING,
  ERROR,
}

interface ICellMeasureValidation {
  validate(options: MeasureStepValidationOptions): boolean
}

class CellMeasureValidationRules {
  static getStepRangeGoal(step, indexCol): Nullable<number> {
    if (!step.is_dynamic)
      return step.goal

    return indexCol >= 0
      ? step.last_targets?.find(p => indexCol! >= p.init_col_id && indexCol! <= p.last_col_id)?.target
      : step.last_targets?.at(-1)?.target
  }

  static getStepRange(step: JStep, target: number): number[] {
    const formatNumber = (num: number) => Number(Number(num)?.toFixed(5))
    const min = formatNumber(step.range?.[0])
    const max = formatNumber(step.range?.[1])
    const goal = formatNumber(target)

    const minType = step?.min_type || step?.minType
    const maxType = step?.max_type || step?.maxType
    const minRange = minType === StepMeasureOptions.Margin ? goal - Math.abs(min) : min
    const maxRange = maxType === StepMeasureOptions.Margin ? goal + Math.abs(max) : max

    return [minRange, maxRange]
  }

  static calculateToleranceBoundaries(min: number, max: number, tolerance?: number): number[] {
    const percentageTolerance = (Number(tolerance) || 0) / 100
    const T = (max - min) * percentageTolerance

    return [min + T, max - T]
  }

  static calculateUncertaintyBoundaries(options: UncertaintyOptions): number[] | null[] {
    const { minRange: min, maxRange: max, step } = options
    const isUncertaintyEnabled = siteStore().getFlag('standard_deviation') && step.uncertainty_enabled

    if (!isUncertaintyEnabled)
      return [null, null]

    const minUncertainty = step.uncertainty_type === StepMeasureOptions.Percentage
      ? min / (1 + (Number(step.uncertainty_min) / 100))
      : min - Number(step.uncertainty_min)

    const maxUncertainty = step.uncertainty_type === StepMeasureOptions.Percentage
      ? max / (1 - (Number(step.uncertainty_max) / 100))
      : max + Number(step.uncertainty_max)

    return [minUncertainty, maxUncertainty]
  }

  static isDecimalCountMatching(step: JStep, value: string, decimals: JSiteDecimal): boolean {
    const nbDecimals = (decimals?.as_tolerance ? Math.max(step.range[0]?.toString()?.split('.')[1]?.length || 0, step.range[1]?.toString()?.split('.')[1]?.length || 0) : Number(decimals?.decimals_number)) ?? 0
    const numberOfDecimalInValue = value?.toString()?.split('.')[1]?.length ?? 0
    const isSameDecimal = decimals?.enabled ? numberOfDecimalInValue === nbDecimals : true

    return isSameDecimal
  }

  static isValueBorderline(value: number, boundariesMin: number[], boundariesMax: number[]): boolean {
    const [lowMin, lowMax] = boundariesMin
    const [highMin, highMax] = boundariesMax

    return (lowMin <= value && value <= lowMax) || (highMin <= value && value <= highMax)
  }

  static isValueErrored(value: number, min: number, max: number, isSameDecimal: boolean): boolean {
    if (min === 0 && max === 0)
      return false

    return value < min || value > max || !isSameDecimal
  }
}

export class CellMeasureValidation implements ICellMeasureValidation {
  private validationType: Nullable<ValidationType>

  constructor(validationType?: ValidationType) {
    this.validationType = validationType
  }

  getBoundaries(options: MeasureStepValidationOptions): number[][] {
    const { step, indexCol, tolerance } = options
    const stepRangeGoal = Number(CellMeasureValidationRules.getStepRangeGoal(step, indexCol))

    if (isUndefinedOrNullOrEmpty(stepRangeGoal))
      return [[], []]

    const [minRange, maxRange] = CellMeasureValidationRules.getStepRange(step, stepRangeGoal!)
    const [minTolerance, maxTolerance] = CellMeasureValidationRules.calculateToleranceBoundaries(minRange, maxRange, tolerance)
    const [minUncertainty, maxUncertainty] = CellMeasureValidationRules.calculateUncertaintyBoundaries({ target: stepRangeGoal, minRange, maxRange, step })
    const boundariesMin = [minUncertainty || minRange, minTolerance]
    const boundariesMax = [maxTolerance, maxUncertainty || maxRange]

    return [boundariesMin, boundariesMax]
  }

  validate(options: MeasureStepValidationOptions) {
    const { step, cellValue, decimals } = options
    const [boundariesMin, boundariesMax] = this.getBoundaries(options)

    if (!boundariesMin && !boundariesMax)
      return false

    const isSameDecimal = this.validationType === ValidationType.ERROR
      ? CellMeasureValidationRules.isDecimalCountMatching(step, cellValue, decimals!)
      : true

    return this.validationType === ValidationType.ERROR
      ? CellMeasureValidationRules.isValueErrored(Number(cellValue), boundariesMin![0], boundariesMax![1], isSameDecimal)
      : CellMeasureValidationRules.isValueBorderline(Number(cellValue), boundariesMin, boundariesMax)
  }
}
