import { format, formatDistanceToNow } from 'date-fns'
import { enUS, fr } from 'date-fns/locale'
import _, { camelCase, isArray, isObject, join, transform } from 'lodash'
import { getUserById } from '~/controllers/authentication'
import { usersStore } from '~/store/users'

interface JDateOptions {
  displayFullDate: boolean
}

// const { locale } = useI18n()
export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// const langMap = [{ lang: 'fr', format: 'fr-FR' }, { lang: 'en', format: 'en-US' }]

export const formatDate = (
  timestamp: Date | string,
  options?: JDateOptions,
) => {
  if (!timestamp) return null

  const locale = usersStore().user.language === 'en' ? enUS : fr

  if (options?.displayFullDate) {
    const dateFormat =
      usersStore().user.language === 'en' ? 'MMMM dd, yyyy' : 'dd MMMM yyyy'

    return format(new Date(timestamp), dateFormat, { locale })
  }

  const dateFormat =
    usersStore().user.language === 'en'
      ? 'MM/dd/yy - hh:mm a'
      : 'dd/MM/yy - HH:mm'

  return format(new Date(timestamp), dateFormat, { locale })
}

export const formatTime = (timestamp: any, yearDigits = 4) => {
  if (!timestamp) return ''

  try {
    const isEN = usersStore().user.language === 'en'
    const formattedDate = new Intl.DateTimeFormat(isEN ? 'en-US' : 'fr-FR', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(timestamp))
    return formattedDate
  } catch (error) {
    return timestamp
  }
}

export const formatHour = (timestamp: any) => {
  if (!timestamp) return ''

  try {
    const isEN = usersStore().user.language === 'en'
    const formattedDate = new Intl.DateTimeFormat(isEN ? 'en-US' : 'fr-FR', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(new Date(timestamp))
    return capitalizeFirstLetter(formattedDate)
  } catch (error) {
    return timestamp
  }
}

export const getRelativeTime = (date) => {
  const relativeTimeString = formatDistanceToNow(new Date(date))
  const regex = /(\d+)\s(\w+)/
  const elements = relativeTimeString.match(regex)!

  let value = 1
  let unit = 'minute'

  if (elements) {
    value = Number(elements[1])
    unit = elements[2]
  }

  return {
    value,
    unit: unit.endsWith('s') ? unit.slice(0, -1) : unit,
  }
}

export const createURLFromArray = (arr: Array<string>) => {
  return `/${join(arr, '/')}`
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const truncateString = (str: string, length: number) => {
  return str.length > length ? `${str.substring(0, length)}...` : str
}

export const getInitialsWithId = (id: string): string => {
  const user = getUserById(id)
  return user
    ? `${user.first_name?.charAt(0).toUpperCase()}${user.last_name?.charAt(0).toUpperCase()}`
    : ''
}

export const getInitials = (name: string): string => {
  const splitName = name.split(' ').map((e) => e.trim())
  name = `${splitName[0][0] ?? ''}${splitName[splitName.length - 1][0] ?? ''}`

  return name
}

export const recursivelyCamelizeObject = (obj) =>
  transform(obj, (acc: any, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key as string)

    acc[camelKey] = isObject(value) ? recursivelyCamelizeObject(value) : value
  })

export const minutesToMilliseconds = (minutes) => {
  const minutesToSeconds = minutes * 60
  const secondsToMilliseconds = minutesToSeconds * 1000

  return secondsToMilliseconds
}

export const dateClassToFormKitDate = (date: number | Date) => {
  date = new Date(date)
  const year = date.getFullYear().toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const result = `${year}-${month}-${day}`
  return result
}

export const convertCamelCase = (string: string): string => {
  const words = _.camelCase(string)
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
  const convertedString = words
    .map((word, index) => {
      return index === 0
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word.toLowerCase()
    })
    .join(' ')

  return convertedString
}

export const generateFirebaseLikeId = () => {
  let customId = ''
  const characters =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

  for (let i = 0; i < 20; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    customId += characters[randomIndex]
  }

  return customId
}
