import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { DD_CLIENT_TOKEN } from '../config/index'
import { BACKEND_URL } from '~/config/index'
import { VERSION } from '~/common/index'

export enum datadogAlertType {
  CRITICAL = 'CRITICAL',
}

let env = ''
if (typeof window !== 'undefined') {
  const w: any = window
  const href = w?.location?.href
  env
    = (href.includes('demo') && 'DEMO')
    || (href.includes('staging') && 'STAGING')
    || (href.includes('app') && 'PROD')
    || 'LOCAL'
}

class LoggerHelper {
  dataDog: any

  constructor() {
    this.dataDog = this.initDataDog()
  }

  private initDataDog = () => {
    if (env === 'LOCAL')
      return
    datadogRum.init({
      applicationId: '8f5ec7b1-b85e-4cdf-9ce4-d8ecbc289f33',
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'juno',
      env,
      version: VERSION,
      sessionSampleRate: 100,
      proxy: `${BACKEND_URL}/v1/proxy-datadog`,
    })

    datadogLogs.init({
      clientToken: DD_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env,
      version: VERSION,
      proxy: `${BACKEND_URL}/v1/proxy-datadog`,
    })
  }

  logError = async (message: string, context: any = {}) => {
    console.error(message, context)
    datadogLogs?.logger.error(message, { context })
  }

  logEvent = async (event: string, params: any = {}) => {
    params.type = 'info'
    datadogLogs?.logger.info(event)
  }

  logInfo = (msg: string) => {
    datadogLogs?.logger.info(msg)
  }

  logWarning = (msg: string) => {
    datadogLogs?.logger.warn(msg)
  }
}

const loggerHelper = new LoggerHelper()

export default loggerHelper
