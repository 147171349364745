import { mapKeys } from 'lodash'
import { i18n } from '~/modules/i18n'
const { t } = i18n.global

export const changeKeysFromArrayOfObject = (array: Array<Object>, keyMapping: Object): Array<Object> => {
  const mappedArray: Array<Object> = array.map(object => (
    mapKeys(object, (value, key) => {
      if (keyMapping[key])
        return keyMapping[key]
      else
        return key
    })
  ))
  return mappedArray
}

export const parseArrayObjectToKeyAndValue = (arrayToTransform: array, variableKey: string, variableValue: string): Object[] => {
  const tabFinal = []
  arrayToTransform.forEach((obj) => {
    const newObj = {}
    newObj.key = obj[variableKey]
    newObj.value = obj[variableValue]
    tabFinal.push(newObj)
  })
  return tabFinal
}

// Used for formkit
export const parseArrayObjectToKeyAndLabel = (arrayToTransform: array, label: string, value: string, selectedValue?: string): Object[] => {
  const formattedArray = [] as any[]
  arrayToTransform?.forEach((obj) => {
    const newObj = {}
    newObj.label = obj[label]
    newObj.value = obj[value]
    if (obj[value] === selectedValue)
      newObj.attrs = { selectedValue }
    formattedArray.push(newObj)
  })
  return formattedArray
}

export const parseArrayObjectToKeyAndLabelObject = (arrayToTransform: array, label: string, value: string, selectedValue: string, keys: string[]): Object[] => {
  const formattedArray = [] as any[]
  arrayToTransform?.forEach((obj) => {
    const newObj = {}
    newObj.label = obj[label]
    newObj.value = obj[value]
    for (const key of keys)
      newObj[key] = obj[key]

    if (obj[value] === selectedValue)
      newObj.attrs = { selectedValue }
    formattedArray.push(newObj)
  })
  return formattedArray
}

export const groupBy = (objectArray: any, property: string) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key])
      acc[key] = []

    acc[key].push(obj)
    return acc
  }, {})
}

export const parseEnumValuesTo = (enumType, translatePrefix) => {
  return Object.keys(enumType)
    .filter(x => isNaN(parseInt(x)))
    .map(item => ({
      label: t(`${translatePrefix}.${item}`),
      value: item,
    }))
}

export const parseRawEnumValuesTo = (enumType) => {
  return Object.keys(enumType)
    .filter(x => isNaN(parseInt(x)))
    .map(item => ({ label: item, value: item }))
}
