export enum ViewDisplayType {
  LIST = 'list',
  GRID = 'grid',
}

export enum ViewType {
  REPORT = 'report',
}

export interface ViewConfiguration {
  filters: any
  groupBy: string
  displayType: ViewDisplayType
  type: ViewType.REPORT
}

export class JView {
  id = ''
  client_id = ''
  created_by = ''
  name = ''
  description = ''
  view_configuration: ViewConfiguration = {} as ViewConfiguration
  created_date: number | Date = 0

  constructor(object: JView | object = {}) {
    for (const key in object)
      this[key] = object[key]
  }
}
